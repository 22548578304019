import React from 'react';

import { SliderItem } from '../../Interfaces';
import LinkEditor from '../LinkEditor';

import './itemEditor.css';

export interface ItemEditorProps {
  data: SliderItem;
  callback: (data: SliderItem | null) => void;
  notDeletable?: boolean;
}

export default function ItemEditor(props: ItemEditorProps) {
  const [path, setPath] = React.useState(props.data.img.path);
  const [alt, setAlt] = React.useState(props.data.img.alt);

  React.useEffect(() => {
    props.callback({
      ...props.data,
      img: {
        path,
        alt,
      },
    });
  }, [path, alt]);

  if (path !== props.data.img.path && alt !== props.data.img.alt) {
    setAlt(props.data.img.alt);
    setPath(props.data.img.path);
  }

  return (
    <LinkEditor
      onRemove={!props.notDeletable ? () => props.callback(null) : undefined}
      image={path}
      description={alt}
      setImage={setPath}
      setDescription={setAlt}
      descriptionPlaceholder="Bildbeschreibung"
      inputClassName="itemEditorInput"
      coverImage
    />
  );
}
