import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

import { AuthProvider } from './Components/Authentication/AuthContext';

import { Router } from 'react-router-dom';
import history from './history';

import App from './App';

import { createTheme, ThemeProvider } from '@material-ui/core';

import { SnackbarProvider } from 'notistack';

import './index.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#09a9ee',
      light: '#3abaf1',
      dark: '#0676a6',
      contrastText: '#fff',
    },
    secondary: {
      main: '#fff',
      contrastText: '#fff',
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3} autoHideDuration={5000} preventDuplicate>
        <AuthProvider>
          <Router history={history}>
            <App />
          </Router>
        </AuthProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
