import React from 'react';

import { Subject, Teacher } from '../../../Interfaces';
import EditorCardManager from '../../EditorCardManager';
import BooleanEditor from '../Generic/BooleanEditor';

import { Select, MenuItem, Checkbox, ListItemText, FormControl, InputLabel, TextField } from '@material-ui/core';

import './teacherEditor.css';

export interface TeacherEditorProps {
  teacher: Teacher;
  callback: (data: Teacher | null) => void;
  possibleSubjects: Subject[];
  removable: boolean;
}

export default function TeacherEditor(props: TeacherEditorProps) {
  const getKeysByElements = (subjects: string[], scope: 'kuerzel' | 'name' = 'kuerzel') => {
    return subjects
      .map((subject) => props.possibleSubjects.find((possible) => possible[scope] === subject)?._key)
      .filter(Boolean) as string[];
  };

  const getElementsByKeys = (subjects: string[], target: 'kuerzel' | 'name' = 'kuerzel') => {
    return subjects
      .map((key) => props.possibleSubjects.find((possible) => possible._key === key)?.[target])
      .filter(Boolean) as string[];
  };

  const [firstname, setFirstname] = React.useState(props.teacher.firstname);
  const [middlename, setMiddlename] = React.useState(props.teacher.middlename);
  const [surname, setSurname] = React.useState(props.teacher.surname);
  const [title, setTitle] = React.useState(props.teacher.title);
  const [token, setToken] = React.useState(props.teacher.token);
  const [subjects, setSubjects] = React.useState(getKeysByElements(props.teacher.subjects));
  const [referendar, setReferendar] = React.useState(props.teacher.referendar);

  React.useEffect(() => {
    props.callback({
      firstname,
      middlename,
      surname,
      title,
      token,
      subjects: getElementsByKeys(subjects),
      referendar,
      _key: props.teacher._key,
    });
  }, [firstname, middlename, surname, title, token, subjects, referendar]);

  return (
    <EditorCardManager onRemove={props.removable ? () => props.callback(null) : undefined}>
      <div className="teacherEditorWrapper">
        <TextField
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFirstname(event.target.value)}
          value={firstname}
          className="teacherEditorInput"
          label="Vorname"
          error={!firstname}
        />
        <TextField
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setMiddlename(event.target.value)}
          value={middlename}
          className="teacherEditorInput"
          label="Zweitnamen"
          error={!middlename}
        />
        <TextField
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSurname(event.target.value)}
          value={surname}
          className="teacherEditorInput"
          label="Nachname"
          error={!surname}
        />
        <TextField
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setTitle(event.target.value)}
          value={title}
          className="teacherEditorInput"
          label="Titel"
          error={!title}
        />
        <TextField
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setToken(event.target.value)}
          value={token}
          className="teacherEditorInput"
          label="Fachgruppenleiter"
          error={!token}
        />
        <FormControl className="teacherEditorInput">
          <InputLabel>Fächer</InputLabel>
          <Select
            multiple
            onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
              setSubjects(getKeysByElements(event.target.value as string[], 'name'))
            }
            value={getElementsByKeys(subjects, 'name')}
            renderValue={(selected) => getElementsByKeys(getKeysByElements(selected as string[], 'name')).join(', ')}
            error={!subjects.length}
          >
            {props.possibleSubjects
              .filter((possible) => !!possible.name)
              .map((subject) => (
                <MenuItem key={subject.name} value={subject.name}>
                  <Checkbox checked={getElementsByKeys(subjects).includes(subject.kuerzel)} color="primary" />
                  <ListItemText primary={subject.name} />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <BooleanEditor value={referendar} setFunc={setReferendar} text="Referendar" />
      </div>
    </EditorCardManager>
  );
}
