import React from 'react';
import { Link } from 'react-router-dom';

import { Backdrop, Button, CircularProgress, Paper, TextField } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import * as EmailValidator from 'email-validator';

import './login.css';

export default function Login(props: {
  onLogin: (email: string, password: string) => void;
  location: { state?: { email: string } };
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const [email, setEmail] = React.useState(props.location.state?.email || '');
  const [password, setPassword] = React.useState('');

  async function handleSubmit(e: any) {
    e.preventDefault();

    try {
      setLoading(true);
      await props.onLogin(email, password);
    } catch {
      setError(true);
      setPassword('');

      enqueueSnackbar('Die E-Mail-Adresse oder das Passwort ist falsch', { variant: 'error' });

      setTimeout(() => setLoading(false), 1000);
    }
  }

  let emailError = !EmailValidator.validate(email) && error;

  return (
    <div className="loginPage">
      <Paper elevation={8} className="container">
        <h1>Login</h1>
        <form>
          {
            // Form triggers password manager prompts when logging in
          }
          <TextField
            fullWidth
            type="email"
            label="E-Mail"
            value={email}
            error={emailError}
            onChange={(event) => setEmail(event.currentTarget.value)}
            helperText={emailError ? 'Bitte geben Sie eine valide E-Mail-Adresse an' : ''}
            autoComplete="username"
          />
          <TextField
            fullWidth
            type="password"
            label="Passwort"
            value={password}
            error={error}
            onChange={(event) => setPassword(event.currentTarget.value)}
            autoComplete="current-password"
          />
          <Button type="submit" color="primary" variant="outlined" onClick={handleSubmit}>
            Einloggen
          </Button>
        </form>
        <span>
          <Link to={{ pathname: '/forgot-password', state: { email } }}>Passwort vergessen?</Link>
        </span>
        <Backdrop open={loading} style={{ zIndex: 100 }}>
          <CircularProgress color="primary" size="10rem" />
        </Backdrop>
      </Paper>
    </div>
  );
}
