import React from 'react';

import { Card } from '../../../Interfaces';
import LinkEditor from '../../LinkEditor';

import { TextField } from '@material-ui/core';

import './cardsEditor.css';

export interface CardsElementEditorProps {
  cardData: Card;
  callback: (data: Card | null) => void;
  removable: boolean;
}

export default function CardsElementEditor(props: CardsElementEditorProps) {
  const [title, setTitle] = React.useState(props.cardData.title);
  const [text, setText] = React.useState(props.cardData.text);
  const [href, setHref] = React.useState(props.cardData.href);
  const [button, setButton] = React.useState(props.cardData.button);
  const [img, setImg] = React.useState(props.cardData.img.path);
  const [desc, setDesc] = React.useState(props.cardData.img.alt);

  React.useEffect(() => {
    props.callback({
      title,
      text,
      href,
      button,
      img: { path: img, alt: desc },
      _key: props.cardData._key,
    });
  }, [title, text, href, button, img, desc]);

  return (
    <LinkEditor
      onRemove={props.removable ? () => props.callback(null) : undefined}
      previewClassName="cardsElementEditorPreview"
      inputClassName="cardsElementEditorInput"
      href={href}
      image={img}
      description={desc}
      setHref={setHref}
      setImage={setImg}
      setDescription={setDesc}
      dontRedirect
      picturePlaceholder="Hintergrundbild"
      descriptionPlaceholder="Bildbeschreibung"
      coverImage
    >
      <div className="cardsPreviewEditorWrapper">
        <TextField
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setTitle(event.target.value)}
          value={title}
          className="cardsPreviewEditorInput title"
          label="Titel"
          error={!title}
        />
        <TextField
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setText(event.target.value)}
          value={text}
          className="cardsPreviewEditorInput text"
          label="Text"
          error={!text}
        />
        <TextField
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setButton(event.target.value)}
          value={button}
          className="cardsPreviewEditorInput button"
          label="Umleitung"
          error={!button}
        />
      </div>
    </LinkEditor>
  );
}
