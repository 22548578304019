import React from 'react';

import { AddButtonRotating, RemoveButtonBin } from '@idot-digital/custom-material-ui';
import '@idot-digital/custom-material-ui/dist/index.css';

import { Tooltip } from '@material-ui/core';

import './editorStyle.css';

export interface EditorCardManagerProps {
  onRemove?: () => void;
  onAdd?: () => void;
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

export default function EditorCardManager(props: EditorCardManagerProps) {
  return (
    <React.Fragment>
      <div className="editorGroup">
        <div className={['content', !props.onRemove ? 'fill' : '', props.className].join(' ')} style={props.style}>
          {props.children}
          {props.onAdd && (
            <div className="addButtonWrapper">
              <Tooltip title="Neuen Eintrag erstellen" className="addButton">
                <div>
                  <AddButtonRotating onClick={props.onAdd} />
                </div>
              </Tooltip>
            </div>
          )}
        </div>
        <div className="removeButtonWrapper">
          <Tooltip title="Eintrag löschen" className="removeButton">
            <div>
              <RemoveButtonBin onClick={props.onRemove} />
            </div>
          </Tooltip>
        </div>
      </div>
    </React.Fragment>
  );
}
