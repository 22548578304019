import React from 'react';

import { addGroup, updateGroup } from '../../../functions';
import { Subject, Teacher } from '../../../Interfaces';
import SubjectEditor from './SubjectEditor';
import GroupCardManager from '../../GroupCardManager';

import { v4 as uuidv4 } from 'uuid';

import './subjectList.css';

export interface SubjectListEditorProps {
  subjects: Subject[];
  possibleFachgruppenleiter: Teacher[];
  callback: (data: Subject[]) => void;
}

export default function SubjectListEditor(props: SubjectListEditorProps) {
  const [subjects, setSubjects] = React.useState(props.subjects);

  React.useEffect(() => {
    props.callback(subjects);
  }, [subjects]);

  const addCard = addGroup.bind({
    setFunc: setSubjects,
    origData: subjects,
    newElmt: {
      kuerzel: '',
      name: '',
      nameShort: '',
      icon: '',
      text: '',
      kontakt: '',
      _key: uuidv4(),
    },
  });

  return (
    <GroupCardManager title={'Fächer'} onAdd={addCard} className="subjectWrapper">
      {props.subjects.map((subject, index) => {
        return (
          <SubjectEditor
            removable={props.subjects.length > 1}
            subjectData={subject}
            callback={(data) => {
              updateGroup(data, index, setSubjects, subjects);
            }}
            possibleFachgruppenleiter={props.possibleFachgruppenleiter}
            kuerzel={subjects.map((subject) => subject.kuerzel)}
            key={subject._key}
          />
        );
      })}
    </GroupCardManager>
  );
}
