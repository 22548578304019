export const YOUTUBE_REGEX = /^(https?:\/\/)?(www\.|music\.)?(youtube\.com|youtu\.be)(?!.*\/channel\/)(?!\/@)(.+)?$/;
export const YOUTUBE_REGEX_GLOBAL =
  /^(https?:\/\/)?(www\.|music\.)?(youtube\.com|youtu\.be)(?!.*\/channel\/)(?!\/@)(.+)?$/g;

export const isValidYoutubeUrl = (url: string) => {
  return url.match(YOUTUBE_REGEX);
};

export interface GetEmbedUrlOptions {
  url: string;
}

export const getYoutubeEmbedUrl = () => {
  return 'https://www.youtube.com/embed/';
};

export const getEmbedUrlFromYoutubeUrl = (options: GetEmbedUrlOptions) => {
  const { url } = options;

  // if is already an embed url, return it
  if (url.includes('/embed/')) {
    return url;
  }

  // if is a youtu.be url, get the id after the /
  if (url.includes('youtu.be')) {
    const id = url.split('/').pop();

    if (!id) {
      return null;
    }
    return `${getYoutubeEmbedUrl()}${id}`;
  }

  const videoIdRegex = /v=([-\w]+)/gm;
  const matches = videoIdRegex.exec(url);

  if (!matches || !matches[1]) {
    return null;
  }

  let outputUrl = `${getYoutubeEmbedUrl()}${matches[1]}`;

  return outputUrl;
};

export function getYTThumbnail(videoURL: string): string {
  const videoID = getVideoIDFromYTLink(videoURL);
  return 'http://i3.ytimg.com/vi/' + videoID + '/hqdefault.jpg';
}

export function getVideoIDFromYTLink(url: string): string {
  if (!url) return '';
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : '';
}
