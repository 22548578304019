import React from 'react';

import { AddButtonRotating, ArrowToggle, RemoveButtonBin } from '@idot-digital/custom-material-ui';
import '@idot-digital/custom-material-ui/dist/index.css';

import { Tooltip } from '@material-ui/core';

import './groupStyle.css';

export interface GroupCardManagerProps {
  title: React.ReactNode;
  children: React.ReactNode;
  onAdd?: () => void;
  onRemove?: () => void;
  className?: string;
  defaultOpen?: boolean;
}

export default function GroupCardManager(props: GroupCardManagerProps) {
  const [open, setOpen] = React.useState(props.defaultOpen ?? false);
  const [animate, setAnimate] = React.useState(false);

  return (
    <React.Fragment>
      <div className={['group', props.className].join(' ')}>
        <div className="groupWrapper">
          {props.onRemove && (
            <Tooltip title="Eintrag löschen" className="groupRemoveButton">
              <div>
                <RemoveButtonBin onClick={props.onRemove} />
              </div>
            </Tooltip>
          )}
          <h2 className="groupTitle">{props.title}</h2>
          <ArrowToggle
            onClick={() => {
              setAnimate(true);
              setOpen((open) => !open);
            }}
            active={open}
            className="openArrow"
          />
        </div>

        <div className={['groupContent', open ? 'open' : 'close', animate ? 'animate' : ''].join(' ')}>
          {open && (
            <>
              {props.children}
              {props.onAdd && (
                <Tooltip title="Neuen Eintrag erstellen" className="groupAddButton">
                  <div>
                    <AddButtonRotating onClick={props.onAdd} />
                  </div>
                </Tooltip>
              )}
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
