import React from 'react';

import { ParsedTableData, TableData, TableDataEntry } from '../../../Interfaces';
import GroupCardManager from '../../GroupCardManager';
import ColorEditor from '../Generic/ColorEditor';

import { styled, useTheme } from '@material-ui/core/styles';
import { Button, IconButton, Popover } from '@material-ui/core';
import {
  AddRounded,
  ArrowDropDownRounded,
  ArrowDropUpRounded,
  ArrowLeftRounded,
  ArrowRightRounded,
  DeleteRounded,
  FormatAlignCenterRounded,
  FormatAlignLeftRounded,
  FormatAlignRightRounded,
  FormatBoldRounded,
  FormatItalicRounded,
  FormatUnderlinedRounded,
} from '@material-ui/icons';

import { v4 as uuidv4 } from 'uuid';

import './tableEditor.css';

const Arrow = styled(IconButton)({
  padding: 0,
});

const Placeholder = () => <div className="placeholder" />;

export interface TableEditorProps {
  data: TableData;
  callback: (data: TableData) => void;
  headline: string;
}

interface ActiveCell {
  rowIndex?: number;
  spanIndex?: number;
}

interface ActiveCellStyle {
  left: number;
  top: number;
  width: number;
  height: number;
}

const noActiveCell = {
  rowIndex: undefined,
  spanIndex: undefined,
};

const noActiveCellStyle = {
  left: 0,
  top: 0,
  width: 0,
  height: 0,
};

const parseTableData = (data: TableData): ParsedTableData => {
  return data.map((row) => row.filter((cell) => 'content' in cell)) as ParsedTableData;
};

export default function TableEditor(props: TableEditorProps) {
  const [activeCell, setActiveCell] = React.useState(noActiveCell as ActiveCell);
  const [activeCellStyle, setActiveCellStyle] = React.useState(noActiveCellStyle as ActiveCellStyle);

  const [activeCellExpander, setActiveCellExpander] = React.useState(noActiveCell as ActiveCell);
  const [activeCellExpanderStyle, setActiveCellExpanderStyle] = React.useState(noActiveCellStyle as ActiveCellStyle);

  const [hintergrundfarbeOpen, setHintergrundfarbeOpen] = React.useState<HTMLButtonElement | null>(null);
  const [farbeOpen, setFarbeOpen] = React.useState<HTMLButtonElement | null>(null);

  const table = React.useRef() as React.RefObject<HTMLTableElement>;

  const textField = React.useRef() as React.RefObject<HTMLInputElement>;

  const theme = useTheme();

  React.useLayoutEffect(() => {
    if (activeCell.rowIndex !== undefined && activeCell.spanIndex !== undefined) {
      const target = table.current?.firstElementChild?.childNodes[activeCell.rowIndex + 1]?.childNodes[
        activeCell.spanIndex + 1
      ] as HTMLTableDataCellElement;

      const rect = target.getBoundingClientRect();
      const tableRect = table.current?.getBoundingClientRect();

      if (tableRect)
        setActiveCellStyle({
          left: rect.left - tableRect.left,
          top: rect.top - tableRect.top,
          width: rect.width,
          height: rect.height,
        });
    }
  }, [props.data, activeCell]);

  React.useLayoutEffect(() => {
    if (activeCellExpander.rowIndex !== undefined && activeCellExpander.spanIndex !== undefined) {
      const target = table.current?.firstElementChild?.childNodes[activeCellExpander.rowIndex]?.childNodes[
        activeCellExpander.spanIndex
      ] as HTMLTableDataCellElement;

      const rect = target.getBoundingClientRect();
      const tableRect = table.current?.getBoundingClientRect();

      if (tableRect)
        setActiveCellExpanderStyle({
          left: rect.left - tableRect.left,
          top: rect.top - tableRect.top,
          width: rect.width,
          height: rect.height,
        });
    }
  }, [props.data, activeCellExpander]);

  // Deselect activeCellExpander or activeCell
  React.useLayoutEffect(() => {
    if (activeCellExpander.spanIndex !== undefined && activeCellExpander.rowIndex !== undefined)
      setActiveCell(noActiveCell);
  }, [activeCellExpander]);
  React.useLayoutEffect(() => {
    if (activeCell.spanIndex !== undefined && activeCell.rowIndex !== undefined) setActiveCellExpander(noActiveCell);
  }, [activeCell]);

  // Autofocus cell to edit text immediately
  React.useEffect(() => {
    if (activeCell.spanIndex !== undefined && activeCell.rowIndex !== undefined) textField.current?.focus();
  }, [activeCell]);

  const kebabToCamel = (object: any) =>
    Object.fromEntries(
      Object.entries(object).map(([key, value]) => [
        // Convert kebab to camel case
        key.replaceAll(/-./g, (match) => match[1].toUpperCase()),
        value,
      ]),
    );

  const indexToExcelLetters = (index: number): string => {
    var mod = index % 26,
      pow = (index / 26) | 0,
      out = mod ? String.fromCharCode(64 + mod) : (--pow, 'Z');
    return pow ? indexToExcelLetters(pow) + out : out;
  };

  // Return column, row of the desired dataset entry
  const parsedIdToDataset = (id: string): [number, number] => {
    for (let j = activeCell.spanIndex as number; j < props.data[activeCell.rowIndex as number].length; j++) {
      if (props.data[activeCell.rowIndex as number][j].id === id) return [j, activeCell.rowIndex as number];
    }
    return [-1, -1];
  };

  const cellsAreEmpty = (columnShift: number, rowShift: number): boolean => {
    const [targetColumn, targetRow] = parsedIdToDataset((currentCellTarget as TableDataEntry).id);

    if (!columnShift)
      for (let i = 0; i < ((currentCellTarget as TableDataEntry).colspan || 1); i++) {
        const destination = props.data[targetRow + rowShift]?.[targetColumn + i];

        if (
          // Either no destination
          !destination ||
          // Or content in destination is filled
          ('content' in destination && destination.content !== '')
        )
          return false;

        // Or content by id from destination is filled
        if (!('content' in destination) && destination) {
          let colindex = targetColumn + i;
          let rowindex = targetRow + rowShift;

          while (props.data[rowindex - 1]?.[colindex]?.id === destination.id) {
            rowindex -= 1;
          }

          while (props.data[rowindex]?.[colindex - 1]?.id === destination.id) {
            colindex -= 1;
          }

          if ((props.data[rowindex][colindex] as TableDataEntry).content !== '') return false;
        }
      }
    else
      for (let i = 0; i < ((currentCellTarget as TableDataEntry).rowspan || 1); i++) {
        const destination = props.data[targetRow + i]?.[targetColumn + columnShift];
        if (
          // Either no destination
          !destination ||
          // Or content in destination is filled
          ('content' in destination && destination.content !== '')
        )
          return false;

        // Or content by id from destination is filled
        if (!('content' in destination) && destination) {
          let colindex = targetColumn + columnShift;
          let rowindex = targetRow + i;

          while (props.data[rowindex - 1]?.[colindex]?.id === destination.id) {
            rowindex -= 1;
          }

          while (props.data[rowindex]?.[colindex - 1]?.id === destination.id) {
            colindex -= 1;
          }

          if ((props.data[rowindex][colindex] as TableDataEntry).content !== '') return false;
        }
      }

    return true;
  };

  // Find first occurrence of desired id -- first item is always going to be the correct element
  const setNewActiveCell = (newData: TableData) => {
    if (currentCellTarget)
      for (let i = 0; i < newData.length; i++) {
        for (let j = 0; j < newData[i].length; j++) {
          if (newData[i][j].id === currentCellTarget.id)
            return setActiveCell({
              rowIndex: i,
              spanIndex: j,
            });
        }
      }
  };

  const parsedData = parseTableData(props.data);

  const currentCellTarget =
    activeCell.rowIndex !== undefined && activeCell.spanIndex !== undefined
      ? parsedData[activeCell.rowIndex][activeCell.spanIndex]
      : undefined;

  return (
    <GroupCardManager title={props.headline}>
      <div className="overflowWrapper">
        <div className="tableEditorToolbar">
          <div>
            <div>
              <IconButton
                onClick={() => {
                  if (currentCellTarget) {
                    const newData = [...props.data];

                    // Get target cell coordinates
                    const [targetColumn, targetRow] = parsedIdToDataset(currentCellTarget.id);

                    // Toggle Bold
                    const data = { ...currentCellTarget };
                    if ('font-weight' in currentCellTarget && currentCellTarget['font-weight'] === 'bold')
                      delete data['font-weight'];
                    else data['font-weight'] = 'bold';

                    newData[targetRow][targetColumn] = data;

                    props.callback(newData);
                  }
                }}
              >
                <FormatBoldRounded />
              </IconButton>
              <IconButton
                onClick={() => {
                  if (currentCellTarget) {
                    const newData = [...props.data];

                    // Get target cell coordinates
                    const [targetColumn, targetRow] = parsedIdToDataset(currentCellTarget.id);

                    // Toggle Italic
                    const data = { ...currentCellTarget };
                    if ('font-style' in currentCellTarget && currentCellTarget['font-style'] === 'italic')
                      delete data['font-style'];
                    else data['font-style'] = 'italic';

                    newData[targetRow][targetColumn] = data;

                    props.callback(newData);
                  }
                }}
              >
                <FormatItalicRounded />
              </IconButton>
              <IconButton
                onClick={() => {
                  if (currentCellTarget) {
                    const newData = [...props.data];

                    // Get target cell coordinates
                    const [targetColumn, targetRow] = parsedIdToDataset(currentCellTarget.id);

                    // Toggle Underlined
                    const data = { ...currentCellTarget };
                    if ('text-decoration' in currentCellTarget && currentCellTarget['text-decoration'] === 'underline')
                      delete data['text-decoration'];
                    else data['text-decoration'] = 'underline';

                    newData[targetRow][targetColumn] = data;

                    props.callback(newData);
                  }
                }}
              >
                <FormatUnderlinedRounded />
              </IconButton>
            </div>

            <div>
              <IconButton
                onClick={() => {
                  if (currentCellTarget) {
                    const newData = [...props.data];

                    // Get target cell coordinates
                    const [targetColumn, targetRow] = parsedIdToDataset(currentCellTarget.id);

                    // Toggle Underlined
                    const data = { ...currentCellTarget };
                    if ('text-align' in currentCellTarget && currentCellTarget['text-align'] === 'start')
                      delete data['text-align'];
                    else data['text-align'] = 'start';

                    newData[targetRow][targetColumn] = data;

                    props.callback(newData);
                  }
                }}
              >
                <FormatAlignLeftRounded />
              </IconButton>
              <IconButton
                onClick={() => {
                  if (currentCellTarget) {
                    const newData = [...props.data];

                    // Get target cell coordinates
                    const [targetColumn, targetRow] = parsedIdToDataset(currentCellTarget.id);

                    // Toggle Underlined
                    const data = { ...currentCellTarget };
                    if ('text-align' in currentCellTarget && currentCellTarget['text-align'] === 'center')
                      delete data['text-align'];
                    else data['text-align'] = 'center';

                    newData[targetRow][targetColumn] = data;

                    props.callback(newData);
                  }
                }}
              >
                <FormatAlignCenterRounded />
              </IconButton>
              <IconButton
                onClick={() => {
                  if (currentCellTarget) {
                    const newData = [...props.data];

                    // Get target cell coordinates
                    const [targetColumn, targetRow] = parsedIdToDataset(currentCellTarget.id);

                    // Toggle Underlined
                    const data = { ...currentCellTarget };
                    if ('text-align' in currentCellTarget && currentCellTarget['text-align'] === 'end')
                      delete data['text-align'];
                    else data['text-align'] = 'end';

                    newData[targetRow][targetColumn] = data;

                    props.callback(newData);
                  }
                }}
              >
                <FormatAlignRightRounded />
              </IconButton>
            </div>
          </div>

          <div className="bin">
            <IconButton
              onClick={() => {
                if (currentCellTarget) {
                  const newData = [...props.data];

                  // Get target cell coordinates
                  const [targetColumn, targetRow] = parsedIdToDataset(currentCellTarget.id);

                  // Overwrite old cells
                  for (let i = 0; i < (currentCellTarget.colspan || 1); i++) {
                    for (let j = 0; j < (currentCellTarget.rowspan || 1); j++) {
                      newData[targetRow + j][targetColumn + i] = { content: '', id: uuidv4() };
                    }
                  }

                  props.callback(newData);

                  setActiveCell(noActiveCell);
                } else if (activeCellExpander.spanIndex !== undefined && activeCellExpander.rowIndex !== undefined) {
                  const newData = [...props.data].map((row) => [...row]);

                  // Remove whole column
                  if (activeCellExpander.spanIndex) {
                    // Remove column cell in each row
                    for (let i = 0; i < newData.length; i++) {
                      // Check if cell is a placeholder
                      if (
                        (activeCellExpander.spanIndex as number) > 0 &&
                        !('content' in newData[i][(activeCellExpander.spanIndex as number) - 1])
                      ) {
                        const id = newData[i][(activeCellExpander.spanIndex as number) - 1].id;

                        // Seek the cell destination
                        var columnIndex = (activeCellExpander.spanIndex as number) - 1;

                        // Loop until id changes -- last will be correct cell target
                        while (columnIndex > 0 && newData[i][columnIndex - 1].id === id) {
                          columnIndex -= 1;
                        }

                        // Check if target cell is in same row
                        if ('content' in newData[i][columnIndex])
                          newData[i][columnIndex] = {
                            ...newData[i][columnIndex],
                            colspan: ((newData[i][columnIndex] as TableDataEntry).colspan as number) - 1,
                          };

                        newData[i] = [
                          ...newData[i].slice(0, (activeCellExpander.spanIndex as number) - 1),
                          ...newData[i].slice(activeCellExpander.spanIndex as number),
                        ];
                      }

                      // Check if cell had a colspan value set
                      else if (
                        'colspan' in (newData[i][(activeCellExpander.spanIndex as number) - 1] as TableDataEntry) &&
                        ((newData[i][(activeCellExpander.spanIndex as number) - 1] as TableDataEntry)
                          .colspan as number) > 1
                      ) {
                        newData[i] = [
                          ...newData[i].slice(0, (activeCellExpander.spanIndex as number) - 1),
                          {
                            ...(newData[i][(activeCellExpander.spanIndex as number) - 1] as TableDataEntry),
                            colspan:
                              ((newData[i][(activeCellExpander.spanIndex as number) - 1] as TableDataEntry)
                                .colspan as number) - 1,
                          },
                          ...newData[i].slice((activeCellExpander.spanIndex as number) + 1),
                        ];
                      }

                      // Otherwise just cut the item out
                      else
                        newData[i] = [
                          ...newData[i].slice(0, (activeCellExpander.spanIndex as number) - 1),
                          ...newData[i].slice(activeCellExpander.spanIndex as number),
                        ];
                    }

                    // Check if table is empty
                    if (newData[0]?.length === 0) newData.splice(0, newData.length, [{ content: '', id: uuidv4() }]);
                  }
                  // Remove whole row
                  else if (activeCellExpander.rowIndex) {
                    // Check for placeholder entries in order to short rowspan
                    for (let i = 0; i < newData[(activeCellExpander.rowIndex as number) - 1].length; i++) {
                      // Check if cell is a placeholder
                      if (
                        (activeCellExpander.rowIndex as number) > 0 &&
                        !('content' in newData[(activeCellExpander.rowIndex as number) - 1][i])
                      ) {
                        const id = newData[(activeCellExpander.rowIndex as number) - 1][i].id;

                        // Seek the cell destination
                        var rowIndex = (activeCellExpander.rowIndex as number) - 1;

                        // Loop until id changes -- last will be correct cell target
                        while (rowIndex > 0 && newData[rowIndex - 1][i].id === id) {
                          rowIndex -= 1;
                        }

                        // Check if target cell is in same row
                        if ('content' in newData[rowIndex][i])
                          newData[rowIndex][i] = {
                            ...newData[rowIndex][i],
                            rowspan: ((newData[rowIndex][i] as TableDataEntry).rowspan as number) - 1,
                          };
                      }

                      // Check if cell had a colspan value set
                      else if (
                        'rowspan' in (newData[(activeCellExpander.rowIndex as number) - 1][i] as TableDataEntry) &&
                        ((newData[(activeCellExpander.rowIndex as number) - 1][i] as TableDataEntry)
                          .rowspan as number) > 1
                      ) {
                        newData[activeCellExpander.rowIndex as number][i] = {
                          ...newData[(activeCellExpander.rowIndex as number) - 1][i],
                          rowspan:
                            ((newData[(activeCellExpander.rowIndex as number) - 1][i] as TableDataEntry)
                              .rowspan as number) - 1,
                        };
                      }
                    }

                    newData.splice((activeCellExpander.rowIndex as number) - 1, 1);

                    if (newData.length === 0) newData.push([{ content: '', id: uuidv4() }]);
                  }
                  // If top left is removed the whole table will be reset
                  else newData.splice(0, newData.length, [{ content: '', id: uuidv4() }]);

                  props.callback(newData);

                  // Set new table cell target
                  setNewActiveCell(parseTableData(newData));

                  // Unselect current expander
                  setActiveCellExpander(noActiveCell);
                }
              }}
            >
              <DeleteRounded />
            </IconButton>
          </div>

          <div className="colorPicker">
            <Button
              color="primary"
              variant="contained"
              onClick={(event) => setHintergrundfarbeOpen(event.currentTarget)}
            >
              Hintergrundfarbe
            </Button>
            <Popover
              open={Boolean(hintergrundfarbeOpen)}
              anchorEl={hintergrundfarbeOpen}
              onClose={() => setHintergrundfarbeOpen(null)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <ColorEditor
                value={(currentCellTarget?.['background-color'] as string) || '#fff'}
                text="Hintergrundfarbe"
                setFunc={(backgroundColor) => {
                  if (currentCellTarget) {
                    const newData = [...props.data];

                    // Get target cell coordinates
                    const [targetColumn, targetRow] = parsedIdToDataset(currentCellTarget.id);

                    newData[targetRow][targetColumn] = {
                      ...currentCellTarget,
                      'background-color': backgroundColor,
                    };

                    props.callback(newData);
                  }
                }}
              />
            </Popover>

            <Button color="primary" variant="contained" onClick={(event) => setFarbeOpen(event.currentTarget)}>
              Textfarbe
            </Button>
            <Popover
              open={Boolean(farbeOpen)}
              anchorEl={farbeOpen}
              onClose={() => setFarbeOpen(null)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <ColorEditor
                value={(currentCellTarget?.color as string) || '#000'}
                text="Textfarbe"
                setFunc={(color) => {
                  if (currentCellTarget) {
                    const newData = [...props.data];

                    // Get target cell coordinates
                    const [targetColumn, targetRow] = parsedIdToDataset(currentCellTarget.id);

                    newData[targetRow][targetColumn] = {
                      ...currentCellTarget,
                      color,
                    };

                    props.callback(newData);
                  }
                }}
              />
            </Popover>
          </div>
        </div>

        <div className="tableEditor">
          <table className="tableEditorTable" ref={table}>
            <tbody>
              <tr className="row index">
                <td className="cell index" onClick={() => setActiveCellExpander({ spanIndex: 0, rowIndex: 0 })} />
                {Array(props.data[0]?.length)
                  .fill(null)
                  .map((_, index) => (
                    <td
                      className="cell index"
                      onClick={() => setActiveCellExpander({ spanIndex: index + 1, rowIndex: 0 })}
                      key={index}
                    >
                      {indexToExcelLetters(index + 1)}
                    </td>
                  ))}
              </tr>
              {parsedData.map((row, rowIndex) => (
                <tr className="row" key={rowIndex}>
                  <td
                    className="cell index"
                    onClick={() => setActiveCellExpander({ spanIndex: 0, rowIndex: rowIndex + 1 })}
                  >
                    {rowIndex + 1}
                  </td>

                  {row.map((cell, spanIndex) => (
                    <td
                      className="cell"
                      colSpan={cell.colspan}
                      rowSpan={cell.rowspan}
                      key={cell.id}
                      onClick={() => {
                        setActiveCell({
                          rowIndex: rowIndex,
                          spanIndex: spanIndex,
                        });
                      }}
                      style={kebabToCamel(cell)}
                    >
                      {cell.content}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>

          {activeCellExpander.rowIndex !== undefined && activeCellExpander.spanIndex !== undefined && (
            <div className="cellExpander" style={kebabToCamel(activeCellExpanderStyle)}>
              {activeCellExpander.spanIndex > 0 && (
                <Arrow
                  className="left"
                  onClick={() => {
                    const newData = [...props.data].map((row) => [...row]);

                    // Add new column cell in each row
                    for (let i = 0; i < newData.length; i++) {
                      if (
                        (activeCellExpander.spanIndex as number) - 1 > 0 &&
                        newData[i][(activeCellExpander.spanIndex as number) - 2].id ===
                          newData[i][(activeCellExpander.spanIndex as number) - 1].id
                      ) {
                        const id = newData[i][(activeCellExpander.spanIndex as number) - 1].id;

                        // Seek the cell destination
                        var columnIndex = (activeCellExpander.spanIndex as number) - 2;
                        var rowIndex = i;

                        // Loop until id changes -- last will be correct cell target
                        while (rowIndex > 0 && props.data[rowIndex - 1][columnIndex].id === id) {
                          rowIndex -= 1;
                        }
                        while (columnIndex > 0 && props.data[rowIndex][columnIndex - 1].id === id) {
                          columnIndex -= 1;
                        }

                        // Expand cell size
                        newData[rowIndex][columnIndex] = {
                          ...props.data[rowIndex][columnIndex],
                          colspan: ((props.data[rowIndex][columnIndex] as TableDataEntry).colspan as number) + 1,
                        };

                        newData[i] = [
                          ...newData[i].slice(0, (activeCellExpander.spanIndex as number) - 1),
                          { id },
                          ...newData[i].slice((activeCellExpander.spanIndex as number) - 1),
                        ];
                      } else
                        newData[i] = [
                          ...newData[i].slice(0, (activeCellExpander.spanIndex as number) - 1),
                          { content: '', id: uuidv4() },
                          ...newData[i].slice((activeCellExpander.spanIndex as number) - 1),
                        ];
                    }

                    props.callback(newData);

                    // Set new table cell target
                    setNewActiveCell(parseTableData(newData));

                    // Unselect current expander
                    setActiveCellExpander(noActiveCell);
                  }}
                >
                  <AddRounded />
                </Arrow>
              )}

              <Arrow
                className="right"
                onClick={() => {
                  // Add new column cell in each row
                  const newData = [...props.data].map((row) => [...row]);

                  // Add new column cell in each row
                  for (let i = 0; i < newData.length; i++) {
                    if (
                      (activeCellExpander.spanIndex as number) < props.data[0]?.length &&
                      (activeCellExpander.spanIndex as number) > 0 &&
                      newData[i][(activeCellExpander.spanIndex as number) - 1].id ===
                        newData[i][activeCellExpander.spanIndex as number].id
                    ) {
                      const id = newData[i][activeCellExpander.spanIndex as number].id;

                      // Seek the cell destination
                      var columnIndex = (activeCellExpander.spanIndex as number) - 1;
                      var rowIndex = i;

                      // Loop until id changes -- last will be correct cell target
                      while (rowIndex > 0 && props.data[rowIndex - 1][columnIndex].id === id) {
                        rowIndex -= 1;
                      }
                      while (columnIndex > 0 && props.data[rowIndex][columnIndex - 1].id === id) {
                        columnIndex -= 1;
                      }

                      // Expand cell size
                      newData[rowIndex][columnIndex] = {
                        ...props.data[rowIndex][columnIndex],
                        colspan: ((props.data[rowIndex][columnIndex] as TableDataEntry).colspan as number) + 1,
                      };

                      newData[i] = [
                        ...newData[i].slice(0, activeCellExpander.spanIndex as number),
                        { id },
                        ...newData[i].slice(activeCellExpander.spanIndex as number),
                      ];
                    } else
                      newData[i] = [
                        ...newData[i].slice(0, activeCellExpander.spanIndex as number),
                        { content: '', id: uuidv4() },
                        ...newData[i].slice(activeCellExpander.spanIndex as number),
                      ];
                  }

                  props.callback(newData);

                  // Set new table cell target
                  setNewActiveCell(parseTableData(newData));

                  // Unselect current expander
                  setActiveCellExpander(noActiveCell);
                }}
              >
                <AddRounded />
              </Arrow>

              {activeCellExpander.rowIndex > 0 && (
                <Arrow
                  className="top"
                  onClick={() => {
                    // Add new column cell in each row
                    const dataCopy = [...props.data].map((row) => [...row]);

                    // Create new placeholder row
                    const newData = [
                      ...dataCopy.slice(0, (activeCellExpander.rowIndex as number) - 1),
                      [],
                      ...dataCopy.slice((activeCellExpander.rowIndex as number) - 1),
                    ];

                    // Add new cells to new row
                    for (let i = 0; i < newData[activeCellExpander.rowIndex as number]?.length; i++) {
                      if (
                        (activeCellExpander.rowIndex as number) - 1 > 0 &&
                        newData[(activeCellExpander.rowIndex as number) - 2][i].id ===
                          newData[activeCellExpander.rowIndex as number][i].id
                      ) {
                        const id = newData[activeCellExpander.rowIndex as number][i].id;

                        // Seek the cell destination
                        var columnIndex = i;
                        var rowIndex = (activeCellExpander.rowIndex as number) - 2;

                        // Loop until id changes -- last will be correct cell target
                        while (rowIndex > 0 && props.data[rowIndex - 1][columnIndex].id === id) {
                          rowIndex -= 1;
                        }
                        while (columnIndex > 0 && props.data[rowIndex][columnIndex - 1].id === id) {
                          columnIndex -= 1;
                        }

                        // Expand cell size
                        newData[rowIndex][columnIndex] = {
                          ...props.data[rowIndex][columnIndex],
                          rowspan: ((props.data[rowIndex][columnIndex] as TableDataEntry).rowspan as number) + 1,
                        };

                        newData[(activeCellExpander.rowIndex as number) - 1].push({ id });
                      } else newData[(activeCellExpander.rowIndex as number) - 1].push({ content: '', id: uuidv4() });
                    }

                    props.callback(newData);

                    // Set new table cell target
                    setNewActiveCell(parseTableData(newData));

                    // Unselect current expander
                    setActiveCellExpander(noActiveCell);
                  }}
                >
                  <AddRounded />
                </Arrow>
              )}

              <Arrow
                className="bottom"
                onClick={() => {
                  // Add new column cell in each row
                  const dataCopy = [...props.data].map((row) => [...row]);

                  // Create new placeholder row
                  const newData = [
                    ...dataCopy.slice(0, activeCellExpander.rowIndex as number),
                    [],
                    ...dataCopy.slice(activeCellExpander.rowIndex as number),
                  ];

                  // Add new cells to new row
                  for (
                    let i = 0;
                    i <
                    (newData[(activeCellExpander.rowIndex as number) + 1]?.length ||
                      newData[(activeCellExpander.rowIndex as number) - 1]?.length);
                    i++
                  ) {
                    if (
                      (activeCellExpander.rowIndex as number) < props.data.length &&
                      (activeCellExpander.rowIndex as number) > 0 &&
                      newData[(activeCellExpander.rowIndex as number) - 1][i].id ===
                        newData[(activeCellExpander.rowIndex as number) + 1][i].id
                    ) {
                      const id = newData[(activeCellExpander.rowIndex as number) - 1][i].id;

                      // Seek the cell destination
                      var columnIndex = i;
                      var rowIndex = (activeCellExpander.rowIndex as number) - 1;

                      // Loop until id changes -- last will be correct cell target
                      while (rowIndex > 0 && props.data[rowIndex - 1][columnIndex].id === id) {
                        rowIndex -= 1;
                      }
                      while (columnIndex > 0 && props.data[rowIndex][columnIndex - 1].id === id) {
                        columnIndex -= 1;
                      }

                      // Expand cell size
                      newData[rowIndex][columnIndex] = {
                        ...props.data[rowIndex][columnIndex],
                        rowspan: ((props.data[rowIndex][columnIndex] as TableDataEntry).rowspan as number) + 1,
                      };

                      newData[activeCellExpander.rowIndex as number].push({ id });
                    } else newData[activeCellExpander.rowIndex as number].push({ content: '', id: uuidv4() });
                  }

                  props.callback(newData);

                  // Set new table cell target
                  setNewActiveCell(parseTableData(newData));

                  // Unselect current expander
                  setActiveCellExpander(noActiveCell);
                }}
              >
                <AddRounded />
              </Arrow>
            </div>
          )}

          {currentCellTarget && (
            <div
              className="activeCell"
              style={kebabToCamel({
                backgroundColor: 'white',
                ...currentCellTarget,
                ...activeCellStyle,
                borderColor: theme.palette.primary.main,
              })}
            >
              <div className="top">
                {cellsAreEmpty(0, -1) ? (
                  <Arrow
                    color="primary"
                    onClick={() => {
                      const newData = [...props.data];

                      // Get target cell coordinates
                      const [targetColumn, targetRow] = parsedIdToDataset(currentCellTarget.id);

                      // Edit potential greater cell that has been clipped
                      const alreadyEditedIds: string[] = [];

                      for (let i = 0; i < (currentCellTarget.colspan || 1); i++) {
                        const target = newData[targetRow - 1][targetColumn + i];

                        // Break when id is already satisfied
                        if (alreadyEditedIds.includes(target.id)) continue;

                        // Check if target is the main cell
                        if ('colspan' in target || 'rowspan' in target) {
                          alreadyEditedIds.push(target.id);

                          if ((target.colspan || 1) + i > (currentCellTarget.colspan || 1)) {
                            newData[targetRow - 1][targetColumn + (currentCellTarget.colspan || 1)] = {
                              ...target,
                              colspan: (target.colspan || 1) + i - (currentCellTarget.colspan || 1),
                            };
                          }
                        }

                        // Check if target is a virtual cell
                        let oldSpan;

                        if (!('content' in target)) {
                          alreadyEditedIds.push(target.id);

                          let colindex = targetColumn + i;
                          let rowindex = targetRow - 1;

                          while (newData[rowindex - 1]?.[colindex]?.id === target.id) {
                            rowindex -= 1;
                          }

                          while (newData[rowindex]?.[colindex - 1]?.id === target.id) {
                            colindex -= 1;
                          }

                          oldSpan = (newData[rowindex][colindex] as TableDataEntry).colspan || 1;

                          // Shorten cell
                          if (((newData[rowindex][colindex] as TableDataEntry).rowspan || 1) > 1) {
                            newData[rowindex][colindex] = {
                              ...newData[rowindex][colindex],
                              rowspan: ((newData[rowindex][colindex] as TableDataEntry).rowspan as number) - 1,
                            };
                          }
                          if (((newData[rowindex][colindex] as TableDataEntry).colspan || 1) > 1) {
                            // Generate new cell
                            let newId = uuidv4();

                            newData[targetRow - 1][colindex] = {
                              content: '',
                              id: newId,
                              colspan: targetColumn - colindex,
                            };

                            for (
                              let j = 1;
                              j < ((newData[rowindex][colindex] as TableDataEntry).colspan as number);
                              j++
                            ) {
                              newData[targetRow - 1][colindex + j] = { id: newId };
                            }
                          } else continue;

                          // Check if cell is cut into two pieces
                          let potentialNewColspan =
                            oldSpan - ((currentCellTarget.colspan || 1) + (targetColumn - colindex));

                          if (potentialNewColspan > 0) {
                            let newId = uuidv4();

                            newData[targetRow - 1][colindex + oldSpan - potentialNewColspan] = {
                              content: '',
                              id: newId,
                              colspan: potentialNewColspan,
                            };

                            for (let j = 1; j < potentialNewColspan; j++) {
                              newData[targetRow - 1][colindex + oldSpan - potentialNewColspan + j] = {
                                id: newId,
                              };
                            }
                          }
                        }
                      }

                      // Set new main cell
                      newData[targetRow - 1][targetColumn] = {
                        ...currentCellTarget,
                        rowspan: (currentCellTarget.rowspan || 1) + 1,
                      };

                      // Overwrite old cell
                      newData[targetRow][targetColumn] = { id: currentCellTarget.id };

                      // And apply leftover colspans
                      for (let i = 1; i < (currentCellTarget.colspan || 1); i++) {
                        newData[targetRow - 1][targetColumn + i] = { id: currentCellTarget.id };
                      }

                      // Set new table cell target
                      setNewActiveCell(parseTableData(newData));

                      props.callback(newData);
                    }}
                  >
                    <ArrowDropUpRounded />
                  </Arrow>
                ) : (
                  <Placeholder />
                )}
                {(currentCellTarget.rowspan || 1) > 1 ? (
                  <Arrow
                    color="primary"
                    onClick={() => {
                      const newData = [...props.data];

                      // Get target cell coordinates
                      const [targetColumn, targetRow] = parsedIdToDataset(currentCellTarget.id);

                      // Set new main cell
                      newData[targetRow + 1][targetColumn] = {
                        ...currentCellTarget,
                        rowspan: (currentCellTarget.rowspan as number) - 1,
                      };

                      // Overwrite old cells
                      for (let i = 0; i < (currentCellTarget.colspan || 1); i++) {
                        newData[targetRow][targetColumn + i] = { content: '', id: uuidv4() };
                      }

                      // Set new table cell target
                      setNewActiveCell(parseTableData(newData));

                      props.callback(newData);
                    }}
                  >
                    <ArrowDropDownRounded />
                  </Arrow>
                ) : (
                  <Placeholder />
                )}
              </div>
              <div className="bottom">
                {(currentCellTarget.rowspan || 1) > 1 ? (
                  <Arrow
                    color="primary"
                    onClick={() => {
                      const newData = [...props.data];

                      // Get target cell coordinates
                      const [targetColumn, targetRow] = parsedIdToDataset(currentCellTarget.id);

                      // Set new main rowspan height
                      newData[targetRow][targetColumn] = {
                        ...currentCellTarget,
                        rowspan: (currentCellTarget.rowspan as number) - 1,
                      };

                      // Overwrite old rowspan cells
                      for (let i = 0; i < (currentCellTarget.colspan || 1); i++) {
                        newData[targetRow + (currentCellTarget.rowspan as number) - 1][targetColumn + i] = {
                          content: '',
                          id: uuidv4(),
                        };
                      }

                      // Set new table cell target
                      setNewActiveCell(parseTableData(newData));

                      props.callback(newData);
                    }}
                  >
                    <ArrowDropUpRounded />
                  </Arrow>
                ) : (
                  <Placeholder />
                )}
                {cellsAreEmpty(0, currentCellTarget.rowspan || 1) ? (
                  <Arrow
                    color="primary"
                    onClick={() => {
                      const newData = [...props.data];

                      // Get target cell coordinates
                      const [targetColumn, targetRow] = parsedIdToDataset(currentCellTarget.id);

                      // Edit potential greater cell that has been clipped
                      const alreadyEditedIds: string[] = [];

                      for (let i = 0; i < (currentCellTarget.colspan || 1); i++) {
                        const target = newData[targetRow + (currentCellTarget.rowspan || 1)][targetColumn + i];

                        // Break when id is already satisfied
                        if (alreadyEditedIds.includes(target.id)) continue;

                        // Check if target is the main cell
                        if ('colspan' in target || 'rowspan' in target) {
                          alreadyEditedIds.push(target.id);

                          if ((target.colspan || 1) + i > (currentCellTarget.colspan || 1)) {
                            newData[targetRow + (currentCellTarget.rowspan || 1)][
                              targetColumn + (currentCellTarget.colspan || 1)
                            ] = {
                              ...target,
                              colspan: (target.colspan || 1) + i - (currentCellTarget.colspan || 1),
                            };
                          }

                          if ((target.rowspan || 1) > 1) {
                            newData[targetRow + (currentCellTarget.rowspan || 1) + 1][
                              targetColumn + (currentCellTarget.colspan || 1)
                            ] = {
                              ...target,
                              rowspan: (target.rowspan as number) - 1,
                            };

                            // Generate new cell
                            let newId = uuidv4();

                            newData[targetRow + (currentCellTarget.rowspan || 1)][
                              targetColumn + (currentCellTarget.colspan || 1)
                            ] = {
                              content: '',
                              id: newId,
                              rowspan: 1,
                            };

                            for (let j = 1; j < (target.colspan || 1); j++) {
                              newData[targetRow + (currentCellTarget.rowspan || 1)][
                                targetColumn + (currentCellTarget.colspan || 1) + j
                              ] = {
                                id: newId,
                              };
                            }
                          }
                        }

                        // Check if target is a virtual cell
                        let oldSpan;

                        if (!('content' in target)) {
                          alreadyEditedIds.push(target.id);

                          let colindex = targetColumn + i;
                          let rowindex = targetRow + (currentCellTarget.rowspan || 1);

                          while (newData[rowindex]?.[colindex - 1]?.id === target.id) {
                            colindex -= 1;
                          }

                          oldSpan = (newData[rowindex][colindex] as TableDataEntry).colspan || 1;

                          // Shorten cell
                          if (((newData[rowindex][colindex] as TableDataEntry).rowspan || 1) > 1) {
                            newData[rowindex + 1][colindex] = {
                              ...newData[rowindex][colindex],
                              rowspan: ((newData[rowindex][colindex] as TableDataEntry).rowspan as number) - 1,
                            };
                          }
                          if (((newData[rowindex][colindex] as TableDataEntry).colspan || 1) > 1) {
                            // Generate new cell
                            let newId = uuidv4();

                            newData[rowindex][colindex] = {
                              content: '',
                              id: newId,
                              colspan: targetColumn - colindex,
                            };

                            for (
                              let j = 1;
                              j < ((newData[rowindex][colindex] as TableDataEntry).colspan as number);
                              j++
                            ) {
                              newData[rowindex][colindex + j] = { id: newId };
                            }
                          } else continue;

                          // Check if cell is cut into two pieces
                          let potentialNewColspan =
                            oldSpan - ((currentCellTarget.colspan || 1) + (targetColumn - colindex));

                          if (potentialNewColspan > 0) {
                            let newId = uuidv4();

                            newData[rowindex][colindex + oldSpan - potentialNewColspan] = {
                              content: '',
                              id: newId,
                              colspan: potentialNewColspan,
                            };

                            for (let j = 1; j < potentialNewColspan; j++) {
                              newData[rowindex][colindex + oldSpan - potentialNewColspan + j] = {
                                id: newId,
                              };
                            }
                          }
                        }
                      }

                      // Set new main cell
                      newData[targetRow][targetColumn] = {
                        ...currentCellTarget,
                        rowspan: (currentCellTarget.rowspan || 1) + 1,
                      };

                      // Apply new rowspan row cells
                      for (let i = 0; i < (currentCellTarget.colspan || 1); i++) {
                        newData[targetRow + (currentCellTarget.rowspan || 1)][targetColumn + i] = {
                          id: currentCellTarget.id,
                        };
                      }

                      // Set new table cell target
                      setNewActiveCell(parseTableData(newData));

                      props.callback(newData);
                    }}
                  >
                    <ArrowDropDownRounded />
                  </Arrow>
                ) : (
                  <Placeholder />
                )}
              </div>
              <div className="left">
                {cellsAreEmpty(-1, 0) ? (
                  <Arrow
                    color="primary"
                    onClick={() => {
                      const newData = [...props.data];

                      // Get target cell coordinates
                      const [targetColumn, targetRow] = parsedIdToDataset(currentCellTarget.id);

                      // Edit potential greater cell that has been clipped
                      const alreadyEditedIds: string[] = [];

                      for (let i = 0; i < (currentCellTarget.rowspan || 1); i++) {
                        const target = newData[targetRow + i][targetColumn - 1];

                        // Break when id is already satisfied
                        if (alreadyEditedIds.includes(target.id)) continue;

                        // Check if target is the main cell
                        if ('colspan' in target || 'rowspan' in target) {
                          alreadyEditedIds.push(target.id);

                          if ((target.rowspan || 1) + i > (currentCellTarget.rowspan || 1)) {
                            newData[targetRow + (currentCellTarget.rowspan || 1)][targetColumn - 1] = {
                              ...target,
                              rowspan: (target.rowspan || 1) + i - (currentCellTarget.rowspan || 1),
                            };
                          }
                        }

                        // Check if target is a virtual cell
                        let oldRow;

                        if (!('content' in target)) {
                          alreadyEditedIds.push(target.id);

                          let colindex = targetColumn - 1;
                          let rowindex = targetRow + i;

                          while (newData[rowindex - 1]?.[colindex]?.id === target.id) {
                            rowindex -= 1;
                          }

                          while (newData[rowindex]?.[colindex - 1]?.id === target.id) {
                            colindex -= 1;
                          }

                          oldRow = (newData[rowindex][colindex] as TableDataEntry).rowspan || 1;

                          // Shorten cell
                          if (((newData[rowindex][colindex] as TableDataEntry).colspan || 1) > 1) {
                            newData[rowindex][colindex] = {
                              ...newData[rowindex][colindex],
                              colspan: ((newData[rowindex][colindex] as TableDataEntry).colspan as number) - 1,
                            };
                          }
                          if (((newData[rowindex][colindex] as TableDataEntry).rowspan || 1) > 1) {
                            // Generate new cell
                            let newId = uuidv4();

                            newData[rowindex][targetColumn - 1] = {
                              content: '',
                              id: newId,
                              rowspan: targetRow - rowindex,
                            };

                            for (
                              let j = 1;
                              j < ((newData[rowindex][colindex] as TableDataEntry).rowspan as number);
                              j++
                            ) {
                              newData[rowindex + j][targetColumn - 1] = { id: newId };
                            }
                          } else continue;

                          // Check if cell is cut into two pieces
                          let potentialNewRowspan =
                            oldRow - ((currentCellTarget.rowspan || 1) + (targetRow - rowindex));

                          if (potentialNewRowspan > 0) {
                            let newId = uuidv4();

                            newData[rowindex + oldRow - potentialNewRowspan][targetColumn - 1] = {
                              content: '',
                              id: newId,
                              rowspan: potentialNewRowspan,
                            };

                            for (let j = 1; j < potentialNewRowspan; j++) {
                              newData[rowindex + oldRow - potentialNewRowspan + j][targetColumn - 1] = {
                                id: newId,
                              };
                            }
                          }
                        }
                      }

                      // Set new main cell
                      newData[targetRow][targetColumn - 1] = {
                        ...currentCellTarget,
                        colspan: (currentCellTarget.colspan || 1) + 1,
                      };

                      // Overwrite old cell
                      newData[targetRow][targetColumn] = { id: currentCellTarget.id };

                      // And apply leftover rowspans
                      for (let i = 1; i < (currentCellTarget.rowspan || 1); i++) {
                        newData[targetRow + i][targetColumn - 1] = { id: currentCellTarget.id };
                      }

                      // Set new table cell target
                      setNewActiveCell(parseTableData(newData));

                      props.callback(newData);
                    }}
                  >
                    <ArrowLeftRounded />
                  </Arrow>
                ) : (
                  <Placeholder />
                )}
                {(currentCellTarget.colspan || 1) > 1 ? (
                  <Arrow
                    color="primary"
                    onClick={() => {
                      const newData = [...props.data];

                      // Get target cell coordinates
                      const [targetColumn, targetRow] = parsedIdToDataset(currentCellTarget.id);

                      // Set new main cell
                      newData[targetRow][targetColumn + 1] = {
                        ...currentCellTarget,
                        colspan: (currentCellTarget.colspan as number) - 1,
                      };

                      // Overwrite old cells
                      for (let i = 0; i < (currentCellTarget.rowspan || 1); i++) {
                        newData[targetRow + i][targetColumn] = { content: '', id: uuidv4() };
                      }

                      // Set new table cell target
                      setNewActiveCell(parseTableData(newData));

                      props.callback(newData);
                    }}
                  >
                    <ArrowRightRounded />
                  </Arrow>
                ) : (
                  <Placeholder />
                )}
              </div>
              <div className="right">
                {(currentCellTarget.colspan || 1) > 1 ? (
                  <Arrow
                    color="primary"
                    onClick={() => {
                      const newData = [...props.data];

                      // Get target cell coordinates
                      const [targetColumn, targetRow] = parsedIdToDataset(currentCellTarget.id);

                      // Set new main rowspan height
                      newData[targetRow][targetColumn] = {
                        ...currentCellTarget,
                        colspan: (currentCellTarget.colspan as number) - 1,
                      };

                      // Overwrite old rowspan cells
                      for (let i = 0; i < (currentCellTarget.rowspan || 1); i++) {
                        newData[targetRow + i][targetColumn + (currentCellTarget.colspan as number) - 1] = {
                          content: '',
                          id: uuidv4(),
                        };
                      }

                      // Set new table cell target
                      setNewActiveCell(parseTableData(newData));

                      props.callback(newData);
                    }}
                  >
                    <ArrowLeftRounded />
                  </Arrow>
                ) : (
                  <Placeholder />
                )}
                {cellsAreEmpty(currentCellTarget.colspan || 1, 0) ? (
                  <Arrow
                    color="primary"
                    onClick={() => {
                      const newData = [...props.data];

                      // Get target cell coordinates
                      const [targetColumn, targetRow] = parsedIdToDataset(currentCellTarget.id);

                      // Edit potential greater cell that has been clipped
                      const alreadyEditedIds: string[] = [];

                      for (let i = 0; i < (currentCellTarget.rowspan || 1); i++) {
                        const target = newData[targetRow + i][targetColumn + (currentCellTarget.colspan || 1)];

                        // Break when id is already satisfied
                        if (alreadyEditedIds.includes(target.id)) continue;

                        // Check if target is the main cell
                        if ('colspan' in target || 'rowspan' in target) {
                          alreadyEditedIds.push(target.id);

                          if ((target.rowspan || 1) + i > (currentCellTarget.rowspan || 1)) {
                            newData[targetRow + (currentCellTarget.rowspan || 1)][
                              targetColumn + (currentCellTarget.colspan || 1)
                            ] = {
                              ...target,
                              rowspan: (target.rowspan || 1) + i - (currentCellTarget.rowspan || 1),
                            };
                          }

                          if ((target.colspan || 1) > 1) {
                            // Generate new cell
                            let newId = uuidv4();

                            newData[targetRow + i][targetColumn + (currentCellTarget.colspan || 1) + 1] = {
                              content: '',
                              id: newId,
                              rowspan: 1,
                              colspan: (target.colspan as number) - 1,
                            };

                            for (let j = 1; j < (target.colspan as number) - 1; j++) {
                              newData[targetRow + i][targetColumn + (currentCellTarget.colspan || 1) + 1 + j] = {
                                id: newId,
                              };
                            }
                          }
                        }

                        // Check if target is a virtual cell
                        let oldRow;

                        if (!('content' in target)) {
                          alreadyEditedIds.push(target.id);

                          let colindex = targetColumn + (currentCellTarget.colspan || 1);
                          let rowindex = targetRow + i;

                          while (newData[rowindex - 1]?.[colindex]?.id === target.id) {
                            rowindex -= 1;
                          }

                          oldRow = (newData[rowindex][colindex] as TableDataEntry).rowspan || 1;

                          // Shorten cell
                          if (((newData[rowindex][colindex] as TableDataEntry).colspan || 1) > 1) {
                            newData[rowindex][colindex + 1] = {
                              ...newData[rowindex][colindex],
                              colspan: ((newData[rowindex][colindex] as TableDataEntry).colspan as number) - 1,
                            };
                          }
                          if (((newData[rowindex][colindex] as TableDataEntry).rowspan || 1) > 1) {
                            // Generate new cell
                            let newId = uuidv4();

                            newData[rowindex][colindex] = {
                              content: '',
                              id: newId,
                              rowspan: targetRow - rowindex,
                            };

                            for (
                              let j = 1;
                              j < ((newData[rowindex][colindex] as TableDataEntry).rowspan as number);
                              j++
                            ) {
                              newData[rowindex + j][colindex] = { id: newId };
                            }
                          } else continue;

                          // Check if cell is cut into two pieces
                          let potentialNewRowspan =
                            oldRow - ((currentCellTarget.rowspan || 1) + (targetRow - rowindex));

                          if (potentialNewRowspan > 0) {
                            let newId = uuidv4();

                            newData[rowindex + oldRow - potentialNewRowspan][colindex] = {
                              content: '',
                              id: newId,
                              rowspan: potentialNewRowspan,
                            };

                            for (let j = 1; j < potentialNewRowspan; j++) {
                              newData[rowindex + oldRow - potentialNewRowspan + j][colindex] = {
                                id: newId,
                              };
                            }
                          }
                        }
                      }

                      // Set new main cell
                      newData[targetRow][targetColumn] = {
                        ...currentCellTarget,
                        colspan: (currentCellTarget.colspan || 1) + 1,
                      };

                      // Apply new colspan row cells
                      for (let i = 0; i < (currentCellTarget.rowspan || 1); i++) {
                        newData[targetRow + i][targetColumn + (currentCellTarget.colspan || 1)] = {
                          id: currentCellTarget.id,
                        };
                      }

                      // Set new table cell target
                      setNewActiveCell(parseTableData(newData));

                      props.callback(newData);
                    }}
                  >
                    <ArrowRightRounded />
                  </Arrow>
                ) : (
                  <Placeholder />
                )}
              </div>
              <input
                ref={textField}
                value={parsedData[activeCell.rowIndex as number][activeCell.spanIndex as number].content}
                style={kebabToCamel(currentCellTarget)}
                onChange={(event) => {
                  const newData = [...props.data];

                  const [targetColumn, targetRow] = parsedIdToDataset(currentCellTarget.id);

                  newData[targetRow][targetColumn] = {
                    ...newData[targetRow][targetColumn],
                    content: event.target.value,
                  };

                  props.callback(newData);
                }}
                className="cellText"
              />
            </div>
          )}
        </div>
      </div>
    </GroupCardManager>
  );
}
