import React from 'react';

import FileSelector, { FileResponse } from '@idot-digital/file-selector';

import { FileSelectorButton } from '@idot-digital/custom-material-ui';
import '@idot-digital/custom-material-ui/dist/index.css';

import { Tooltip } from '@material-ui/core';

import { useAuth } from '../../Authentication/AuthContext';
import config from '../../../config';

export interface ImageSelectorProps {
  callback: (path: string) => void;
  imagePath: string;
  hideButton?: boolean;
  open?: boolean;
  onClose?: () => void;
}

export default function ImageSelector(props: ImageSelectorProps) {
  const [open, setOpen] = React.useState(props.open ?? false);

  const { jwt, parseBaseUrl } = useAuth();

  const settings = {
    credentials: 'omit',
    headers: { Authorization: `Bearer ${jwt}`, referer: config.corsURL },
    mode: 'cors',
  } as Omit<RequestInit, 'method'>;

  React.useEffect(() => {
    if (props.open !== undefined) setOpen(props.open);
  }, [props.open]);

  return (
    <>
      <FileSelector
        open={open}
        onClose={() => {
          if (props.open !== undefined) {
            props.onClose?.();
          } else setOpen(false);
        }}
        links={{
          get: {
            url: parseBaseUrl('upload'),
            method: 'GET',
            settings,
          },
          rename: { url: parseBaseUrl('upload'), method: 'PUT', settings },
          delete: {
            url: parseBaseUrl('upload'),
            method: 'DELETE',
            settings,
          },
          upload: { url: parseBaseUrl('upload'), method: 'POST', settings },
          root: parseBaseUrl('cdn'),
        }}
        callbackDone={(files: FileResponse[]) => {
          if (files.length) props.callback(parseBaseUrl('cdn') + files[0].path);
          else props.callback('');

          if (props.open !== undefined) {
            props.onClose?.();
          } else setOpen(false);
        }}
        filter={(files) => [...files].reverse()}
        previewImage={(path) => path.replace('.jpg', '-low.webp')}
        pickedFiles={props.imagePath ? [props.imagePath] : undefined}
      />

      {!props.hideButton && (
        <Tooltip title="Bild auswählen" className="fileSelector">
          <div>
            <FileSelectorButton magnifierColor="white" onClick={() => setOpen(true)} />
          </div>
        </Tooltip>
      )}
    </>
  );
}
