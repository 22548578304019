import React from 'react';

import { NavigationLink } from '../../../Interfaces';
import LinkEditor from '../../LinkEditor';

export interface NavigationLinkEditorProps {
  data: NavigationLink;
  callback: (data: NavigationLink | null) => void;
  removable: boolean;
}

export default function NavigationLinkEditor(props: NavigationLinkEditorProps) {
  const [name, setName] = React.useState(props.data.name);
  const [href, setHref] = React.useState(props.data.href);

  React.useEffect(() => {
    props.callback({
      name,
      href,
      _key: props.data._key,
    });
  }, [name, href]);

  return (
    <LinkEditor
      onRemove={props.removable ? () => props.callback(null) : undefined}
      name={name}
      href={href}
      setName={setName}
      setHref={setHref}
      namePlaceholder="Navigationspunkt"
      color="primary"
    />
  );
}
