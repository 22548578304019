import React from 'react';

import { Box, TextField, Tooltip } from '@material-ui/core';

import GroupCardManager from '../../GroupCardManager';
import { useAuth } from '../../Authentication/AuthContext';
import { MadMax, MadMaxArchive } from '../../../Interfaces';
import config from '../../../config';
import MadMaxLinkEditor from './MadMaxLinkEditor';
import { addGroup, updateGroup } from '../../../functions';

import FileSelector from '@idot-digital/file-selector';
import { FileSelectorButton } from '@idot-digital/custom-material-ui';

import { v4 as uuidv4 } from 'uuid';

import './madMaxEditor.css';

export interface MadMaxEditorProps {
  headline: string;
  data: MadMaxArchive;
  callback: (data: MadMaxArchive | null) => void;
}

export default function MadMaxEditor(props: MadMaxEditorProps) {
  const [madMaxData, setMadMaxData] = React.useState(props.data.files);
  const [title, setTitle] = React.useState(props.data.title);
  const [content, setContent] = React.useState(props.data.content);
  const [img, setImage] = React.useState(props.data.img);

  const { parseBaseUrl, jwt } = useAuth();
  const [fileSelectorOpen, setFileSelectorOpen] = React.useState(false);

  const settings = {
    credentials: 'omit',
    headers: { Authorization: `Bearer ${jwt}`, referer: config.corsURL },
    mode: 'cors',
  } as Omit<RequestInit, 'method'>;

  const addMadMax = addGroup.bind({
    setFunc: setMadMaxData,
    origData: madMaxData,
    newElmt: {
      path: '',
      alt: '',
      _key: uuidv4(),
    } as MadMax,
  });

  React.useEffect(() => {
    props.callback({ ...props.data, title, content, files: madMaxData, img });
  }, [madMaxData, title, content, img]);

  return (
    <GroupCardManager title={props.headline} className="madMaxEditorCardManager">
      <FileSelector
        open={fileSelectorOpen}
        onClose={() => setFileSelectorOpen(false)}
        links={{
          get: {
            url: parseBaseUrl('upload'),
            method: 'GET',
            settings,
          },
          rename: { url: parseBaseUrl('upload'), method: 'PUT', settings },
          delete: {
            url: parseBaseUrl('upload'),
            method: 'DELETE',
            settings,
          },
          upload: { url: parseBaseUrl('upload'), method: 'POST', settings },
          root: parseBaseUrl('cdn'),
        }}
        callbackDone={(links) => {
          if (links.length) setImage({ path: parseBaseUrl('cdn') + links[0].path, alt: links[0].displayName });
          setFileSelectorOpen(false);
        }}
        filter={(files) => [...files].reverse()}
        previewImage={(path) => path.replace('.jpg', '-low.webgp')}
        pickedFiles={[img.path]}
      />

      <div className="madMaxEditorCardTitle">
        <TextField
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setTitle(event.target.value)}
          value={title}
          className="madMaxEditorCardTitleInput"
          label="Titel"
          InputProps={{ style: { fontSize: 'x-large' } }}
          error={!title}
        />
        <TextField
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setContent(event.target.value)}
          value={content}
          className="madMaxEditorCardTitleInput"
          label="Schlagzeile"
          InputProps={{ style: { fontSize: 'x-large' } }}
          error={!content}
        />

        <Box alignSelf="center">
          <Tooltip title="Bild auswählen" className="fileSelector">
            <Box>
              <FileSelectorButton magnifierColor="white" onClick={() => setFileSelectorOpen(true)} />
            </Box>
          </Tooltip>
        </Box>
      </div>

      <GroupCardManager title={'Archiv'} onAdd={addMadMax}>
        {madMaxData.map((madMax, index) => (
          <MadMaxLinkEditor
            data={madMax}
            callback={(data) => updateGroup(data, index, setMadMaxData, madMaxData)}
            key={madMax._key}
          />
        ))}
      </GroupCardManager>
    </GroupCardManager>
  );
}
