import React from 'react';

import { addGroup, updateGroup } from '../../functions';
import { SliderItem } from '../../Interfaces';
import GroupCardManager from '../GroupCardManager';
import ItemEditor from './ItemEditor';

import { v4 as uuidv4 } from 'uuid';
import { Tooltip } from '@material-ui/core';
import { AddButtonRotating } from '@idot-digital/custom-material-ui';

export interface SliderEditorProps {
  headline: string;
  data: SliderItem[];
  callback: (data: SliderItem[] | null) => void;
  omitGroup?: boolean;
}

export default function SliderEditor(props: SliderEditorProps) {
  const [sliderData, setSliderData] = React.useState(props.data);

  React.useEffect(() => {
    props.callback(sliderData);
  }, [sliderData]);

  const addLink = addGroup.bind({
    setFunc: setSliderData,
    origData: sliderData,
    newElmt: {
      img: {
        path: '',
        alt: '',
      },
      _key: uuidv4(),
    },
  });

  const slidesEditors = (
    <>
      {sliderData.map((item, index) => {
        return (
          <ItemEditor
            data={item}
            callback={(data) => {
              updateGroup(data, index, setSliderData, sliderData);
            }}
            key={item._key}
          />
        );
      })}
      <Tooltip title="Neuen Eintrag erstellen" className="groupAddButton">
        <div>
          <AddButtonRotating onClick={addLink} />
        </div>
      </Tooltip>
    </>
  );

  if (props.omitGroup) return slidesEditors;

  return (
    <GroupCardManager title={props.headline} onAdd={addLink}>
      {slidesEditors}
    </GroupCardManager>
  );
}
