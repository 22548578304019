import React from 'react';

import {
  ClassesData,
  ClassesDataClass,
  ClassesDataClassData,
  ClassesDataClassesDataSubjects,
} from '../../../Interfaces';
import GroupCardManager from '../../GroupCardManager';

import { TextField } from '@material-ui/core';
import EditorCardManager from '../../EditorCardManager';

export interface ClassesEditorProps {
  data: ClassesData;
  callback: (data: ClassesData) => void;
  headline: string;
}

const subjects = {
  'Erste Fremdsprache': [],
  'Zweite Fremdsprache': [],
  Grundfächer: [],
  Naturwissenschaften: [],
  Wahlfächer: [],
  Praktikum: [],
  'Lernen lernen': [],
};

export default function ClassesEditor(props: ClassesEditorProps) {
  const [classes, setClasses] = React.useState(props.data.classes);

  function addClass() {
    setClasses([
      ...classes,
      { data: { '5': subjects, '6': subjects, '7': subjects, '8': subjects, '9': subjects, '10': subjects }, name: '' },
    ]);
  }

  function removeClass(index: number) {
    setClasses((classes) => classes.slice(0, index).concat(classes.slice(index + 1, classes.length)));
  }

  React.useEffect(() => {
    props.callback({ ...props.data, classes });
  }, [classes]);

  return (
    <GroupCardManager title={props.headline} onAdd={addClass}>
      {classes.map((classData, index) => (
        <ClassesEditorClass
          title={classData.name}
          data={classData.data}
          key={classData.name}
          onRemove={() => removeClass(index)}
          titleCallback={(newTitle) => {
            const newClasses = [...classes];

            newClasses[index] = { ...newClasses[index], name: newTitle };

            setClasses(newClasses);
          }}
          dataCallback={(newData) => {
            const newClasses = [...classes];

            newClasses[index] = { ...newClasses[index], data: newData };

            setClasses(newClasses);
          }}
        />
      ))}
    </GroupCardManager>
  );
}

const ClassesEditorClass = (props: {
  data: ClassesDataClass;
  title: string;
  titleCallback: (newTitle: string) => void;
  dataCallback: (newData: ClassesDataClass) => void;
  onRemove: () => void;
}) => {
  const [title, setTitle] = React.useState(props.title);

  return (
    <GroupCardManager
      title={
        <TextField
          value={title}
          onChange={(event) => setTitle(event.target.value)}
          onBlur={() => props.titleCallback(title)}
        />
      }
      onRemove={props.onRemove}
    >
      {Object.entries(props.data).map(([classNumber, classData]: [string, ClassesDataClassData]) => (
        <GroupCardManager title={classNumber} key={classNumber}>
          {Object.entries(classData).map(([classType, typeData], index) => (
            <ClassesEditorSubjects
              type={classType}
              data={typeData}
              key={index}
              callback={(data) => {
                const newData = { ...props.data };

                newData[classNumber as '5' | '6' | '7' | '8' | '9' | '10'][classType as keyof ClassesDataClassData] =
                  data;

                props.dataCallback(newData);
              }}
            />
          ))}
        </GroupCardManager>
      ))}
    </GroupCardManager>
  );
};

const ClassesEditorSubjects = (props: {
  type: string;
  data: ClassesDataClassesDataSubjects[];
  callback: (data: ClassesDataClassesDataSubjects[]) => void;
}) => {
  const [subjects, setSubjects] = React.useState(props.data);

  function addSubject() {
    setSubjects([...subjects, { subject: '', hours: 0 }]);
  }

  function callback() {
    props.callback(subjects);
  }

  // Test if data is falsely a _key
  if (typeof props.data !== 'object') return null;

  return (
    <GroupCardManager title={props.type} onAdd={addSubject}>
      {subjects.map((subject, index) => (
        <EditorCardManager
          onRemove={() => setSubjects(subjects.slice(0, index).concat(subjects.slice(index + 1)))}
          key={index}
        >
          <TextField
            value={subject.subject}
            onChange={(event) => {
              const newSubjects = [...subjects];

              newSubjects[index] = { ...newSubjects[index], subject: event.target.value };

              setSubjects(newSubjects);
            }}
            onBlur={callback}
          />
          :{' '}
          <TextField
            type="number"
            value={subject.hours}
            onChange={(event) => {
              const newSubjects = [...subjects];

              newSubjects[index] = { ...newSubjects[index], hours: parseFloat(event.target.value) };

              setSubjects(newSubjects);
            }}
            onBlur={callback}
          />
        </EditorCardManager>
      ))}
    </GroupCardManager>
  );
};
