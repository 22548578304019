import React from 'react';

import config from '../../../config';

import { SubjectsData } from '../../../Interfaces';
import SubjectListEditor from './SubjectListEditor';
import TeacherListEditor from './TeacherListEditor';
import GroupCardManager from '../../GroupCardManager';

import { Box, TextField, Tooltip } from '@material-ui/core';
import FileSelector from '@idot-digital/file-selector';

import './subjectsEditor.css';
import { useAuth } from '../../Authentication/AuthContext';
import { FileSelectorButton } from '@idot-digital/custom-material-ui';

export interface SubjectEditorProps {
  headline: string;
  data: SubjectsData;
  callback: (data: SubjectsData | null) => void;
}

export default function SubjectsEditor(props: SubjectEditorProps) {
  const [title, setTitle] = React.useState(props.data.title);
  const [headline, setHeadline] = React.useState(props.data.headline);
  const [kollegium, setKollegium] = React.useState(props.data.kollegium);
  const [faecher, setFaecher] = React.useState(props.data.faecher);
  const [img, setImage] = React.useState(props.data.img);

  const { parseBaseUrl, jwt } = useAuth();
  const [fileSelectorOpen, setFileSelectorOpen] = React.useState(false);

  const settings = {
    credentials: 'omit',
    headers: { Authorization: `Bearer ${jwt}`, referer: config.corsURL },
    mode: 'cors',
  } as Omit<RequestInit, 'method'>;

  React.useEffect(() => {
    props.callback({
      title,
      headline,
      kollegium,
      faecher,
      img,
    });
  }, [title, headline, kollegium, faecher, img]);

  return (
    <GroupCardManager title={props.headline} className="subjectsEditorCardManager">
      <FileSelector
        open={fileSelectorOpen}
        onClose={() => setFileSelectorOpen(false)}
        links={{
          get: {
            url: parseBaseUrl('upload'),
            method: 'GET',
            settings,
          },
          rename: { url: parseBaseUrl('upload'), method: 'PUT', settings },
          delete: {
            url: parseBaseUrl('upload'),
            method: 'DELETE',
            settings,
          },
          upload: { url: parseBaseUrl('upload'), method: 'POST', settings },
          root: parseBaseUrl('cdn'),
        }}
        callbackDone={(links) => {
          if (links.length) setImage({ path: parseBaseUrl('cdn') + links[0].path, alt: links[0].displayName });
          setFileSelectorOpen(false);
        }}
        filter={(files) => [...files].reverse()}
        previewImage={(path) => path.replace('.jpg', '-low.webp')}
        pickedFiles={[img.path]}
      />

      <div className="subjectsEditorCardTitle">
        <TextField
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setTitle(event.target.value)}
          value={title}
          className="subjectsEditorCardTitleInput"
          label="Titel"
          InputProps={{ style: { fontSize: 'x-large' } }}
          error={!title}
        />
        <TextField
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setHeadline(event.target.value)}
          value={headline}
          className="subjectsEditorCardTitleInput"
          label="Schlagzeile"
          InputProps={{ style: { fontSize: 'x-large' } }}
          error={!headline}
        />

        <Box alignSelf="center">
          <Tooltip title="Bild auswählen" className="fileSelector">
            <Box>
              <FileSelectorButton magnifierColor="white" onClick={() => setFileSelectorOpen(true)} />
            </Box>
          </Tooltip>
        </Box>
      </div>
      <SubjectListEditor
        subjects={faecher}
        possibleFachgruppenleiter={kollegium}
        callback={(data) => {
          setFaecher(data);
        }}
      />
      <TeacherListEditor teachers={kollegium} possibleSubjects={faecher} callback={setKollegium} />
    </GroupCardManager>
  );
}
