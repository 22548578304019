import React from 'react';

import { addGroup, updateGroup } from '../../../functions';
import { Navigation } from '../../../Interfaces';
import NavigationLinkEditor from './NavigationLinkEditor';
import EditorCardManager from '../../EditorCardManager';

import { v4 as uuidv4 } from 'uuid';

import { TextField } from '@material-ui/core';

import './navigationGroupEditor.css';

export interface NavigationGroupEditorProps {
  groupState: Navigation;
  callback: (data: Navigation | null) => void;
  removable: boolean;
}

export default function NavigationGroupEditor(props: NavigationGroupEditorProps) {
  const [title, setTitle] = React.useState(props.groupState.title);
  const [links, setLinks] = React.useState(props.groupState.links);

  React.useEffect(() => {
    props.callback({
      title,
      links,
      _key: props.groupState._key,
    });
  }, [links, title]);

  const addLink = addGroup.bind({
    setFunc: setLinks,
    origData: links,
    newElmt: { name: '', href: '', _key: uuidv4() },
  });

  return (
    <EditorCardManager
      onAdd={addLink}
      onRemove={props.removable ? () => props.callback(null) : undefined}
      className="navigationBackground"
    >
      <TextField
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setTitle(event.target.value)}
        className="navigationInput"
        value={title}
        label="Titel"
        InputProps={{ style: { fontSize: 'xx-large' } }}
        color="secondary"
        error={!title}
      />
      {links.map((link, index) => {
        return (
          <NavigationLinkEditor
            removable={links.length > 1}
            data={link}
            callback={(data) => {
              updateGroup(data, index, setLinks, links);
            }}
            key={link._key}
          />
        );
      })}
    </EditorCardManager>
  );
}
