import React from 'react';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Icon,
  IconButton,
  MenuItem,
  Popover,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import {
  FormatAlignCenter,
  FormatAlignJustify,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatUnderlined,
  Info,
  InsertLink,
  LinkOff,
  Save,
  YouTube as YouTubeIcon,
} from '@material-ui/icons';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import './tiptap.css';
import Image from '@tiptap/extension-image';
import ImageSelector from '../Editors/Generic/ImageSelector';
import { Image as ImageIcon } from '@material-ui/icons';
import RemoveIcon from '@material-ui/icons/Remove';
import { Youtube } from './TipTapYouTube';
import { getVideoIDFromYTLink } from './TipTapYouTubeUtils';
import PromiseButton from './PromiseButton';
import { useAuth } from '../Authentication/AuthContext';
import TextAlign from '@tiptap/extension-text-align';

export interface TexteditorProps {
  defaultValue?: string;
  onSave?: (value: string) => Promise<void>;
  onChange?: (value: string) => void;
  hideSave?: boolean;
  infoText?: string;
  disableImages?: boolean;
  disableYoutube?: boolean;
}

export default function TipTapTextEditor(props: TexteditorProps) {
  const theme = useTheme();
  const { jwt, parseBaseUrl } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const [addLinkDialogOpen, setAddLinkDialogOpen] = React.useState(false);
  const [link, setLink] = React.useState('');
  const [addYouTubeDialogOpen, setAddYouTubeDialogOpen] = React.useState(false);
  const [youtubeLink, setYouTubeLink] = React.useState('');
  const [fileSelectorOpen, setFileSelectorOpen] = React.useState(false);
  const initialContent = React.useRef(props.defaultValue);
  const youtubeLinkButton = React.useRef<HTMLButtonElement>(null);
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link.configure({
        protocols: ['mailto', 'http', 'https'],
        openOnClick: false,
      }),
      Image.configure({
        allowBase64: false,
        inline: true,
        HTMLAttributes: {
          class: 'tiptap-image',
        },
      }),
      Youtube.configure({}),
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
    ],
    content: props.defaultValue,
    onUpdate: ({ editor }) => {
      props.onChange?.(editor.getHTML());
      setChanged(editor?.getHTML() !== initialContent.current);
    },
  });

  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    editor?.setOptions({
      content: props.defaultValue,
    });
  }, [props.defaultValue, editor]);

  const getCurrentStyle = () => {
    if (editor?.isActive('paragraph')) return 'Text';
    if (editor?.isActive('heading', { level: 1 })) return 'H1';
    if (editor?.isActive('heading', { level: 2 })) return 'H2';
    if (editor?.isActive('heading', { level: 3 })) return 'H3';
    if (editor?.isActive('heading', { level: 4 })) return 'H4';
    if (editor?.isActive('heading', { level: 5 })) return 'H5';
    if (editor?.isActive('heading', { level: 6 })) return 'H6';
    if (editor?.isActive('blockquote')) return 'Text';
    if (editor?.isActive('codeBlock')) return 'Text';
    return 'Text';
  };

  const addImage = (url: string) => {
    if (!editor) return;
    editor.chain().focus().setImage({ src: url }).run();
  };

  const addYouTube = async (url: string) => {
    const videoID = getVideoIDFromYTLink(url);
    if (!videoID) return;
    const thumbnail_link = `https://img.youtube.com/vi/${videoID}/maxresdefault.jpg`;

    /**
     * Uploads an image to the server and returns the link to the image on our server
     */
    try {
      const res = await fetch(parseBaseUrl('download'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + jwt,
        },
        body: JSON.stringify({
          url: thumbnail_link,
          displayName: 'YouTube Thumbnail',
          filetype: 'image',
        }),
      });

      if (!res.ok || res.status !== 201) throw new Error('Error uploading image');

      const imageID = await res.text();
      const image = parseBaseUrl('cdn') + imageID;

      if (!mounted.current) return;

      editor
        ?.chain()
        .focus()
        .setYoutubeVideo({
          src: youtubeLink,
          thumbnail: image,
        })
        .run();
      setAddYouTubeDialogOpen(false);
    } catch (e) {
      console.error(e);
    }
  };

  const [changed, setChanged] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  return (
    <Box
      style={{
        border: `${theme.spacing(0.125)}px solid ${theme.palette.text.primary}`,
        borderRadius: theme.spacing(2),
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Box>
        <IconButton
          onClick={() => editor?.chain().focus().toggleBold().run()}
          color={editor?.isActive('bold') ? 'primary' : 'default'}
        >
          <FormatBold />
        </IconButton>
        <IconButton
          onClick={() => editor?.chain().focus().toggleItalic().run()}
          color={editor?.isActive('italic') ? 'primary' : 'default'}
        >
          <FormatItalic />
        </IconButton>
        <IconButton
          onClick={() => editor?.chain().focus().toggleUnderline().run()}
          color={editor?.isActive('underline') ? 'primary' : 'default'}
        >
          <FormatUnderlined />
        </IconButton>
        <Select
          style={{
            minWidth: theme.spacing(8),
            textAlign: 'center',
            margin: theme.spacing(0, 1),
          }}
          variant="standard"
          value={getCurrentStyle()}
        >
          {[
            { name: 'Text', type: -1 },
            { name: 'Überschrift 1', type: 1 },
            { name: 'Überschrift 2', type: 2 },
            { name: 'Überschrift 3', type: 3 },
            { name: 'Überschrift 4', type: 4 },
            { name: 'Überschrift 5', type: 5 },
            { name: 'Überschrift 6', type: 6 },
          ].map((style) => (
            <MenuItem
              key={style.type}
              value={style.name}
              onClick={() =>
                style.type === -1
                  ? editor?.chain().focus().setParagraph().run()
                  : editor
                      ?.chain()
                      .focus()
                      .setHeading({
                        level: style.type as 1 | 2 | 3 | 4 | 5 | 6,
                      })
                      .run()
              }
            >
              {style.name}
            </MenuItem>
          ))}
        </Select>
        <IconButton
          onClick={() => editor?.chain().focus().toggleBulletList().run()}
          color={editor?.isActive('bulletList') ? 'primary' : 'default'}
        >
          <FormatListBulleted />
        </IconButton>
        <IconButton
          onClick={() => editor?.chain().focus().toggleOrderedList().run()}
          color={editor?.isActive('orderedList') ? 'primary' : 'default'}
        >
          <FormatListNumbered />
        </IconButton>
        <ButtonGroup
          disableElevation
          variant="outlined"
          color="inherit"
          style={{
            verticalAlign: 'middle',
            color: theme.palette.text.disabled,
          }}
        >
          <Button
            onClick={() => editor?.chain().focus().setTextAlign('left').run()}
            color={editor?.isActive({ textAlign: 'left' }) ? 'primary' : undefined}
          >
            <FormatAlignLeft />
          </Button>
          <Button
            onClick={() => editor?.chain().focus().setTextAlign('center').run()}
            color={editor?.isActive({ textAlign: 'center' }) ? 'primary' : undefined}
          >
            <FormatAlignCenter />
          </Button>
          <Button
            onClick={() => editor?.chain().focus().setTextAlign('right').run()}
            color={editor?.isActive({ textAlign: 'right' }) ? 'primary' : undefined}
          >
            <FormatAlignRight />
          </Button>
          <Button
            onClick={() => editor?.chain().focus().setTextAlign('justify').run()}
            color={editor?.isActive({ textAlign: 'justify' }) ? 'primary' : undefined}
          >
            <FormatAlignJustify />
          </Button>
        </ButtonGroup>
        <IconButton onClick={() => editor?.chain().focus().setHorizontalRule().run()}>
          <RemoveIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            if (editor) setLink(editor.getAttributes('link').href);
            setAddLinkDialogOpen(true);
          }}
        >
          <InsertLink />
        </IconButton>
        <IconButton onClick={() => editor?.chain().focus().unsetLink().run()}>
          <LinkOff />
        </IconButton>
        {!props.disableImages && (
          <IconButton onClick={() => setFileSelectorOpen(true)}>
            <ImageIcon />
          </IconButton>
        )}
        {!props.disableYoutube && (
          <IconButton onClick={() => setAddYouTubeDialogOpen(true)}>
            <YouTubeIcon />
          </IconButton>
        )}
        {!props.disableImages && (
          <ImageSelector
            imagePath=""
            callback={(image) => addImage(image)}
            hideButton
            open={fileSelectorOpen}
            onClose={() => setFileSelectorOpen(false)}
          />
        )}
        <Dialog
          open={addLinkDialogOpen}
          onClose={() => setAddLinkDialogOpen(false)}
          TransitionProps={{
            onExited: () => setLink(''),
          }}
        >
          <DialogTitle>Link einfügen</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              label="Link"
              value={link || ''}
              onChange={(e) => setLink(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  editor
                    ?.chain()
                    .focus()
                    .setLink({
                      href: link,
                    })
                    .run();
                  setAddLinkDialogOpen(false);
                }
              }}
              autoFocus
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setAddLinkDialogOpen(false)}>Zurück</Button>
            <Button
              onClick={() => {
                editor
                  ?.chain()
                  .focus()
                  .setLink({
                    href: link,
                  })
                  .run();
                setAddLinkDialogOpen(false);
              }}
            >
              Einfügen
            </Button>
          </DialogActions>
        </Dialog>
        {!props.disableYoutube && (
          <Dialog
            open={addYouTubeDialogOpen}
            onClose={() => setAddYouTubeDialogOpen(false)}
            TransitionProps={{
              onExited: () => setYouTubeLink(''),
            }}
          >
            <DialogTitle>YouTube Video einfügen</DialogTitle>
            <DialogContent>
              <TextField
                fullWidth
                label="Link"
                value={youtubeLink || ''}
                onChange={(e) => setYouTubeLink(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    youtubeLinkButton.current?.click();
                  }
                }}
                autoFocus
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setAddYouTubeDialogOpen(false)}>Zurück</Button>
              <PromiseButton
                ref={youtubeLinkButton}
                onClick={() => {
                  return addYouTube(youtubeLink);
                }}
              >
                Einfügen
              </PromiseButton>
            </DialogActions>
          </Dialog>
        )}
      </Box>
      <EditorContent
        editor={editor}
        style={{
          paddingLeft: theme.spacing(2),
          minHeight: '16em',
          maxHeight: '70vh',
          flexGrow: 1,
          flexShrink: 1,
          overflow: 'auto',
        }}
      />
      <Box>
        {props.hideSave ? null : (
          <Fab
            size="small"
            style={{
              position: 'absolute',
              top: theme.spacing(1),
              right: theme.spacing(1),
            }}
            color="primary"
            disabled={!changed || loading}
            onClick={async () => {
              setLoading(true);
              try {
                await props.onSave?.(editor?.getHTML() || '');
              } catch (e) {
                console.error(e);
              } finally {
                setLoading(false);
              }
            }}
          >
            {loading ? <CircularProgress size="1.5em" /> : <Save />}
          </Fab>
        )}
        {props.infoText && (
          <>
            <Icon
              onMouseEnter={(e) => setAnchorEl(e.target as HTMLElement)}
              onMouseLeave={() => setAnchorEl(null)}
              style={{
                position: 'absolute',
                bottom: theme.spacing(2),
                right: theme.spacing(2),
              }}
            >
              <Info />
            </Icon>
            <Popover
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              style={{
                pointerEvents: 'none',
              }}
            >
              <Box sx={{ padding: theme.spacing(2) }}>
                <Typography>
                  {props.infoText.split('\n').map((text) => (
                    <React.Fragment key={text}>
                      {text}
                      <br />
                    </React.Fragment>
                  ))}
                </Typography>
              </Box>
            </Popover>
          </>
        )}
      </Box>
    </Box>
  );
}
