import React from 'react';

import { QuickAccessGroup } from '../../../Interfaces';
import QuickAccessGroupEditor from './QuickAccessGroupEditor';
import GroupCardManager from '../../GroupCardManager';

import { v4 as uuidv4 } from 'uuid';

export interface QuickAccessEditorProps {
  data: QuickAccessGroup[];
  callback: (data: QuickAccessGroup[] | null) => void;
  headline: string;
}

export default function QuickAccessEditor(props: QuickAccessEditorProps) {
  const [quickAccessData, setquickAccessData] = React.useState(props.data);

  function addQuickAccessGroup() {
    setquickAccessData([
      ...quickAccessData,
      {
        title: '',
        color: '#09A9EE',
        textcolor: '',
        links: [
          {
            name: '',
            href: '',
            image: '',
            _key: uuidv4(),
          },
        ],
        _key: uuidv4(),
      },
    ]);
  }

  function updateGroup(data: QuickAccessGroup | null, linkIndex: number) {
    if (data === null) {
      const updatedGroups = quickAccessData.filter((_, index) => index !== linkIndex);
      setquickAccessData(updatedGroups);
    } else {
      const updatedGroups = quickAccessData.map((link, index) => {
        if (!(index === linkIndex)) return link;
        return data;
      });
      setquickAccessData(updatedGroups);
    }
  }

  React.useEffect(() => {
    props.callback(quickAccessData);
  }, [quickAccessData]);

  return (
    <GroupCardManager title={props.headline} onAdd={addQuickAccessGroup}>
      {quickAccessData.map((quickAccessGroup, index) => {
        return (
          <QuickAccessGroupEditor
            removable={quickAccessData.length > 1}
            groupState={quickAccessGroup}
            callback={(data) => {
              updateGroup(data, index);
            }}
            key={quickAccessGroup._key}
          />
        );
      })}
    </GroupCardManager>
  );
}
