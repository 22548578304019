import { Box, Grid } from '@material-ui/core';
import React from 'react';
import GroupCardManager from '../../GroupCardManager';
import { DeepPartial, Image, SliderItem } from '../../../Interfaces';
import SubpageEditor from './SubpageEditor';
import LinkEditor from '../../LinkEditor';
import { deepMerge } from '../../../functions';
import { AnyInput } from '../Generic/GenericEditor';

export type PageData = {
  _img?: Image;
  _bgImg?: Image;
  left?: SubPageData;
  right?: SubPageData;
} & (SubPageData | {}) & { [key: string]: any | undefined };

export interface SubPageData {
  headline?: string;
  subheader?: string;
  content: string;
  _textState_content?: any;
  slider?: SliderItem[];
}

export interface PageEditorProps {
  headline: string;
  data: PageData;
  callback: (data: PageData | null) => void;
}

export default function PageEditor(props: PageEditorProps) {
  const [data, _setData] = React.useState<PageData>(props.data);

  const setData = (data: DeepPartial<PageData>) => {
    const newData = deepMerge(props.data, data);
    _setData(newData);
    props.callback(newData);
  };

  const subPage = React.useMemo(() => {
    const subpage: SubPageData | {} = {};
    for (const key of ['headline', 'subheader', 'content', 'slider'] as (keyof SubPageData)[]) {
      if (key in data)
        //@ts-ignore
        subpage[key] = data[key];
    }
    return subpage as SubPageData | {};
  }, [data]);

  const otherProps = React.useMemo(() => {
    const otherProps: { [key: string]: any | undefined } = { ...data };
    for (const key of ['_img', '_bgImg', 'left', 'right', 'headline', 'subheader', 'content', 'slider']) {
      delete otherProps[key];
    }
    return otherProps;
  }, [data]);

  return (
    <GroupCardManager title={props.headline} className="madMaxEditorCardManager">
      {Object.keys(subPage).length && <SubpageEditor data={subPage as SubPageData} callback={setData} />}
      {data._img && (
        <>
          <h3>Beitragsbild</h3>
          <LinkEditor
            image={data._img.path}
            description={data._img.alt}
            setImage={(img) => {
              if (typeof img === 'function') {
                // @ts-ignore
                img((path) => {
                  setData({ _img: { path } });
                  return path;
                });
              } else
                setData({
                  _img: {
                    path: img,
                  },
                });
            }}
            setDescription={(alt) => {
              if (typeof alt === 'function') {
                // @ts-ignore
                alt((path) => {
                  setData({ _img: { alt: path } });
                  return path;
                });
              } else
                setData({
                  _img: {
                    alt,
                  },
                });
            }}
          />
        </>
      )}
      {data._bgImg && (
        <>
          <h3>Hintergrundbild</h3>
          <LinkEditor
            image={data._bgImg.path}
            description={data._bgImg.alt}
            setImage={(img) => {
              if (typeof img === 'function') {
                // @ts-ignore
                img((path) => {
                  setData({ _bgImg: { path } });
                  return path;
                });
              } else
                setData({
                  _bgImg: {
                    path: img,
                  },
                });
            }}
            setDescription={(alt) => {
              if (typeof alt === 'function') {
                // @ts-ignore
                alt((path) => {
                  setData({ _bgImg: { alt: path } });
                  return path;
                });
              } else
                setData({
                  _bgImg: {
                    alt,
                  },
                });
            }}
          />
        </>
      )}
      {Object.keys(otherProps).length && <AnyInput title="" data={otherProps} onChange={setData} />}
      {(data.left || data.right) && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '1rem',
            border: '1px solid var(--primary-dark)',
            borderRadius: '0.5rem',
          }}
        >
          {data.left && (
            <div
              style={{
                flex: '1 1 0',
                padding: '1rem',
              }}
            >
              <h2>Linke Seite</h2>
              <SubpageEditor data={data.left} callback={(data) => setData({ left: data })} />
            </div>
          )}
          {data.right && (
            <div
              style={{
                ...(data.right && {
                  background: 'var(--primary-dark)',
                  color: 'var(--bg)',
                  borderRadius: '0.5rem',
                }),
                flex: '1 1 0',
                padding: '1rem',
              }}
            >
              <h2>Rechte Seite</h2>
              <SubpageEditor data={data.right} callback={(data) => setData({ right: data })} />
            </div>
          )}
        </div>
      )}
    </GroupCardManager>
  );
}
