import React from 'react';

import { Checkbox, FormControlLabel } from '@material-ui/core';

import './booleanEditor.css';

export interface BooleanEditorProps {
  value: any;
  text: string;
  setFunc: (value: any) => void;
}

export default function BooleanEditor(props: BooleanEditorProps) {
  return (
    <FormControlLabel
      className="booleanEditorWrapper"
      control={
        <Checkbox
          checked={props.value}
          color="primary"
          onChange={(e) => {
            props.setFunc(e.target.checked);
          }}
          className="booleanInput"
        />
      }
      style={{ marginLeft: 0 }}
      label={props.text}
    />
  );
}
