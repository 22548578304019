import React from 'react';

import { MadMax } from '../../../Interfaces';
import EditorCardManager from '../../EditorCardManager';
import LinkEditor from '../../LinkEditor';

export interface MadMaxLinkEditorProps {
  data: MadMax;
  callback: (data: MadMax | null) => void;
}

export default function MadMaxLinkEditor(props: MadMaxLinkEditorProps) {
  const [alt, setAlt] = React.useState(props.data.alt);
  const [path, setPath] = React.useState(props.data.path);

  React.useEffect(() => {
    props.callback({
      alt,
      path,
      _key: props.data._key,
    });
  }, [alt, path]);

  return (
    <LinkEditor
      onRemove={() => props.callback(null)}
      file={props.data.path}
      description={props.data.alt}
      setFile={setPath}
      setDescription={setAlt}
      className="madMaxEditorPreview"
    />
  );
}
