import { Paper, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useAuth } from './AuthContext';
import * as EmailValidator from 'email-validator';
import { Button } from '@material-ui/core';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import passwordGenerator from 'generate-password';

import { v4 as uuidv4 } from 'uuid';

export interface ForgotPasswordStateProps {
  email: string;
}

export default function ForgotPassword(props: { location: { state?: ForgotPasswordStateProps } }) {
  const [step, setStep] = React.useState(0);

  const [email, setEmail] = React.useState(props.location.state?.email || '');

  const [code, setCode] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = React.useState('');

  const emailSendKey = React.useRef('');

  const generatedPassword = React.useRef(
    passwordGenerator.generate({
      length: 16,
      numbers: true,
      symbols: true,
    }),
  );

  const { userManagement } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();

  async function handleSubmitCode(e: any) {
    e.preventDefault();

    try {
      setError('');
      setLoading(true);
      await userManagement.requestPasswordReset(email);

      emailSendKey.current = uuidv4();
      enqueueSnackbar('Der Sicherheitsschlüssel wurde an ihre E-Mail gesendet', {
        autoHideDuration: 60 * 1000,
        key: emailSendKey.current,
      });

      setStep(1);
    } catch {
      enqueueSnackbar(
        'Die E-Mail konnte nicht korrekt versendet werden. Bitte versuchen Sie es in kurzer Zeit erneut.',
        {
          variant: 'error',
        },
      );
    }

    setLoading(false);
  }

  async function handleSubmitPassword(e: any) {
    e.preventDefault();

    try {
      setError('');
      setLoading(true);
      await userManagement.resetPassword(newPassword || generatedPassword.current, code);

      let key = uuidv4();
      enqueueSnackbar('Passwort erfolgreich geändert', {
        variant: 'success',
        autoHideDuration: 60 * 1000,
        key,
      });
      closeSnackbar(emailSendKey.current);

      setLoading(false);

      history.push('/login');
      let clearListener = history.listen(() => {
        closeSnackbar(key);
        clearListener();
      });
    } catch {
      enqueueSnackbar('Es ist ein Fehler aufgetreten. Bitte versuchen Sie es in kurzer Zeit erneut.', {
        variant: 'error',
      });

      setLoading(false);
    }
  }

  let emailError = !EmailValidator.validate(email) && error !== '';

  let passwordsMatch = newPassword === newPasswordConfirm;

  return (
    <div className="resetPassword">
      <Paper elevation={8} className="container">
        <h1>Passwort zurücksetzen</h1>
        <div className="formContainer">
          <form>
            {
              // Form triggers password manager prompts
            }
            {step === 0 && (
              <div className="form">
                <TextField
                  fullWidth
                  type="email"
                  label="E-Mail"
                  value={email}
                  error={emailError}
                  onChange={(event) => setEmail(event.currentTarget.value)}
                  helperText={emailError ? 'Bitte geben Sie eine valide E-Mail-Adresse an' : ''}
                  autoComplete="username"
                />
                <Button type="submit" color="primary" variant="outlined" disabled={!email} onClick={handleSubmitCode}>
                  Sicherheitsschlüssel anfragen
                </Button>
              </div>
            )}
            {step === 1 && (
              <div className="form">
                <TextField
                  fullWidth
                  label="Sicherheitsschlüssel"
                  value={code}
                  onChange={(event) => setCode(event.currentTarget.value)}
                  autoComplete="one-time-code"
                />
                <TextField
                  fullWidth
                  type="password"
                  label="Neues Passwort"
                  value={newPassword}
                  onChange={(event) => setNewPassword(event.currentTarget.value)}
                  autoComplete="new-password"
                />
                <TextField
                  fullWidth
                  type="password"
                  label="Passwort wiederholen"
                  value={newPasswordConfirm}
                  error={newPasswordConfirm ? !passwordsMatch : undefined}
                  onChange={(event) => setNewPasswordConfirm(event.currentTarget.value)}
                  autoComplete="new-password"
                />
                oder
                <TextField
                  fullWidth
                  type="input"
                  helperText="Sicheres und zufälliges Passwort"
                  onClick={(event) => {
                    (event.target as HTMLInputElement).focus();
                    (event.target as HTMLInputElement).select();
                    document.execCommand('copy');

                    enqueueSnackbar('Passwort kopiert', {
                      variant: 'success',
                    });
                  }}
                  value={generatedPassword.current}
                  autoComplete="off"
                />
                <Button
                  type="submit"
                  color="primary"
                  variant="outlined"
                  disabled={!(((passwordsMatch && newPassword) || (!newPassword && !newPasswordConfirm)) && code)}
                  onClick={handleSubmitPassword}
                >
                  Passwort aktualisieren
                </Button>
              </div>
            )}
          </form>
        </div>
        <span>
          <Link
            to={{ pathname: '/login', state: { email } }}
            onClick={() => {
              if (emailSendKey.current) closeSnackbar(emailSendKey.current);
            }}
          >
            Zurück zum Login
          </Link>
        </span>
        <Backdrop open={loading} style={{ zIndex: 100 }}>
          <CircularProgress color="primary" size="10rem" />
        </Backdrop>
      </Paper>
    </div>
  );
}
