import { useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import config from './config';

import { url } from './Interfaces';

export function updateGroup(data: any, linkIndex: number, setFunc: (a: any) => void, origData: any) {
  if (data === null) {
    const updatedGroups = origData.filter((_: any, index: number) => index !== linkIndex);
    setFunc(updatedGroups);
  } else {
    const updatedGroups = origData.map((link: any, index: number) => {
      if (!(index === linkIndex)) return link;
      return data;
    });
    setFunc(updatedGroups);
  }
}

export function addGroup(this: any) {
  this.setFunc([...this.origData, this.newElmt]);
}

export async function simpleFetch(url: url, method: string, body: any = undefined, referrer = ''): Promise<any> {
  const response = await fetch(url, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
    },
    body: body ? JSON.stringify(body) : undefined,
    referrer: referrer,
    //mode: 'cors', // same-origin, no-cors
    credentials: 'include',
  });
  const text = await response.text();
  try {
    const json = JSON.parse(text);
    return json;
  } catch (err) {
    if (text === '') return null;
    return text;
  }
}

export const uniquify = (data: any): any => {
  switch (data?.constructor || ''.constructor) {
    case Object:
      let newData = Object.fromEntries(Object.entries(data).map(([key, entry]: [any, any]) => [key, uniquify(entry)]));
      newData._key = newData._key || uuidv4();
      return newData;
    case Array:
      return data.map((entry: any) => uniquify(entry));
    default:
      return data;
  }
};

export const deuniquify = (data: any): any => {
  switch (data?.constructor || ''.constructor) {
    case Object:
      let newData = Object.fromEntries(
        Object.entries(data).map(([key, entry]: [any, any]) => [key, deuniquify(entry)]),
      );
      delete newData._key;
      return newData;
    case Array:
      return data.map((entry: any) => deuniquify(entry));
    default:
      return data;
  }
};

export function usePrevious(value: any): any {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export async function executeGraphQL(doc: string, jwt: string, variables = {}): Promise<any> {
  const request = await fetch(config.graphQlLink, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
    body: JSON.stringify({
      query: doc,
      variables,
      operationName: 'operation',
    }),
  });
  const { errors, data } = await request.json();
  if (errors) {
    // handle those errors like a pro
    console.error(errors);
  }
  return data;
}

export function deepMerge(obj1: any, obj2: any): any {
  const res = { ...obj1 };
  for (const [key, value] of Object.entries(obj2)) {
    if (typeof value === 'object' && !Array.isArray(value)) {
      res[key] = deepMerge(res[key] || {}, value);
    } else {
      res[key] = value;
    }
  }
  return res;
}

//@ts-ignore
window.simpleFetch = simpleFetch;
