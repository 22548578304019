import React from 'react';

import Dropdown from '@idot-digital/dropdown-selector';

import { Subject, Teacher } from '../../../Interfaces';
import EditorCardManager from '../../EditorCardManager';

import { ListItemText, MenuItem, TextField } from '@material-ui/core';

import './subjectEditor.css';

export interface SubjectEditorProps {
  subjectData: Subject;
  possibleFachgruppenleiter: Teacher[];
  kuerzel: string[];
  callback: (data: Subject | null) => void;
  removable: boolean;
}

export default function SubjectEditor(props: SubjectEditorProps) {
  const getKeyByElement = (element: string) => {
    return props.possibleFachgruppenleiter.find((possible) => possible.token === element)?._key;
  };

  const getElementByKey = (key: string) => {
    return props.possibleFachgruppenleiter.find((possible) => possible._key === key)?.token;
  };

  const [kuerzel, setKuerzel] = React.useState(props.subjectData.kuerzel);
  const [name, setName] = React.useState(props.subjectData.name);
  const [nameShort, setNameShort] = React.useState(props.subjectData.nameShort);
  const [text, setText] = React.useState(props.subjectData.text);
  const [kontakt, setKontakt] = React.useState(props.subjectData.kontakt);
  const [fachgruppenleiter, setFachgruppenleiter] = React.useState(
    (props.subjectData.fachgruppenleiter ? getKeyByElement(props.subjectData.fachgruppenleiter) : '') as string,
  );

  React.useEffect(() => {
    props.callback({
      kuerzel,
      name,
      nameShort,
      text,
      kontakt,
      fachgruppenleiter: getElementByKey(fachgruppenleiter),
      _key: props.subjectData._key,
    });
  }, [kuerzel, name, nameShort, text, kontakt, fachgruppenleiter]);

  return (
    <EditorCardManager
      onRemove={props.removable ? () => props.callback(null) : undefined}
      className="subjectEditorWrapper"
    >
      <div className="input">
        <div>
          <TextField
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setKuerzel(event.target.value)}
            className="inputField"
            value={kuerzel}
            label="Kürzel"
            error={props.kuerzel.filter((currentKuerzel) => kuerzel === currentKuerzel).length > 1 || !kuerzel}
          />
          <TextField
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value)}
            className="inputField"
            value={name}
            label="Name"
            error={!name}
          />
          <TextField
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNameShort(event.target.value)}
            className="inputField"
            value={nameShort}
            label="Kurzer Name"
            error={!nameShort}
          />
        </div>
        <div>
          <TextField
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setText(event.target.value)}
            className="inputField"
            value={text}
            label="Text zum Fachgruppenleiter"
            error={!text}
          />
          <Dropdown
            className="inputField"
            defaultValue={kontakt}
            onChangeDone={setKontakt}
            errorOnEmpty
            errorAtStartup
            allowCustomInputs
            placeholder="Link"
            style={{ display: '', alignSelf: 'flex-end' }}
            label="Kontakt"
          >
            <MenuItem value='<a href="mailto:ihre@mail.addresse">Schreiben Sie uns</a>'>
              <ListItemText primary="Schreiben Sie uns"></ListItemText>
            </MenuItem>
          </Dropdown>
          <TextField
            value={getElementByKey(fachgruppenleiter)}
            onChange={(event) => setFachgruppenleiter(() => getKeyByElement(event.target.value as string) || '')}
            label="Fachgruppenleiter"
            className="inputField"
            size="small"
            select
          >
            <MenuItem value="">
              <ListItemText secondary={'Keiner'}></ListItemText>
            </MenuItem>
            {props.possibleFachgruppenleiter
              .filter((teacher) => teacher.subjects.includes(kuerzel))
              .map((possible) => possible.token)
              .filter(Boolean)
              .map((selection) => (
                <MenuItem value={selection} key={selection}>
                  <ListItemText primary={selection}></ListItemText>
                </MenuItem>
              ))}
          </TextField>
        </div>
      </div>
    </EditorCardManager>
  );
}
