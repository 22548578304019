import React, { useContext, useState, useEffect } from 'react';
import config from '../../config';
import jsonwebtoken from 'jsonwebtoken';
import { executeGraphQL } from '../../functions';
import { ContentManagementData, Post, uuid, DataVersion, User, OrganisationData } from '../../Interfaces';
import { v4 as uuidv4 } from 'uuid';
import passwordGenerator from 'generate-password';

const AuthContext = React.createContext<AuthHook>(undefined!);

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider(props: { children: JSX.Element }) {
  const [currentUser, setCurrentUser] = useState(null as any as User | null);
  const [jwt, setjwt] = useState('');
  const [engineName, setengineName] = useState();
  const [elasticApiKey, setelasticApiKey] = useState(null as any as string);
  const [elasticBaseUrl, setelasticBaseUrl] = useState(null as any as string);
  const [modulesJson, setmodulesJson] = useState();
  const [nsBaseUrl, setnsBaseUrl] = useState('');

  //@ts-ignore
  window.other = { currentUser, jwt, engineName, elasticApiKey, elasticBaseUrl, modulesJson, nsBaseUrl };

  if (jwt === '') {
    if (sessionStorage.getItem('loggedIn') === 'true' || localStorage.getItem('loggedIn') === 'true') {
      //@ts-ignore
      setjwt(config.developmentMode ? localStorage.getItem('jwt') : sessionStorage.getItem('jwt'));
      //@ts-ignore
      setengineName(config.developmentMode ? localStorage.getItem('engineName') : sessionStorage.getItem('engineName'));
      setmodulesJson(
        //@ts-ignore
        config.developmentMode ? localStorage.getItem('modulesJson') : sessionStorage.getItem('modulesJson'),
      );
      setelasticApiKey(
        //@ts-ignore
        config.developmentMode ? localStorage.getItem('elasticApiKey') : sessionStorage.getItem('elasticApiKey'),
      );
      setelasticBaseUrl(
        //@ts-ignore
        config.developmentMode ? localStorage.getItem('elasticBaseUrl') : sessionStorage.getItem('elasticBaseUrl'),
      );
      setCurrentUser(
        //@ts-ignore
        JSON.parse(config.developmentMode ? localStorage.getItem('userdata') : sessionStorage.getItem('userdata')),
      );
      setnsBaseUrl(
        //@ts-ignore
        config.developmentMode ? localStorage.getItem('nsBaseUrl') : sessionStorage.getItem('nsBaseUrl'),
      );
    }
  }

  async function login(email: string, password: string) {
    const jwt = await (
      await fetch(config.authserverLink + 'login', {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ email: email.toLowerCase(), password }),
        mode: 'cors',
        credentials: 'omit',
      })
    ).text();
    setjwt(jwt);
    await jsonwebtoken.verify(jwt, config.publicKey, async (err: any, decoded: any) => {
      if (err) throw err;

      decoded.userGroup = rightStringTouserGroup(decoded.rightString);
      let QUERY = '';

      if (decoded.userGroup.includes('writer')) {
        QUERY = `
        query operation {
          namespaces(where: {id: {_eq: ${decoded.namespace}}}) {
            elasticApiKey
            elasticBaseUrl
            engineName
            modulesJson
            nsBaseUrl
          }
        }
        `;
      } else if (decoded.userGroup.includes('contentManager')) {
        QUERY = `
        query operation {
          namespaces(where: {id: {_eq: ${decoded.namespace}}}) {
            modulesJson
            nsBaseUrl
          }
        }
        `;
      }
      if (decoded.userGroup.includes('writer') || decoded.userGroup.includes('contentManager')) {
        const { elasticApiKey, elasticBaseUrl, engineName, modulesJson, nsBaseUrl } = (await executeGraphQL(QUERY, jwt))
          .namespaces[0];

        setnsBaseUrl(nsBaseUrl);
        setengineName(engineName);
        const modules = config.useTestData
          ? config.testModules
            ? JSON.stringify(config.testModules)
            : modulesJson
          : modulesJson;
        setmodulesJson(modules);
        setelasticApiKey(elasticApiKey);
        setelasticBaseUrl(elasticBaseUrl);

        const storage = config.developmentMode ? localStorage : sessionStorage;
        storage.setItem('loggedIn', 'true');
        storage.setItem('jwt', jwt);
        storage.setItem('engineName', engineName);
        storage.setItem('userdata', JSON.stringify(decoded));
        storage.setItem('modulesJson', modules);
        storage.setItem('elasticApiKey', elasticApiKey);
        storage.setItem('elasticBaseUrl', elasticBaseUrl);
        storage.setItem('nsBaseUrl', nsBaseUrl);
      }

      setCurrentUser(decoded);
    });
  }

  function logout() {
    const storage = config.developmentMode ? localStorage : sessionStorage;
    storage.setItem('loggedIn', 'false');
    storage.removeItem('jwt');
    storage.removeItem('engineName');
    storage.removeItem('userdata');
    storage.removeItem('modulesJson');
    storage.removeItem('elasticApiKey');
    storage.removeItem('elasticBaseUrl');
    setjwt('');
    setCurrentUser(null);
  }

  async function updateEmail(email: string) {
    await (
      await fetch(config.authserverLink + 'updateEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify({ email: email.toLowerCase() }),
      })
    ).text();
  }

  const postActions = {
    list: async (pageNum = 1, pageSize = 100): Promise<{ currentPage: number; totalPages: number; posts: Post[] }> => {
      const QUERY = `
      query operation($offset: Int, $limit: Int, $tennantID: String) {
        posts(limit: $limit, offset: $offset, order_by: {categories: desc, date: desc}, where: {tennantID: {_eq: $tennantID}}) {
          title
          author
          categories
          date
          id
          images
          published
          text
          text_state
        }
        posts_aggregate {
          aggregate {
            count
          }
        }
      } 
    `;

      console.log({ pageNum, pageSize });
      if (pageNum < 1) pageNum = 1;
      const data = await executeGraphQL(QUERY, jwt, {
        offset: 0,
        limit: pageSize,
        tennantID: String(engineName),
      });
      return {
        totalPages: Math.ceil(data.posts_aggregate.aggregate.count / pageSize),
        posts: data.posts,
        currentPage: pageNum,
      };
    },
    search: async (searchPhrase: string): Promise<Post[]> => {
      const QUERY = `
      query operation($query: String, $tennantID: String) {
        posts(where: {_or: {text: {_like: $query}, tennantID: {_eq: $tennantID}}, title: {_like: $query}}) {
          title
          author
          categories
          date
          id
          images
          published
          text
          text_state
        }
      }
    `;
      const { versions } = await executeGraphQL(QUERY, jwt, {
        query: `%${searchPhrase}%`,
        tennantID: String(engineName),
      });
      return versions;
    },
    add: async (post: Post): Promise<{ id: uuid }> => {
      const QUERY = `
      mutation operation($author: String = "", $categories: String = "", $date: timestamptz = "", $images: String = "", $published: Boolean = false, $tennantID: String = "", $text: String = "", $text_state: String = "", $title: String = "") {
        posts: insert_posts_one(object: {author: $author, categories: $categories, date: $date, images: $images, published: $published, text: $text, text_state: $text_state, title: $title, tennantID: $tennantID}) {
          id
          author
          categories
          date
          images
          published
          text
          text_state
          title
        }
      }
    `;
      const response = await executeGraphQL(QUERY, jwt, {
        author: post.author,
        categories: post.categories,
        date: new Date(post.date).toISOString(),
        images: post.images,
        published: post.published,
        tennantID: String(engineName),
        text: post.text,
        title: post.title,
      });
      return response;
    },
    update: async (post: Post, rebuild = true): Promise<void> => {
      const QUERY = `
      mutation operation($id: uuid = "", $title: String = "", $text_state: String = "", $text: String = "", $published: Boolean = false, $images: String = "", $date: timestamptz = "", $categories: String = "", $author: String = "") {
        update_posts(where: {id: {_eq: $id}}, _set: {author: $author, categories: $categories, date: $date, images: $images, published: $published, text: $text, text_state: $text_state, title: $title}) {
          posts: returning {
            title
            text_state
            text
            published
            images
            id
            date
            categories
            author
          }
        }
      }
    `;
      await executeGraphQL(QUERY, jwt, {
        id: post.id,
        title: post.title,
        text: post.text,
        published: post.published,
        images: post.images,
        date: new Date(post.date).toISOString(),
        categories: post.categories,
        author: post.author,
      });

      // await postActions._eesrFetch('/posts', { post }, 'PATCH');
      if (rebuild) staticcBuildActions('blog');
      return;
    },
    delete: async (id: uuid, rebuild = true): Promise<void> => {
      const QUERY = `
      mutation operation($postIDs: [uuid!] = "") {
        delete_posts(where: {id: {_in: $postIDs}}) {
          affected_rows
        }
      }
    `;
      await executeGraphQL(QUERY, jwt, { postIDs: id });
      // await postActions._eesrFetch('/posts', { postIDs: [id] }, 'DELETE');
      if (rebuild) staticcBuildActions('blog');
      return;
    },
    get: async (id: uuid): Promise<void> => {
      const QUERY = `
      query operation($ids: [uuid!] = "") {
        posts(where: {id: {ids: $ids}}) {
          title
          author
          categories
          date
          id
          images
          published
          text
          text_state
        }
      }
    `;

      const response = await executeGraphQL(QUERY, jwt, {
        ids: id,
      });
      if (!response.posts[0]) throw new Error('Post not found');
      return response.posts[0];
      // return (await postActions._eesrFetch('/post', { postIDs: [id] }, 'POST'))[0];
    },
    preview: async (): Promise<void> => {
      await staticcBuildActions('article');
      return;
    },
    // _eesrFetch: async (url: string, body: any, method = 'POST') => {
    //   const result = await fetch(elasticBaseUrl + url, {
    //     method: method,
    //     headers: {
    //       Authorization: `Bearer ${jwt}`,
    //       'Content-Type': 'application/json',
    //       referer: config.corsURL,
    //     },
    //     body: JSON.stringify({ engineName, apiKey: elasticApiKey, ...body }),
    //     mode: 'cors',
    //     credentials: 'omit',
    //   });

    //   return result.json();
    // },
  };

  const dataActions = {
    list: async (): Promise<DataVersion[]> => {
      if (!currentUser) throw new Error('User not logged in');
      const QUERY = `
      query operation {
        versions(where: {namespace: {_eq: ${currentUser.namespace}}}) {
          current
          date
          id
          preview
        }
      }
    `;
      const { versions } = await executeGraphQL(QUERY, jwt);
      return versions;
    },
    get: async (id: uuid): Promise<ContentManagementData> => {
      if (!currentUser) throw new Error('User not logged in');
      const QUERY = `
      query operation {
        versions(where: {namespace: {_eq: ${currentUser.namespace}}, id: {_eq: "${id}"}}) {
          data
        }
      }
    `;
      const { versions } = await executeGraphQL(QUERY, jwt);
      return JSON.parse(versions[0].data);
    },
    add: async (data: ContentManagementData): Promise<DataVersion> => {
      if (!currentUser) throw new Error('User not logged in');
      const QUERY = `
mutation operation ($data: String) {
  insert_versions(objects: {data: $data, date: "${new Date().toISOString()}", namespace: ${
        currentUser.namespace
      }, current: false, preview: false}) {
    returning {
      current
      date
      id
      preview
    }
  }
}
`;
      const {
        insert_versions: { returning },
      } = await executeGraphQL(QUERY, jwt, { data: JSON.stringify(data) });
      return returning[0] as DataVersion;
    },
    delete: async (id: uuid): Promise<void> => {
      if (!currentUser) throw new Error('User not logged in');
      const QUERY = `
      mutation operation {
        delete_versions(where: {id: {_eq: "${id}"}, namespace: {_eq: ${currentUser.namespace}}}) {
          affected_rows
        }
      }
    `;
      await executeGraphQL(QUERY, jwt);
      return;
    },
    setPreview: async (id: uuid): Promise<void> => {
      if (!currentUser) throw new Error('User not logged in');
      const QUERY1 = `
      mutation operation {
        update_versions(where: {namespace: {_eq: ${currentUser.namespace}}}, _set: {preview: false}) {
          affected_rows
        }
      }
      `;
      await executeGraphQL(QUERY1, jwt);
      const QUERY2 = `
      mutation operation {
        update_versions(where: {id: {_eq: "${id}"}, namespace: {_eq: ${currentUser.namespace}}}, _set: {preview: true}) {
          affected_rows
        }
      }
      `;
      await executeGraphQL(QUERY2, jwt);
      return;
    },
    buildPreview: async (): Promise<void> => {
      await staticcBuildActions('preview');
      return;
    },

    createPreview: async (id: uuid) => {
      await dataActions.setPreview(id);
      await dataActions.buildPreview();
      return;
    },

    upgradeRelease: async (id: uuid) => {
      if (!currentUser) throw new Error('User not logged in');
      const QUERY1 = `
      mutation operation {
        update_versions(where: {namespace: {_eq: ${currentUser.namespace}}}, _set: {current: false}) {
          affected_rows
        }
      }
    `;
      await executeGraphQL(QUERY1, jwt);
      const QUERY2 = `
      mutation operation {
        update_versions(where: {id: {_eq: "${id}"}, namespace: {_eq: ${currentUser.namespace}}}, _set: {current: true}) {
          affected_rows
        }
      }
    `;
      await executeGraphQL(QUERY2, jwt);
      await staticcBuildActions('publish');
      return;
    },
  };

  const userManagement = {
    listUsers: async (): Promise<User[]> => {
      if (!currentUser) throw new Error('User not logged in');
      const QUERY = `
      query operation {
        users(where: {namespace: {_eq: ${currentUser.namespace}}}) {
          email
          rightString
          namespace
        }
      }
    `;
      const { users } = await executeGraphQL(QUERY, jwt);
      return users.map((user: any) => {
        return { ...user, userGroup: rightStringTouserGroup(user.rightString) };
      });
    },
    deleteUser: async (email: string): Promise<void> => {
      if (!currentUser) throw new Error('User not logged in');
      const QUERY = `
      mutation operation {
        delete_users(where: {email: {_eq: "${email.toLowerCase()}"}}) {
          affected_rows
        }
      }
    `;
      await executeGraphQL(QUERY, jwt);
      return;
    },
    updateUser: async (email: string, userGroup: ('admin' | 'contentManager' | 'writer')[]) => {
      if (!currentUser) throw new Error('User not logged in');
      const rightString = userGroupToRightString(userGroup);
      const QUERY = `
      mutation operation {
        update_users(where: {email: {_eq: "${email.toLowerCase()}"}}, _set: {rightString: "${rightString}"}) {
          affected_rows
        }
      }
    `;
      await executeGraphQL(QUERY, jwt);
      return;
    },
    createUser: async (user: {
      email: string;
      password: string;
      userGroup: ('admin' | 'contentManager' | 'writer')[];
    }): Promise<boolean> => {
      if (!currentUser) throw new Error('User not logged in');
      const passwordHash = await (
        await fetch(config.authserverLink + 'hash', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${jwt}`,
            'Content-Type': 'application/json',
            referer: config.corsURL,
          },
          body: JSON.stringify({ password: user.password }),
          mode: 'cors',
          credentials: 'omit',
        })
      ).text();
      const rightString = userGroupToRightString(user.userGroup);
      const QUERY = `
      mutation operation {
        insert_users_one(object: {email: "${user.email.toLowerCase()}", namespace: ${
        currentUser.namespace
      }, password: "${passwordHash}", rightString: "${rightString}"}) {
          email
        }
      }
    `;
      await executeGraphQL(QUERY, jwt);
      return true;
    },
    createPassword: () => {
      return passwordGenerator.generate({
        length: 12,
        numbers: true,
        symbols: true,
        strict: true,
      });
    },
    requestPasswordReset: async (email?: string): Promise<void> => {
      await fetch(config.authserverLink + 'requestPasswordReset', {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ email: email?.toLowerCase() || currentUser?.email.toLowerCase() }),
        mode: 'cors',
        credentials: 'omit',
      });
    },
    resetPassword: async (newPassword: string, key: string): Promise<void> => {
      await fetch(config.authserverLink + 'resetPassword', {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ code: key, password: newPassword }),
        mode: 'cors',
        credentials: 'omit',
      });
    },
  };

  const settingActions = {
    ppmAddress: {
      get: async (): Promise<string> => {
        if (!currentUser) throw new Error('User not logged in');
        const QUERY = `
        query operation {
          settings(where: {settingName: {_eq: "ppmAddress"}, namespace: {_eq: ${currentUser.namespace}}}) {
            value
          }
        }
      `;
        const { settings } = await executeGraphQL(QUERY, jwt);
        if (settings.length < 1) throw new Error('PPM-Address not defined! Contact server admin!');
        return settings[0].value;
      },
      update: async (newAddress: string): Promise<void> => {
        if (!currentUser) throw new Error('User not logged in');
        const QUERY = `
        mutation operation {
          update_settings(where: {namespace: {_eq: ${currentUser.namespace}}, settingName: {_eq: "ppmAddress"}}, _set: {value: "${newAddress}"}) {
            affected_rows
          }
        }
      `;
        await executeGraphQL(QUERY, jwt);
        return;
      },
    },
  };

  const parseBaseUrl = (type: 'articles' | 'build' | 'cdn' | 'preview' | 'upload' | 'publish' | 'download') => {
    const before = 'https://';
    const after = '/';

    switch (type) {
      case 'articles':
      case 'build':
      case 'cdn':
      case 'preview':
      case 'publish':
        return before + type + '.' + nsBaseUrl + after;
      case 'upload':
        return before + type + '.' + nsBaseUrl + after + type;
      case 'download':
        return before + 'upload.' + nsBaseUrl + after + type;
    }
  };

  const value: AuthHook = {
    currentUser,
    jwt,
    login,
    logout,
    updateEmail,
    postActions,
    dataActions,
    userManagement,
    settingActions,
    modulesJson,
    nsBaseUrl,
    parseBaseUrl,
  };

  async function staticcBuildActions(action: 'preview' | 'publish' | 'blog' | 'article') {
    if (!currentUser) throw new Error('User not logged in!');
    await fetch(parseBaseUrl('build') + action, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
        referer: config.corsURL,
      },
      body: JSON.stringify({ namespace: `${currentUser.namespace}` }),
      mode: 'cors',
      credentials: 'omit',
    });
  }

  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
}

interface AuthHook {
  currentUser: User | null;
  jwt: any;
  login: (email: string, password: string) => Promise<UserCredential>;
  logout: () => void;
  updateEmail: (email: string) => Promise<UserCredential>;
  postActions: {
    list: (pageNum?: number, pageSize?: number) => Promise<{ currentPage: number; totalPages: number; posts: Post[] }>;
    search: (searchPhrase: string) => Promise<Post[]>;
    add: (post: Post) => Promise<{
      id: uuid;
    }>;
    update: (post: Post, rebuild?: boolean) => Promise<void>;
    delete: (id: uuid) => Promise<void>;
    preview: () => Promise<void>;
  };
  dataActions: {
    list: () => Promise<DataVersion[]>;
    get: (id: uuid) => Promise<ContentManagementData>;
    add: (data: ContentManagementData) => Promise<DataVersion>;
    delete: (id: uuid) => Promise<void>;
    createPreview: (id: uuid) => Promise<void>;
    buildPreview: () => Promise<void>;
    setPreview: (id: uuid) => Promise<void>;
    upgradeRelease: (id: uuid) => Promise<void>;
  };
  userManagement: {
    listUsers: () => Promise<User[]>;
    deleteUser: (uid: string) => Promise<void>;
    updateUser: (email: string, userGroup: ('admin' | 'contentManager' | 'writer')[]) => Promise<void>;
    createUser: (user: {
      email: string;
      password: string;
      userGroup: ('admin' | 'contentManager' | 'writer')[];
    }) => Promise<boolean>;
    createPassword: () => string;
    requestPasswordReset: (email?: string) => Promise<void>;
    resetPassword: (newPassword: string, key: string) => Promise<void>;
  };
  settingActions: {
    ppmAddress: {
      get: () => Promise<string>;
      update: (newAddress: string) => Promise<void>;
    };
  };
  modulesJson: any;
  nsBaseUrl: string;
  parseBaseUrl: (type: 'articles' | 'build' | 'cdn' | 'preview' | 'upload' | 'publish' | 'download') => string;
}

type UserCredential = any;

function userGroupToRightString(userGroup: ('admin' | 'contentManager' | 'writer')[]): string {
  let rightString = '';
  if (userGroup.includes('admin')) rightString += 'a';
  if (userGroup.includes('contentManager')) rightString += 'c';
  if (userGroup.includes('writer')) rightString += 'w';
  return rightString;
}

function rightStringTouserGroup(rightString: string): ('admin' | 'contentManager' | 'writer')[] {
  const userGroup = [];
  if (rightString.includes('a')) userGroup.push('admin');
  if (rightString.includes('c')) userGroup.push('contentManager');
  if (rightString.includes('w')) userGroup.push('writer');
  return userGroup as ('admin' | 'contentManager' | 'writer')[];
}
