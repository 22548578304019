import React from 'react';

import { PostsContext, SelectedPostContext } from '../ContextManager';
import history from '../history';
import { Post, SelectedPost } from '../Interfaces';

import FileSelector from '@idot-digital/file-selector';

import { RemoveButtonBin, PublicToggle, DoneButton } from '@idot-digital/custom-material-ui';
import '@idot-digital/custom-material-ui/dist/index.css';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { AttachFile } from '@material-ui/icons';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { useAuth } from './Authentication/AuthContext';
import config from '../config';

import './editorPage.css';
import TipTapTextEditor from './TextEditor/TipTapTextEditor';

const regexEditorIsEmpty = /^(<p>((<br>)||(&nbsp;)*)<\/p>\n?)*$/;

export default function EditorPage() {
  const [fileSelectorOpen, setFileSelectorOpen] = React.useState(false);
  const [deleteWarning, setDeleteWarningOpen] = React.useState(false);
  const [textEmptyWarning, setTextEmptyWarning] = React.useState(false);

  const [showErrors, setShowErrors] = React.useState(false);

  const loading = React.useRef(false);

  const { postActions, jwt, parseBaseUrl } = useAuth();

  const [posts, setposts] = React.useContext(PostsContext);
  const [selectedPost, setselectedPost] = React.useContext(SelectedPostContext);

  const { enqueueSnackbar } = useSnackbar();

  const settings = {
    credentials: 'omit',
    headers: { Authorization: `Bearer ${jwt}`, referer: config.corsURL },
    mode: 'cors',
  } as Omit<RequestInit, 'method'>;

  const selPost: Post = selectedPost
    ? selectedPost.post
    : {
        title: '',
        text: '',
        author: '',
        date: new Date().getTime(),
        published: false,
        images: [],
        categories: ['Artikel'],
      };

  // Ref for server update
  const initialPost = React.useRef({} as Post);
  React.useEffect(() => {
    initialPost.current = selPost;
  }, [selPost]);

  const [categories, setCategories] = React.useState(selPost.categories.filter((c) => c !== 'pinned'));
  const [pinned, setPinned] = React.useState(selPost.categories.includes('pinned'));
  const [images, setImages] = React.useState(selPost.images);
  const [published, setPublished] = React.useState(selPost.published);
  const [title, setTitle] = React.useState(selPost.title);
  const [author, setAuthor] = React.useState(selPost.author);
  const [date, setDate] = React.useState<Date | null>(new Date(selPost.date));
  const [content, setContent] = React.useState(selPost.text);

  function redirectBack() {
    history.push('/articles');
  }

  return (
    <>
      <Dialog open={deleteWarning} onClose={() => setDeleteWarningOpen(false)}>
        <DialogTitle>Achtung</DialogTitle>
        <DialogContent>Möchten Sie wirklich diesen Beitrag löschen?</DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteWarningOpen(false)} color="primary" autoFocus>
            Abbrechen
          </Button>
          <Button
            onClick={() => {
              if (!loading.current && selectedPost && selPost.id) {
                loading.current = true;

                postActions
                  .delete(selPost.id)
                  .then(() => {
                    loading.current = false;
                    setposts({
                      ...posts,
                      posts: posts.posts.filter((post) => post.id !== (selectedPost as SelectedPost).post.id),
                    });
                    redirectBack();
                    setselectedPost(undefined);
                  })
                  .catch(() => (loading.current = false));
              } else redirectBack();
            }}
            color="primary"
          >
            Beitrag wirklich löschen
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={textEmptyWarning} onClose={() => setTextEmptyWarning(false)}>
        <DialogTitle>Achtung</DialogTitle>
        <DialogContent>Der Artikel enthält momentan kein Text</DialogContent>
        <DialogActions>
          <Button onClick={() => setTextEmptyWarning(false)} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <FileSelector
        open={fileSelectorOpen}
        onClose={() => setFileSelectorOpen(false)}
        links={{
          get: {
            url: parseBaseUrl('upload'),
            method: 'GET',
            settings,
          },
          rename: { url: parseBaseUrl('upload'), method: 'PUT', settings },
          delete: {
            url: parseBaseUrl('upload'),
            method: 'DELETE',
            settings,
          },
          upload: { url: parseBaseUrl('upload'), method: 'POST', settings },
          root: parseBaseUrl('cdn'),
        }}
        callbackDone={(links) => {
          setImages(links.map((link) => ({ path: parseBaseUrl('cdn') + link.path, alt: link.displayName })));
          setFileSelectorOpen(false);
        }}
        filter={(files) => [...files].reverse()}
        previewImage={(path) => path.replace('.jpg', '-low.webp')}
        pickedFiles={images.map((image) => image.path)}
        multiple
      />

      <div className="editorPage">
        <div className="editorPageHead">
          <TextField
            className="editorTitle"
            value={title}
            error={showErrors && !title}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setTitle(event.target.value)}
            label="Titel"
          />
          <TextField
            className="editorAuthor"
            value={author}
            error={showErrors && !author}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setAuthor(event.target.value)}
            label="Autor"
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd.MM.yyyy"
              className="editDate"
              label="Datum"
              helperText=""
              value={date}
              onChange={setDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div className="editorPageBody">
          <TipTapTextEditor defaultValue={content} onChange={(content) => setContent(content)} disableImages />
        </div>
        <div className="editorContent">
          <Select
            value={categories}
            onChange={(event) => setCategories([event.target.value] as string[])}
            renderValue={(selected) => (selected as string[]).join(', ')}
          >
            {['Artikel', 'Eilmeldung'].map((item) => (
              <MenuItem value={item} key={item}>
                <Checkbox checked={categories.includes(item)} color="primary" />
                <ListItemText primary={item} />
              </MenuItem>
            ))}
          </Select>
          <FormControlLabel
            control={<Switch checked={pinned} onChange={() => setPinned(!pinned)} color="primary" />}
            label="Pinned"
          />
          <div className="fileSelectorTextPreview">{images.map((image) => image.alt).join(', ')}</div>
          <Tooltip title="Bildauswahl" className="fileSelector">
            <div>
              <AttachFile onClick={() => setFileSelectorOpen(true)} />
            </div>
          </Tooltip>
        </div>
        <div className="editorPageButtons">
          <Tooltip title="Beitrag löschen" className="removeButton">
            <div>
              <RemoveButtonBin onClick={() => setDeleteWarningOpen(true)} />
            </div>
          </Tooltip>
          <Tooltip title="Öffentlich" className="publicToggleButton">
            <div>
              <PublicToggle onClick={() => setPublished((published: boolean) => !published)} active={published} />
            </div>
          </Tooltip>
          <Tooltip title="Beitrag speichern" className="doneButton">
            <div>
              <DoneButton
                onClick={() => {
                  if (title && !regexEditorIsEmpty.test(content) && author && date && !isNaN(date.getTime())) {
                    //TODO das hier ist auch langsam. Siehe ContentManagement 100!
                    let post = {
                      id: selPost.id,
                      title,
                      text: content,
                      author,
                      date: date.getTime(),
                      published,
                      images,
                      categories: pinned ? ['pinned', ...categories] : categories,
                    } as Post;

                    if (!loading.current && selectedPost && post.id) {
                      loading.current = true;

                      let stringified = {
                        // strings and integers
                        ...Object.fromEntries(
                          ['title', 'text', 'author', 'published'].map((key) => [key, post[key as keyof Post]]),
                        ),
                        // objects
                        ...Object.fromEntries(
                          ['images', 'categories'].map((key) => [key, JSON.stringify(post[key as keyof Post])]),
                        ),
                        // dates
                        date: date.getTime(),
                      } as Post;

                      postActions
                        .update({ ...stringified, id: post.id })
                        .then(() => {
                          loading.current = false;

                          const index = posts.posts.findIndex((postList) => postList.id === post.id);
                          setposts({
                            ...posts,
                            posts: [
                              ...posts.posts.slice(0, index),
                              post,
                              ...posts.posts.slice(index + 1, posts.posts.length),
                            ],
                          });
                          redirectBack();
                          setselectedPost(undefined);
                        })
                        .then(() =>
                          enqueueSnackbar('Beitrag erfolgreich aktualisiert', {
                            variant: 'success',
                          }),
                        )
                        .catch(() => {
                          loading.current = false;
                          enqueueSnackbar('Beim aktualisieren des Beitrags ist ein Fehler aufgetreten', {
                            variant: 'error',
                          });
                        });
                    } else if (!loading.current) {
                      loading.current = true;

                      let stringified = {
                        ...post,
                        images: JSON.stringify(post.images),
                        categories: JSON.stringify(post.categories),
                      } as unknown as Post;

                      postActions
                        .add(stringified)
                        .then((response: any) => {
                          loading.current = false;
                          setposts({
                            ...posts,
                            posts: [...posts.posts, { ...post, id: response.id } as Post],
                          });
                          redirectBack();
                          setselectedPost(undefined);
                        })
                        .then(() =>
                          enqueueSnackbar('Beitrag erfolgreich erstellt', {
                            variant: 'success',
                          }),
                        )
                        .catch(() => {
                          loading.current = false;
                          enqueueSnackbar('Beim erstellen des Beitrags ist ein Fehler aufgetreten', {
                            variant: 'error',
                          });
                        });
                    }
                  }
                  // Trigger warnings
                  else {
                    setShowErrors(true);

                    if (regexEditorIsEmpty.test(content)) setTextEmptyWarning(true);
                  }
                }}
              />
            </div>
          </Tooltip>
        </div>
      </div>
    </>
  );
}
