import React from 'react';

import CardsEditor from './Editors/CardsEditor/CardsEditor';
import NavigationEditor from './Editors/NavigationEditor/NavigationEditor';
import PeopleEditor from './Editors/PeopleEditor/PeopleEditor';
import QuickAccessEditor from './Editors/QuickAccessEditor/QuickAccessEditor';
import SliderEditor from './Editors/SliderEditor';
import SubjectsEditor from './Editors/SubjectsEditor/SubjectsEditor';
import TableEditor from './Editors/TableEditor/TableEditor';
import ClassesEditor from './Editors/ClassesEditor/ClassesEditor';

import { DataVersion } from '../Interfaces';
import { DataContext, DataHistoryContext, DataIDContext, ModulesContext } from '../ContextManager';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { BuildRounded, PublicRounded } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

import GenericEditor from './Editors/Generic/GenericEditor';

import { v4 as uuidv4 } from 'uuid';

import { useAuth } from './Authentication/AuthContext';

import './contentManagmentPage.css';
import MadMaxEditor from './Editors/MadMaxEditor/MadMaxEditor';
import PageEditor from './Editors/PageEditor/PageEditor';
import TipTapTextEditor from './TextEditor/TipTapTextEditor';

const StyledListItemIcon = styled(ListItemIcon)({
  minWidth: 0,
  paddingLeft: 10,
  paddingRight: 5,
});

const dateFormat: Intl.DateTimeFormatOptions = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
};

export const GenericInputTextField = React.createContext<{
  setGenericInputOnClose: React.Dispatch<React.SetStateAction<((html: string) => void) | undefined>>;
  setTextEditorText: React.Dispatch<React.SetStateAction<any>>;
}>(undefined!);

export default function ContentManagementPage() {
  const [dataHistory, setDataHistory] = React.useContext(DataHistoryContext);
  const [data, setData] = React.useContext(DataContext);
  const [modules] = React.useContext(ModulesContext);
  const [currentDataBranch, setCurrentBranch] = React.useContext(DataIDContext);

  const [maximizedGenericInput, setMaximizedGenericInput] = React.useState(false);
  const [textEditorText, setTextEditorText] = React.useState('');
  const [genericInputOnClose, setGenericInputOnClose] = React.useState<((html: string) => void) | undefined>(undefined);

  React.useEffect(() => setMaximizedGenericInput(!!genericInputOnClose), [genericInputOnClose]);

  const updatingPreview = React.useRef(false);

  const { dataActions, parseBaseUrl } = useAuth();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const updateData = (newData: any, index: number) => setData({ ...data, ...{ [modules[index][0]]: newData } });

  const currentData: DataVersion | undefined = dataHistory.find((data) => data.id === currentDataBranch);

  return (
    <>
      <div className="contentManagmentPageHeader">
        <div className="contentManagmentManager">
          <Select
            onChange={(event) => setCurrentBranch(event.target.value as string)}
            value={currentDataBranch}
            renderValue={() => new Date(currentData?.date || 0).toLocaleString('de-DE', dateFormat)}
          >
            {dataHistory
              .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
              .map((item) => (
                <MenuItem value={item.id} key={item.id}>
                  <ListItemText>{new Date(item.date).toLocaleString('de-DE', dateFormat)}</ListItemText>

                  {item.preview && (
                    <StyledListItemIcon>
                      <Tooltip title="Preview">
                        <BuildRounded />
                      </Tooltip>
                    </StyledListItemIcon>
                  )}
                  {item.current && (
                    <StyledListItemIcon>
                      <Tooltip title="Aktuell">
                        <PublicRounded />
                      </Tooltip>
                    </StyledListItemIcon>
                  )}
                </MenuItem>
              ))}
          </Select>
          {!currentData?.preview && (
            <Button
              variant="outlined"
              color="primary"
              onClick={() =>
                dataActions.setPreview(currentDataBranch).then(() =>
                  setDataHistory((history) =>
                    history.map((entry) => ({
                      ...entry,
                      preview: entry.id === currentDataBranch,
                    })),
                  ),
                )
              }
            >
              Als Preview setzen
            </Button>
          )}
          {currentData?.preview && !currentData?.current && (
            <Button
              variant="outlined"
              color="primary"
              onClick={() =>
                dataActions.upgradeRelease(currentDataBranch).then(() =>
                  setDataHistory((history) =>
                    history.map((entry) => ({
                      ...entry,
                      current: entry.id === currentDataBranch,
                    })),
                  ),
                )
              }
            >
              Als Aktuell setzen
            </Button>
          )}
          {currentData?.preview && (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                if (!updatingPreview.current) {
                  let key = uuidv4();

                  enqueueSnackbar(`Preview wird erstellt`, { key });

                  updatingPreview.current = true;

                  dataActions
                    .buildPreview()
                    .then(() => closeSnackbar(key))
                    .then(() => enqueueSnackbar(`Preview erfolgreich erstellt`, { variant: 'success' }))
                    .catch(() => enqueueSnackbar(`Preview konnte nicht erstellt werden`, { variant: 'error' }))
                    .finally(() => (updatingPreview.current = false));
                }
              }}
            >
              Preview erstellen
            </Button>
          )}
          {currentData?.preview && (
            <Button variant="outlined" color="primary" href={parseBaseUrl('preview')} target="_blank">
              Preview einsehen
            </Button>
          )}
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              dataActions
                .add(data)
                .then((entry: DataVersion) => {
                  setDataHistory([entry, ...dataHistory]);
                  setCurrentBranch(entry.id);
                  return entry.id;
                })
                .then((id: any) =>
                  enqueueSnackbar(
                    `Daten erfolgreich gespeichert. Der Datensatz hat die ID "${id}" zugewiesen bekommen`,
                    { variant: 'success' },
                  ),
                )
                .catch(() =>
                  enqueueSnackbar(
                    'Der Datensatz konnte nicht gespeichert werden. Bitte versuchen Sie es in kurzer Zeit erneut.',
                    { variant: 'error' },
                  ),
                );
            }}
          >
            Speichern
          </Button>
        </div>
      </div>

      <Dialog
        open={maximizedGenericInput}
        onClose={() => setMaximizedGenericInput(false)}
        TransitionProps={{ onExited: () => setTextEditorText('') }}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          <TipTapTextEditor
            hideSave
            onChange={(text) => {
              setTextEditorText(text);
            }}
            defaultValue={textEditorText}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMaximizedGenericInput(false)} color="primary" autoFocus>
            Abbrechen
          </Button>
          <Button
            onClick={() => {
              setMaximizedGenericInput(false);

              genericInputOnClose?.(textEditorText);
            }}
            color="primary"
          >
            Übernehmen
          </Button>
        </DialogActions>
      </Dialog>

      <GenericInputTextField.Provider value={{ setGenericInputOnClose, setTextEditorText }}>
        {modules.length && Object.keys(data).length ? (
          modules.map((module, index) => {
            const values: any = data[module[0]];
            const headline = module[1].name;
            const type = module[1].type;

            if (values) {
              switch (type) {
                case 'quickAccessEditor':
                  return (
                    <QuickAccessEditor
                      callback={(data) => updateData(data, index)}
                      headline={headline}
                      data={values}
                      key={index}
                    />
                  );
                case 'navigationEditor':
                  return (
                    <NavigationEditor
                      callback={(data) => updateData(data, index)}
                      headline={headline}
                      data={values}
                      key={index}
                    />
                  );
                case 'sliderEditor':
                  return (
                    <SliderEditor
                      callback={(data) => updateData(data, index)}
                      headline={headline}
                      data={values}
                      key={index}
                    />
                  );
                case 'cardsEditor':
                  return (
                    <CardsEditor
                      callback={(data) => updateData(data, index)}
                      headline={headline}
                      data={values}
                      key={index}
                    />
                  );
                case 'peopleEditor':
                  return (
                    <PeopleEditor
                      callback={(data) => updateData(data, index)}
                      headline={headline}
                      data={values}
                      key={index}
                    />
                  );
                case 'subjectsEditor':
                  return (
                    <SubjectsEditor
                      callback={(data) => updateData(data, index)}
                      headline={headline}
                      data={values}
                      key={index}
                    />
                  );
                case 'tableEditor':
                  return (
                    <TableEditor
                      callback={(data) => updateData(data, index)}
                      headline={headline}
                      data={values}
                      key={index}
                    />
                  );
                case 'classesEditor':
                  return (
                    <ClassesEditor
                      callback={(data) => updateData(data, index)}
                      headline={headline}
                      data={values}
                      key={index}
                    />
                  );
                case 'madMaxEditor':
                  return (
                    <MadMaxEditor
                      callback={(data) => updateData(data, index)}
                      headline={headline}
                      data={values}
                      key={index}
                    />
                  );
                case 'genericEditor':
                  return (
                    <GenericEditor
                      callback={(data) => updateData(data, index)}
                      headline={headline}
                      data={values}
                      key={index}
                    />
                  );

                case 'pageEditor':
                  return (
                    <PageEditor
                      headline={headline}
                      data={values}
                      callback={(data) => updateData(data, index)}
                      key={index}
                    />
                  );

                default:
                  return '';
              }
            } else return '';
          })
        ) : (
          <CircularProgress className="loading" />
        )}
      </GenericInputTextField.Provider>
    </>
  );
}
