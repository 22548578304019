import React from 'react';

import {
  PostsContext,
  PostSearchContext,
  PostsLoadedContext,
  PostsSearchedContext,
  SelectedPostContext,
} from '../ContextManager';
import history from '../history';
import { Post } from '../Interfaces';
import Highlighter from 'react-highlight-words';

import { AddButtonRotating, EditButton } from '@idot-digital/custom-material-ui';
import '@idot-digital/custom-material-ui/dist/index.css';

import { BookmarkBorder, Bookmark } from '@material-ui/icons';
import { TextField, Tooltip, CircularProgress, Button, IconButton } from '@material-ui/core';

import { useAuth } from './Authentication/AuthContext';

import { v4 as uuidv4 } from 'uuid';

import { useSnackbar } from 'notistack';

import config from '../config';

import './articlesPage.css';

export default function ArticlesPage() {
  const [posts, setPosts] = React.useContext(PostsContext);
  const setSelectedPost = React.useContext(SelectedPostContext)[1];
  const [loadedPosts, setLoadedPosts] = React.useContext(PostsLoadedContext);

  const [search, setSearch] = React.useContext(PostSearchContext);
  const [searchedPost] = React.useContext(PostsSearchedContext);

  const updatingPreview = React.useRef(false);

  const { postActions, parseBaseUrl } = useAuth();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const pinPost = async (post: Post) => {
    const newCategories = !post.categories.includes('pinned')
      ? post.categories.concat('pinned')
      : post.categories.filter((cat) => cat !== 'pinned');

    let stringified = {
      // strings and integers
      ...Object.fromEntries(['title', 'text', 'author', 'published'].map((key) => [key, post[key as keyof Post]])),
      // objects
      ...Object.fromEntries(['images', 'categories'].map((key) => [key, JSON.stringify(post[key as keyof Post])])),
      // dates
      date: post.date,
    } as Post;

    // Add or remove category "pinned" without triggering a re-build
    await postActions
      .update({ ...stringified, categories: JSON.stringify(newCategories), id: post.id } as unknown as Post, false)
      .catch(() => enqueueSnackbar('Der Artikel konnte nicht gepinnt werden', { variant: 'error' }));
    setPosts({
      ...posts,
      posts: posts.posts.map((current) =>
        current.id !== post.id ? current : { ...current, categories: newCategories },
      ),
    });
  };

  return (
    <div className="articlesPage">
      <div className="articlesBar">
        <TextField
          label="Suche"
          className="articlesSearch"
          value={search}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)}
        />
        <Tooltip title="Unveröffentlichte Artikel preview erstellen" className="previewLink">
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              if (!updatingPreview.current) {
                let key = uuidv4();

                enqueueSnackbar(`Preview wird erstellt`, { key });

                updatingPreview.current = true;

                postActions
                  .preview()
                  .then(() => closeSnackbar(key))
                  .then(() => enqueueSnackbar(`Preview erfolgreich erstellt`, { variant: 'success' }))
                  .catch(() => enqueueSnackbar(`Preview konnte nicht erstellt werden`, { variant: 'error' }))
                  .finally(() => (updatingPreview.current = false));
              }
            }}
          >
            Preview erstellen
          </Button>
        </Tooltip>
        <Tooltip title="Es werden nur unveröffentlichte Artikel angezeigt" className="previewLink">
          <Button variant="outlined" color="primary" href={parseBaseUrl('articles')} target="_blank">
            Artikel einsehen
          </Button>
        </Tooltip>
        <Tooltip title="Neuen Beitrag erstellen" className="addNewArticle">
          <div>
            <AddButtonRotating
              onClick={() => {
                setSelectedPost(undefined);
                history.push('/editor');
              }}
            />
          </div>
        </Tooltip>
      </div>
      {posts.currentPage > 0 ? (
        <div className="articlesList">
          {(searchedPost.length ? searchedPost : posts.posts)
            .sort((first: Post, second: Post) => new Date(second.date).getTime() - new Date(first.date).getTime())
            .sort(
              (first: Post, second: Post) =>
                (second.categories.includes('pinned') ? 1 : 0) - (first.categories.includes('pinned') ? 1 : 0),
            )
            .map((post: Post) => {
              return (
                <div key={post.id} className="article">
                  <Highlighter
                    highlightClassName="highlightArticle"
                    searchWords={[search]}
                    textToHighlight={post.title}
                    autoEscape
                  />
                  <IconButton onClick={() => pinPost(post)} className="editButton">
                    {post.categories.includes('pinned') ? <Bookmark /> : <BookmarkBorder />}
                  </IconButton>
                  <Tooltip title="Beitrag bearbeiten">
                    <div>
                      <EditButton
                        onClick={() => {
                          setSelectedPost({
                            index: posts.posts.findIndex((currentPost) => currentPost.id === post.id),
                            post,
                          });
                          history.push('/editor');
                        }}
                      />
                    </div>
                  </Tooltip>
                </div>
              );
            })}
        </div>
      ) : (
        <CircularProgress className="loading" />
      )}
      {posts.currentPage !== posts.totalPages && (
        <div className="loadMoreWrapper">
          <Button
            variant="outlined"
            color="primary"
            className="loadMore"
            onClick={() => setLoadedPosts(loadedPosts + 10)}
          >
            Mehr laden
          </Button>
        </div>
      )}
    </div>
  );
}
