import React from 'react';
import { useHistory } from 'react-router-dom';

import { PpmAddressContext } from './ContextManager';

import passwordGenerator from 'generate-password';

import { VisibilityToggle } from '@idot-digital/custom-material-ui';
import '@idot-digital/custom-material-ui/dist/index.css';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip } from '@material-ui/core';
import { LockOpenRounded, EmailRounded } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

import { useAuth } from './Components/Authentication/AuthContext';
import * as EmailValidator from 'email-validator';

import './settingsPage.css';

export default function SettingsPage() {
  const [ppmAddress, setPPMAddress] = React.useContext(PpmAddressContext);
  const [hidden, setHidden] = React.useState(true);

  const [passwordDialogOpen, setPasswordDialogOpen] = React.useState(false);
  const [emailDialogOpen, setEmailDialogOpen] = React.useState(false);

  const { settingActions, currentUser } = useAuth();

  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <Dialog open={passwordDialogOpen} onClose={() => setPasswordDialogOpen(false)}>
        <NewPassword close={() => setPasswordDialogOpen(false)} />
      </Dialog>

      <Dialog open={emailDialogOpen} onClose={() => setEmailDialogOpen(false)}>
        <NewEmail close={() => setEmailDialogOpen(false)} />
      </Dialog>

      <div className="settingsPageWrapper">
        {currentUser?.userGroup.includes('admin') && (
          <div className="postPerMailWrapper">
            <div className="title">Post Per Mail</div>
            <SecretDisplay secret={ppmAddress} hidden={hidden} setHidden={setHidden} />
            <div className="generateMail">
              <Button
                className="generate"
                variant="outlined"
                color="primary"
                onClick={() => {
                  const mailusername = passwordGenerator.generate({
                    length: 10,
                  });
                  const newMail = `${mailusername}@ppm.idot-digital.de`;

                  setHidden(() => true);

                  settingActions.ppmAddress
                    .update(newMail)
                    .then(() => {
                      setPPMAddress(() => newMail);
                      enqueueSnackbar('Die "Post Per Mail" Adresse wurde erfolgreich aktualisiert', {
                        variant: 'success',
                      });
                    })
                    .catch(() =>
                      enqueueSnackbar(
                        'Die "Post Per Mail" Adresse wurde nicht korrekt aktualisiert. Bitte versuchen Sie es in kurzer Zeit erneut.',
                        { variant: 'error' },
                      ),
                    );
                }}
              >
                Neu generieren
              </Button>
            </div>
          </div>
        )}
        <div className="changeSettings">
          <Button
            className="changePassword"
            variant="outlined"
            onClick={() => setPasswordDialogOpen(true)}
            startIcon={<LockOpenRounded />}
          >
            Passwort ändern
          </Button>
          <Button
            className="changeEmail"
            variant="outlined"
            onClick={() => setEmailDialogOpen(true)}
            startIcon={<EmailRounded />}
          >
            E-Mail ändern
          </Button>
        </div>
      </div>
    </>
  );
}

function SecretDisplay(props: {
  secret: string;
  hidden: boolean;
  setHidden: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <div className="secretDisplay">
      <Tooltip title="E-Mail Sichtbarkeit ändern">
        <div>
          <VisibilityToggle
            className="visibility"
            onClick={(active) => props.setHidden(active)}
            active={props.hidden}
          />
        </div>
      </Tooltip>
      {props.hidden ? '************' : props.secret}
    </div>
  );
}

function NewPassword(props: { close: () => void }) {
  const generated = React.useRef(
    passwordGenerator.generate({
      length: 16,
      numbers: true,
      symbols: true,
    }),
  );

  const { userManagement, logout } = useAuth();

  const history = useHistory();

  const [securityKey, setSecurityKey] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmNewPassword, setConfirmNewPassword] = React.useState('');

  const { enqueueSnackbar } = useSnackbar();

  let confirmPasswordError = !(
    (generated.current === newPassword && !confirmNewPassword) ||
    confirmNewPassword === newPassword
  );

  React.useEffect(() => {
    userManagement.requestPasswordReset();
    enqueueSnackbar('Der Sicherheitsschlüssel wurde an Ihre Email gesendet');
  }, []);

  return (
    <>
      <DialogTitle>Passwort ändern</DialogTitle>
      <DialogContent>
        <div className="passwordWrapper">
          <TextField
            className="input"
            value={securityKey}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSecurityKey(event.target.value)}
            label="Sicherheitsschlüssel"
          />
          <TextField
            className="input"
            type="password"
            value={newPassword}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNewPassword(event.target.value)}
            label="Neues Passwort"
          />
          <TextField
            className="input"
            type="password"
            value={confirmNewPassword}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setConfirmNewPassword(event.target.value)}
            label="Neues Passwort bestätigen"
            helperText={confirmPasswordError ? 'Die Passwörter stimmen nicht überein' : ' '}
            error={confirmPasswordError}
          />
          oder
          <TextField
            className="input"
            value={generated.current}
            helperText="Sicheres und zufälliges Passwort"
            onClick={(event) => {
              (event.target as HTMLInputElement).focus();
              (event.target as HTMLInputElement).select();
              document.execCommand('copy');

              enqueueSnackbar('Passwort kopiert', {
                variant: 'success',
              });
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.close} color="primary">
          Abbrechen
        </Button>
        <Button
          onClick={() => {
            userManagement
              .resetPassword(newPassword || generated.current, securityKey)
              .then(() => {
                logout();
                history.push('/login');
                enqueueSnackbar(
                  'Paswort erfolgreich aktualisiert. Sie können sich nun mit dem neuen Passwort anmelden',
                  {
                    variant: 'success',
                  },
                );
              })
              .catch(() =>
                enqueueSnackbar(
                  'Die Verbindung zum Server konnte nicht hergestellt werden oder der Sicherheitsschlüssel war inkorrekt. Bitte überprüfen Sie ihre Angaben und versuchen Sie es in kurzer Zeit erneut.',
                  { variant: 'error' },
                ),
              );
            props.close();
          }}
          color="primary"
          autoFocus
          disabled={
            !(
              securityKey &&
              ((newPassword && newPassword === confirmNewPassword) || (!newPassword && !confirmNewPassword))
            )
          }
        >
          Passwort aktualisieren
        </Button>
      </DialogActions>
    </>
  );
}

function NewEmail(props: { close: () => void }) {
  const [oldEmail, setOldEmail] = React.useState('');
  const [newEmail, setNewEmail] = React.useState('');
  const [confirmEmail, setConfirmEmail] = React.useState('');

  const [error, setError] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { updateEmail, logout } = useAuth();

  const validNewEmail = EmailValidator.validate(newEmail);

  return (
    <>
      <DialogTitle>E-Mail ändern</DialogTitle>
      <DialogContent>
        <div className="emailWrapper">
          <TextField
            className="input"
            value={oldEmail}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setOldEmail(event.target.value)}
            label="Alte E-Mail"
            error={EmailValidator.validate(oldEmail)}
          />
          <TextField
            className="input"
            value={newEmail}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNewEmail(event.target.value)}
            label="Neue E-Mail"
            error={error ? !validNewEmail : false}
          />
          <TextField
            className="input"
            value={confirmEmail}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setConfirmEmail(event.target.value)}
            label="Neue E-Mail bestätigen"
            helperText={
              error ? (!validNewEmail ? 'Die E-Mail ist nicht valide' : 'Die E-Mails stimmen nicht überein') : ' '
            }
            error={error ? newEmail !== confirmEmail : false}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.close} color="primary">
          Abbrechen
        </Button>
        <Button
          onClick={() => {
            updateEmail(newEmail)
              .then(() => logout())
              .then(() =>
                enqueueSnackbar('E-Mail erfolgreich aktualisiert. Sie können sich nun mit der neuen Email anmelden', {
                  variant: 'success',
                }),
              )
              .catch(() => {
                setError(true);
                enqueueSnackbar(
                  'Die Verbindung zum Server konnte nicht hergestellt werden oder die E-Mail war inkorrekt. Bitte überprüfen Sie ihre Angaben und versuchen Sie es in kurzer Zeit erneut.',
                  { variant: 'error' },
                );
              });

            props.close();
          }}
          color="primary"
          autoFocus
          disabled={!oldEmail || newEmail !== confirmEmail}
        >
          E-Mail aktualisieren
        </Button>
      </DialogActions>
    </>
  );
}
