import React from 'react';
import { SubPageData } from './PageEditor';
import { Box, TextField } from '@material-ui/core';
import { DeepPartial } from '../../../Interfaces';
import SliderEditor from '../SliderEditor';
import TipTapTextEditor from '../../TextEditor/TipTapTextEditor';

export interface SubpageEditorProps {
  data: SubPageData;
  callback: (data: DeepPartial<SubPageData>) => void;
}

export default function SubpageEditor(props: SubpageEditorProps) {
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '.5rem',
        padding: '.5rem',
      }}
    >
      {typeof props.data.headline === 'string' && (
        <div className="simpleInput">
          <span className="title">Titel</span>
          <TextField
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.callback({ headline: event.target.value })}
            className="input"
            value={props.data.headline}
            InputProps={{ style: { color: 'inherit' } }}
          />
        </div>
      )}
      {typeof props.data.subheader === 'string' && (
        <div className="simpleInput">
          <span className="title">Untertitel</span>
          <TextField
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.callback({ subheader: event.target.value })}
            className="input"
            value={props.data.subheader}
            InputProps={{ style: { color: 'inherit' } }}
          />
        </div>
      )}
      {typeof props.data.content === 'string' && (
        <TipTapTextEditor
          defaultValue={props.data.content}
          onChange={(text) => {
            if (text !== props.data.content) props.callback({ content: text });
          }}
          hideSave
        />
      )}
      {props.data.slider && (
        <>
          <h3>Slider</h3>
          <SliderEditor
            callback={(slider) => {
              if (!slider) return;
              props.callback({ slider });
            }}
            data={props.data.slider}
            headline=""
            omitGroup
          />
        </>
      )}
    </Box>
  );
}
