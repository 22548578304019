import { Route, Redirect } from 'react-router-dom';
import { useAuth } from './AuthContext';

export default function PrivateRoute(props: any) {
  const { currentUser } = useAuth();

  const { component, ...rest } = props;

  const Component = component;

  return (
    <Route
      {...rest}
      render={(props) => {
        return currentUser ? <Component {...props} /> : <Redirect to="/login" />;
      }}
    ></Route>
  );
}
