import React from 'react';

import { QuickAccessLink } from '../../../Interfaces';
import LinkEditor from '../../LinkEditor';

export interface QuickAccessLinkEditorProps {
  data: QuickAccessLink;
  color: string;
  callback: (data: QuickAccessLink | null) => void;
  removable: boolean;
}

export default function QuickAccessLinkEditor(props: QuickAccessLinkEditorProps) {
  const [name, setName] = React.useState(props.data.name);
  const [href, setHref] = React.useState(props.data.href);
  const [image, setImage] = React.useState(props.data.image);

  React.useEffect(() => {
    props.callback({
      name,
      href,
      image,
      _key: props.data._key,
    });
  }, [name, href, image]);

  return (
    <LinkEditor
      onRemove={props.removable ? () => props.callback(null) : undefined}
      name={name}
      href={href}
      image={image}
      setName={setName}
      setHref={setHref}
      setImage={setImage}
      color={props.color}
      namePlaceholder="Linkname"
    />
  );
}
