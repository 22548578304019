import { LogoutButton } from '@idot-digital/custom-material-ui';
import { NavLink, useHistory } from 'react-router-dom';
import { useAuth } from './Authentication/AuthContext';

import { Tooltip } from '@material-ui/core';
import { useSnackbar } from 'notistack';

export default function NavigationBar() {
  const { logout, currentUser } = useAuth();
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  return (
    <nav className="navbar">
      {currentUser?.userGroup.includes('writer') && (
        <NavLink activeClassName="active" className="link" to="/" exact>
          Beiträge
        </NavLink>
      )}
      {currentUser?.userGroup.includes('contentManager') && (
        <NavLink
          activeClassName="active"
          className="link"
          to="/contentmanagement"
          isActive={(_, location) => {
            return (
              (location.pathname === '/' && !currentUser?.userGroup.includes('writer')) ||
              location.pathname.includes('/contentmanagement')
            );
          }}
        >
          Contentmanagement
        </NavLink>
      )}
      {currentUser?.userGroup.includes('admin') && (
        <NavLink
          activeClassName="active"
          className="link"
          to="/users"
          isActive={(_, location) => {
            return (
              (location.pathname === '/' &&
                !currentUser?.userGroup.includes('contentManager') &&
                !currentUser?.userGroup.includes('writer')) ||
              location.pathname.includes('/users')
            );
          }}
        >
          Benutzer
        </NavLink>
      )}
      <NavLink
        activeClassName="active"
        className="link"
        to="/settings"
        isActive={(_, location) => {
          return (
            (location.pathname === '/' &&
              !currentUser?.userGroup.includes('contentManager') &&
              !currentUser?.userGroup.includes('writer') &&
              !currentUser?.userGroup.includes('admin')) ||
            location.pathname.includes('/settings')
          );
        }}
      >
        Einstellungen
      </NavLink>
      <Tooltip title="Ausloggen" className="logout">
        <div>
          <LogoutButton
            onClick={() => {
              logout();
              history.push('/login');
              enqueueSnackbar('Sie wurden erfolgreich abgemeldet', { variant: 'success', autoHideDuration: 1000 });
            }}
          />
        </div>
      </Tooltip>
    </nav>
  );
}
