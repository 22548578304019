import React from 'react';

import { Person } from '../../../Interfaces';
import LinkEditor from '../../LinkEditor';

import './personEditor.css';

export interface PersonEditorProps {
  personData: Person;
  callback: (data: Person | null) => void;
  removable: boolean;
}

export default function PersonEditor(props: PersonEditorProps) {
  const [name, setName] = React.useState(props.personData.name);
  const [desc, setDesc] = React.useState(props.personData.desc);
  const [imgPath, setImgPath] = React.useState(props.personData.imgPath);

  React.useEffect(() => {
    props.callback({
      name,
      desc,
      imgPath,
      _key: props.personData._key,
    });
  }, [name, desc, imgPath]);

  return (
    <LinkEditor
      previewClassName="personEditorPreview"
      inputClassName="personEditorInput"
      onRemove={props.removable ? () => props.callback(null) : undefined}
      name={name}
      image={imgPath}
      description={desc}
      setName={setName}
      setImage={setImgPath}
      setDescription={setDesc}
      picturePlaceholder="Profilbild"
      descriptionPlaceholder="Beschreibung der Person"
    />
  );
}
