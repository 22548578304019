import React from 'react';

import { addGroup, updateGroup } from '../../../functions';
import { Card } from '../../../Interfaces';
import GroupCardManager from '../../GroupCardManager';
import CardsElementEditor from './CardsElementEditor';

import { v4 as uuidv4 } from 'uuid';

export interface CardsEditorProps {
  headline: string;
  data: Card[];
  callback: (data: Card[] | null) => void;
}

export default function CardsEditor(props: CardsEditorProps) {
  const [cardsData, setCardsData] = React.useState(props.data);

  React.useEffect(() => {
    props.callback(cardsData);
  }, [cardsData]);

  const addCard = addGroup.bind({
    setFunc: setCardsData,
    origData: cardsData,
    newElmt: {
      img: {
        path: '',
        alt: '',
      },
      title: '',
      text: '',
      button: 'Mehr lesen →',
      href: '',
      _key: uuidv4(),
    },
  });

  return (
    <GroupCardManager title={props.headline} onAdd={addCard}>
      {cardsData.map((card, index) => {
        return (
          <CardsElementEditor
            removable={cardsData.length > 1}
            cardData={card}
            callback={(data) => {
              updateGroup(data, index, setCardsData, cardsData);
            }}
            key={card._key}
          />
        );
      })}
    </GroupCardManager>
  );
}
