import React from 'react';
import { ColorResult, SketchPicker } from 'react-color';

import './colorPicker.css';

export interface ColorEditorProps {
  value: string;
  setFunc: (value: any) => void;
  colorBorder: boolean;
  text: string;
}

ColorEditor.defaultProps = {
  colorBorder: false,
};

export default function ColorEditor(props: ColorEditorProps) {
  const [color, setColor] = React.useState(props.value);

  const resolveAlphaHex = (hex: string, alpha?: number) => {
    return (
      hex +
      Math.round(255 * (alpha || 1))
        .toString(16)
        .padStart(2, '0')
    );
  };

  return (
    <div className="colorPickerWrapper">
      {props.text}
      <SketchPicker
        color={color}
        className="colorEditor"
        onChange={(color: ColorResult) => setColor(() => resolveAlphaHex(color.hex, color.rgb.a))}
        onChangeComplete={(color: ColorResult) => props.setFunc(resolveAlphaHex(color.hex, color.rgb.a))}
        presetColors={['#09a9ee', '#0088cc', '#005796', '#e2e2e2', '#ffffff', '#e4e4e4', '#767676', '#000000']}
      />
    </div>
  );
}
