import React from 'react';

import { createContext } from 'react';
import { DataVersion, Module, Post, Posts, SelectedPost, User } from './Interfaces';

export const PostsContext = createContext<[Posts, React.Dispatch<React.SetStateAction<Posts>>]>(undefined!);

export const PostsLoadedContext = createContext<[number, React.Dispatch<React.SetStateAction<number>>]>(undefined!);

export const SelectedPostContext = createContext<
  [SelectedPost | undefined, React.Dispatch<React.SetStateAction<SelectedPost | undefined>>]
>(undefined!);

export const PostSearchContext = createContext<[string, React.Dispatch<React.SetStateAction<string>>]>(undefined!);

export const PostsSearchedContext = createContext<[Post[], React.Dispatch<React.SetStateAction<Post[]>>]>(undefined!);

export const UserContext = createContext<[User[], React.Dispatch<React.SetStateAction<User[]>>]>(undefined!);

export const PpmAddressContext = createContext<[string, React.Dispatch<React.SetStateAction<string>>]>(undefined!);

export const DataHistoryContext = createContext<[DataVersion[], React.Dispatch<React.SetStateAction<DataVersion[]>>]>(
  undefined!,
);

export const ModulesContext = createContext<[Module[], React.Dispatch<React.SetStateAction<Module[]>>]>(undefined!);

export const DataContext = createContext<[any, React.Dispatch<React.SetStateAction<any>>]>(undefined!);

export const DataIDContext = createContext<[string, React.Dispatch<React.SetStateAction<string>>]>(undefined!);
