import React from 'react';

import { addGroup, updateGroup } from '../../../functions';
import { Person } from '../../../Interfaces';
import GroupCardManager from '../../GroupCardManager';
import PersonEditor from './PersonEditor';

import { v4 as uuidv4 } from 'uuid';

export interface PeopleEditorProps {
  headline: string;
  data: Person[];
  callback: (data: Person[] | null) => void;
}

export default function PeopleEditor(props: PeopleEditorProps) {
  const [peopleData, setPeopleData] = React.useState(props.data);

  React.useEffect(() => {
    props.callback(peopleData);
  }, [peopleData]);

  const addPerson = addGroup.bind({
    setFunc: setPeopleData,
    origData: peopleData,
    newElmt: {
      name: '',
      desc: '',
      imgPath: '',
      _key: uuidv4(),
    },
  });

  return (
    <GroupCardManager title={props.headline} onAdd={addPerson}>
      {peopleData.map((person, index) => {
        return (
          <PersonEditor
            removable={peopleData.length > 1}
            personData={person}
            callback={(data) => {
              updateGroup(data, index, setPeopleData, peopleData);
            }}
            key={person._key}
          />
        );
      })}
    </GroupCardManager>
  );
}
