import React from 'react';

import { QuickAccessGroup, QuickAccessLink } from '../../../Interfaces';
import QuickAccessLinkEditor from './QuickAccessLinkEditor';
import ColorEditor from '../Generic/ColorEditor';
import EditorCardManager from '../../EditorCardManager';

import { v4 as uuidv4 } from 'uuid';

import { TextField } from '@material-ui/core';

import './quickAccessGroup.css';

export interface QuickAccessGroupEditorProps {
  groupState: QuickAccessGroup;
  callback: (data: QuickAccessGroup | null) => void;
  removable: boolean;
}

export default function QuickAccessGroupEditor(props: QuickAccessGroupEditorProps) {
  const [links, setLinks] = React.useState(props.groupState.links);
  const [title, setTitle] = React.useState(props.groupState.title);
  const [color, setColor] = React.useState(props.groupState.color);
  const [textcolor, setTextcolor] = React.useState(props.groupState.textcolor);

  function addLink() {
    setLinks([
      ...props.groupState.links,
      {
        name: '',
        href: '',
        image: '',
        _key: uuidv4(),
      },
    ]);
  }

  function updateLink(data: QuickAccessLink | null, linkIndex: number) {
    if (data === null) {
      const updatedLinks = links.filter((_, index) => index !== linkIndex);
      setLinks(updatedLinks);
    } else {
      const updatedLinks = links.map((link, index) => {
        if (!(index === linkIndex)) return link;
        return data;
      });
      setLinks(updatedLinks);
    }
  }

  React.useEffect(() => {
    props.callback({
      title,
      color,
      textcolor,
      links,
      _key: props.groupState._key,
    });
  }, [links, title, color, textcolor]);

  return (
    <EditorCardManager
      onAdd={addLink}
      onRemove={props.removable ? () => props.callback(null) : undefined}
      style={{ backgroundColor: color }}
    >
      <div className="quickAccessGroupWrapper">
        <ColorEditor value={color} setFunc={setColor} text="Hintergrundfarbe" />
        <ColorEditor value={textcolor} setFunc={setTextcolor} text="Textfarbe" />
        <TextField
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setTitle(event.target.value)}
          className="title"
          style={{ color: textcolor }}
          value={title}
          label="Titel"
          InputProps={{ style: { fontSize: 'xx-large' } }}
          error={!title}
        />
      </div>

      {links.map((link, index) => {
        return (
          <QuickAccessLinkEditor
            removable={links.length > 1}
            color={color}
            data={link}
            callback={(data) => {
              updateLink(data, index);
            }}
            key={link._key}
          />
        );
      })}
    </EditorCardManager>
  );
}
