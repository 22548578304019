import React from 'react';

import { addGroup, updateGroup } from '../../../functions';
import { Subject, Teacher } from '../../../Interfaces';
import GroupCardManager from '../../GroupCardManager';
import TeacherEditor from './TeacherEditor';

import { v4 as uuidv4 } from 'uuid';

export interface TeacherListEditorProps {
  teachers: Teacher[];
  callback: (data: Teacher[]) => void;
  possibleSubjects: Subject[];
}

export default function TeacherListEditor(props: TeacherListEditorProps) {
  const [teachers, setTeachers] = React.useState(props.teachers);

  React.useEffect(() => {
    props.callback(teachers);
  }, [teachers]);

  const addCard = addGroup.bind({
    setFunc: setTeachers,
    origData: teachers,
    newElmt: {
      surname: '',
      title: '',
      firstname: '',
      middlename: '',
      subjects: [],
      token: '',
      referendar: true,
      _key: uuidv4(),
    },
  });

  return (
    <GroupCardManager title={'Lehrer'} onAdd={addCard} className="teacherEditor">
      {props.teachers.map((teacher, index) => {
        return (
          <TeacherEditor
            removable={props.teachers.length > 1}
            teacher={teacher}
            callback={(data) => {
              updateGroup(data, index, setTeachers, teachers);
            }}
            key={teacher._key}
            possibleSubjects={props.possibleSubjects}
          />
        );
      })}
    </GroupCardManager>
  );
}
