import { Button, ButtonProps, CircularProgress, useTheme } from '@material-ui/core';
import React from 'react';

type MouseEvent = Parameters<Required<ButtonProps>['onClick']>[0];
export interface PromiseButtonProps extends ButtonProps {
  onClick?: (event: MouseEvent) => Promise<any> | any;
  loading?: boolean;
}

const PromiseButton = React.forwardRef<HTMLButtonElement, PromiseButtonProps>((props, ref) => {
  const { onClick, color, children, ...rest } = props;
  const theme = useTheme();

  const [loading, setLoading] = React.useState(false);
  const [loadingError, setLoadingError] = React.useState(false);

  const mounted = React.useRef(false);

  React.useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  });

  const promiseAction = async (e: MouseEvent) => {
    if (mounted.current) {
      // Initiate loading
      setLoading(true);
      setLoadingError(false);
    }

    // Perform action
    try {
      await onClick?.(e);
    } catch (e) {
      console.error('Error in PromiseButton:', e);
      if (mounted.current) setLoadingError(true);
      // forward error
      throw e;
    } finally {
      // Complete loading
      if (mounted.current) setLoading(false);
    }
  };

  return (
    <Button
      ref={ref}
      onClick={promiseAction}
      style={{
        color: loadingError ? theme.palette.error.main : undefined,
      }}
      {...rest}
      startIcon={loading || rest.loading ? <CircularProgress size={20} /> : rest.startIcon}
      endIcon={loading || rest.loading ? undefined : rest.endIcon}
    >
      {!loading && !rest.loading && children}
    </Button>
  );
});

export default PromiseButton;
