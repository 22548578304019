import { Node } from '@tiptap/core';

import { getVideoIDFromYTLink, isValidYoutubeUrl } from './TipTapYouTubeUtils';
import { ReactNodeViewRenderer } from '@tiptap/react';
import YouTubView from './YouTubView';

export interface YoutubeOptions {
  src: string;
  thumbnail: string;
}
export interface YoutubeStorage {}

type SetYoutubeVideoOptions = YoutubeOptions;

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    youtube: {
      /**
       * Insert a youtube video
       */
      setYoutubeVideo: (options: SetYoutubeVideoOptions) => ReturnType;
    };
  }
}

export const Youtube = Node.create<YoutubeOptions, YoutubeStorage>({
  name: 'staticc-youtube',

  inline() {
    return false;
  },

  group() {
    return 'block';
  },

  draggable: true,

  addAttributes() {
    return {
      src: {
        default: null,
      },
      thumbnail: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div[staticc-youtube]',
      },
    ];
  },

  addCommands() {
    return {
      setYoutubeVideo:
        (options: SetYoutubeVideoOptions) =>
        ({ commands }) => {
          if (!isValidYoutubeUrl(options.src)) {
            return false;
          }

          this.options.src = options.src;
          this.options.thumbnail = options.thumbnail;

          return commands.insertContent({
            type: this.name,
            attrs: options,
          });
        },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(YouTubView);
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      {
        'staticc-youtube': '',
        ...HTMLAttributes,
      },
      `{{ !!ytEmbed \`${getVideoIDFromYTLink(this.options.src)}\` \`${this.options.thumbnail}\` }}`,
    ];
  },
});
