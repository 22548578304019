import React from 'react';

import { updateGroup } from '../functions';
import { User } from '../Interfaces';
import { useAuth } from './Authentication/AuthContext';
import { UserContext } from '../ContextManager';

import { AddButtonRotating, RemoveButtonBin } from '@idot-digital/custom-material-ui';
import '@idot-digital/custom-material-ui/dist/index.css';

import { v4 as uuidv4 } from 'uuid';

import Highlighter from 'react-highlight-words';

import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Step,
  StepButton,
  Stepper,
  TextField,
  Tooltip,
  Grow,
  Switch,
} from '@material-ui/core';
import { FileCopyRounded } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

import './userPage.css';

export default function UserPage() {
  const [users, setUsers] = React.useContext(UserContext);
  const [search, setSearch] = React.useState('');

  const [createNewUserOpen, setNewUserOpen] = React.useState(false);

  const { currentUser, userManagement, logout } = useAuth();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const deleteUser = (user: User, index: number) => {
    if (!currentUser) throw new Error('User not logged in');
    if (user.email !== currentUser.email)
      userManagement
        .deleteUser(user.email as string)
        .then(() => setUsers(users.filter((_: any, i: number) => i !== index)))
        .catch(() =>
          enqueueSnackbar(
            `Der Nutzer "${user.email}" konnte nicht entfernt werden. Bitte versuchen Sie es in kurzer Zeit erneut.`,
            { variant: 'error' },
          ),
        );
    else enqueueSnackbar(`Sie können ihr eigenes Konto nicht löschen`, { variant: 'error' });
  };

  const toggleRights = (
    user: User,
    toggle: 'admin' | 'contentManager' | 'writer',
    index: number,
    rootUsers: User[] = users,
  ) => {
    if (!currentUser) throw new Error('User not logged in');
    let newRights = user.userGroup.includes(toggle)
      ? user.userGroup.filter((right) => right !== toggle)
      : user.userGroup.concat(toggle);

    // Cant change own admin rights
    if (user.email !== currentUser.email || newRights.includes('admin')) {
      userManagement
        .updateUser(user.email, newRights)
        .then(() =>
          updateGroup(
            {
              ...user,
              userGroup: newRights,
            } as User,
            index,
            setUsers,
            rootUsers,
          ),
        )
        .then(() => {
          let key = uuidv4();
          enqueueSnackbar(`Die Rechte des Nutzers "${user.email}" wurden erfolgreich geändert`, {
            variant: 'success',
            key: key,
            action: (
              <Button
                color="default"
                className="pointerEvent"
                onClick={() => {
                  closeSnackbar(key);

                  setUsers((users) => {
                    let currentUserIndex = users.findIndex((current) => current.email === user.email);
                    if (currentUserIndex >= 0) {
                      let user = users[currentUserIndex];

                      let revertRights = user.userGroup.includes(toggle)
                        ? user.userGroup.filter((right) => right !== toggle)
                        : user.userGroup.concat(toggle);

                      // Toggle rights
                      userManagement.updateUser(user.email, revertRights).catch(() => {
                        // Revert to last correct
                        setUsers((users) => {
                          let newUsers = [...users];
                          newUsers[currentUserIndex] = { ...user };
                          return newUsers;
                        });

                        enqueueSnackbar(
                          `Die Rechte des Nutzers "${user.email}" konnten nicht geändert werden. Bitte versuchen Sie es in kurzer Zeit erneut.`,
                          { variant: 'error' },
                        );
                      });

                      // and return new user object
                      let newUsers = [...users];
                      newUsers[currentUserIndex] = { ...user, userGroup: revertRights };
                      return newUsers;
                    } else
                      enqueueSnackbar(
                        `Die Rechte des Nutzers "${user.email}" konnten nicht geändert werden, da dieser bereits aus der Datenbank entfernt wurde.`,
                        {
                          variant: 'error',
                        },
                      );
                    return users;
                  });
                }}
              >
                Rückgängig
              </Button>
            ),
          });
        })
        .then(() => {
          if (currentUser.email === user.email) {
            let key = uuidv4();

            enqueueSnackbar('Damit die Änderungen lokal wirksam werden, müssen Sie sich neu einloggen.', {
              key,
              action: (
                <Button
                  color="primary"
                  className="pointerEvent"
                  onClick={() => {
                    logout();
                    closeSnackbar(key);
                  }}
                >
                  Möchten Sie sich neu einloggen?
                </Button>
              ),
            });
          }
        })
        .catch(() =>
          enqueueSnackbar(
            `Die Rechte des Nutzers "${user.email}" konnten nicht geändert werden. Bitte versuchen Sie es in kurzer Zeit erneut.`,
            { variant: 'error' },
          ),
        );
    } else enqueueSnackbar(`Sie können die Adminrechte ihres eigenen Kontos nicht ändern`, { variant: 'error' });
  };

  return (
    <>
      <Dialog
        open={createNewUserOpen}
        onClose={() => setNewUserOpen(false)}
        PaperProps={{ style: { minHeight: '30vh', minWidth: '50vh' } as React.CSSProperties }}
      >
        <CreateNewUser setUsers={setUsers} close={() => setNewUserOpen(false)} />
      </Dialog>

      <div className="userPageWrapper">
        <div className="userPageTopBar">
          <TextField
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)}
            label="Suche"
            className="articlesSearch"
            value={search}
          />
          <Tooltip title="Neuen Nutzer erstellen" className="addUserButton">
            <div>
              <AddButtonRotating onClick={() => setNewUserOpen(true)} />
            </div>
          </Tooltip>
        </div>
        <div className="userPageUsers">
          {users.length ? (
            users
              .filter((user) => user.email.toLowerCase().includes(search.toLowerCase()))
              .map((user, index) => (
                <div className="userPageUser" key={user._key}>
                  <Highlighter
                    highlightClassName="highlightArticle"
                    searchWords={[search]}
                    textToHighlight={user.email}
                    className="userPagerUserName"
                  />
                  <>
                    <FormControlLabel
                      value="writer"
                      control={
                        <Checkbox
                          color="primary"
                          checked={user.userGroup.includes('writer')}
                          onChange={() => toggleRights(user, 'writer', index)}
                        />
                      }
                      label="Autor"
                    />
                    <FormControlLabel
                      value="contentManager"
                      control={
                        <Checkbox
                          color="primary"
                          checked={user.userGroup.includes('contentManager')}
                          onChange={() => toggleRights(user, 'contentManager', index)}
                        />
                      }
                      label="Content Manager"
                    />
                    <FormControlLabel
                      value="admin"
                      control={
                        <Checkbox
                          color="primary"
                          checked={user.userGroup.includes('admin')}
                          onChange={() => toggleRights(user, 'admin', index)}
                        />
                      }
                      label="Admin"
                    />
                  </>
                  <Tooltip title="Benutzer löschen" className="removeButton">
                    <div>
                      <RemoveButtonBin onClick={() => deleteUser(user, index)} />
                    </div>
                  </Tooltip>
                </div>
              ))
          ) : (
            <CircularProgress className="loading" />
          )}
        </div>
      </div>
    </>
  );
}

function CreateNewUser(props: { setUsers: (value: React.SetStateAction<User[]>) => void; close: () => void }) {
  const [step, setStep] = React.useState(0);
  const steps = [
    { label: 'Email', tooltip: 'E-Mail und Nutzername des Users' },
    { label: 'Rechte', tooltip: 'Rechte des Nutzers' },
    { label: 'Account', tooltip: 'Passwort des neuen Nutzers' },
  ];

  const [name, setName] = React.useState('');
  const { userManagement, currentUser } = useAuth();
  const [rights, setRights] = React.useState({
    writer: false,
    contentManager: false,
    admin: false,
  });

  const [password] = React.useState(userManagement.createPassword());
  const shouldUpdateUser = React.useRef(false);

  const [userIsCreated, setUserIsCreated] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const createUser = () => {
    // Reset and overwrite linking code when needed
    if (shouldUpdateUser.current) {
      setUserIsCreated(false);
      shouldUpdateUser.current = false;
      const userGroup: ('writer' | 'contentManager' | 'admin')[] = [];
      if (rights.writer) userGroup.push('writer');
      if (rights.contentManager) userGroup.push('contentManager');
      if (rights.admin) userGroup.push('admin');
      userManagement.createUser({ email: name, password, userGroup }).then((successful: boolean) => {
        if (successful) {
          setUserIsCreated(true);
          props.setUsers((users) => [
            ...users,
            {
              email: name,
              userGroup: Object.entries(rights).reduce((allRights, [right, hasRight]) => {
                if (hasRight) return allRights.concat([right]);
                else return allRights;
              }, [] as string[]),
              rightString: Object.entries(rights).reduce((allRights, [right, hasRight]) => {
                if (hasRight) return allRights + right[0];
                else return allRights;
              }, '' as string),
              namespace: currentUser?.namespace,
              _key: uuidv4(),
            } as User,
          ]);
        } else
          enqueueSnackbar(
            'Beim erstellen des Nutzers ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut',
          );
      });
    }
  };

  function getSteppers(index: number, step: { label: string; tooltip: string }) {
    switch (index) {
      case 0:
      case 1:
        return (
          <Tooltip title={step.tooltip} key={step.label}>
            <Step>
              <StepButton onClick={() => stepAction(index)}>{step.label}</StepButton>
            </Step>
          </Tooltip>
        );
      case 2:
        return (
          <Tooltip title={step.tooltip} key={step.label}>
            <Step>
              <StepButton
                onClick={() => stepAction(index)}
                disabled={Object.entries(rights).filter(([_, right]) => right).length === 0 || !name}
              >
                {step.label}
              </StepButton>
            </Step>
          </Tooltip>
        );
    }
  }

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <TextField
            value={name}
            onChange={(event) => setName(event.target.value)}
            label="Email"
            helperText={steps[step].tooltip}
          />
        );
      case 1:
        return (
          <>
            <FormControlLabel
              value="writer"
              control={
                <Checkbox
                  color="primary"
                  checked={rights.writer}
                  onChange={(event) => {
                    setRights({ ...rights, writer: event.target.checked });
                    shouldUpdateUser.current = true;
                  }}
                />
              }
              label="Autor"
              labelPlacement="top"
            />
            <FormControlLabel
              value="contentManager"
              control={
                <Checkbox
                  color="primary"
                  checked={rights.contentManager}
                  onChange={(event) => {
                    setRights({ ...rights, contentManager: event.target.checked });
                    shouldUpdateUser.current = true;
                  }}
                />
              }
              label="Content Manager"
              labelPlacement="top"
            />
            <FormControlLabel
              value="admin"
              control={
                <Checkbox
                  color="primary"
                  checked={rights.admin}
                  onChange={(event) => {
                    setRights({ ...rights, admin: event.target.checked });
                    shouldUpdateUser.current = true;
                  }}
                />
              }
              label="Admin"
              labelPlacement="top"
            />
          </>
        );
      case 2:
        return (
          <>
            {userIsCreated ? (
              <Grow in={true}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextField value={name} helperText={steps[0].tooltip} label="Email" />

                  <TextField
                    inputProps={{
                      readOnly: true,
                    }}
                    className="input"
                    value={password}
                    label="Passwort"
                    helperText={steps[step].tooltip}
                  />
                  <Tooltip title="Passwort kopieren">
                    <div style={{ marginRight: '1rem', cursor: 'pointer' }}>
                      <FileCopyRounded onClick={() => navigator.clipboard.writeText(password)} key={password} />
                    </div>
                  </Tooltip>
                </div>
              </Grow>
            ) : (
              <CircularProgress />
            )}
          </>
        );
    }
  }

  function getButtons(step: number) {
    switch (step) {
      case 0:
        return (
          <>
            <Button onClick={props.close} color="primary">
              Abbrechen
            </Button>
            <Button
              color="primary"
              onClick={() => {
                setStep(step + 1);
              }}
              disabled={!name}
              autoFocus
            >
              Weiter
            </Button>
          </>
        );
      case 1:
        return (
          <>
            <Button onClick={props.close} color="primary">
              Abbrechen
            </Button>
            <Button
              color="primary"
              onClick={() => {
                setStep(step + 1);
                createUser();
              }}
              disabled={Object.entries(rights).filter(([_, right]) => right).length === 0 || !name}
              autoFocus
            >
              Weiter
            </Button>
          </>
        );
      case 2:
        return (
          <>
            <Button color="primary" onClick={props.close} autoFocus>
              Nutzer erstellen
            </Button>
          </>
        );
    }
  }

  function stepAction(step: number) {
    setStep(step);

    switch (step) {
      case 3:
        createUser();
        break;
    }
  }

  return (
    <>
      <DialogTitle>Neuen Nutzer erstellen</DialogTitle>
      <Stepper nonLinear activeStep={step}>
        {steps.map((step, index) => getSteppers(index, step))}
      </Stepper>
      <DialogContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {getStepContent(step)}
      </DialogContent>
      <DialogActions>{getButtons(step)}</DialogActions>
    </>
  );
}
