import React from 'react';
import { YoutubeOptions } from './TipTapYouTube';
import { NodeViewWrapper } from '@tiptap/react';
import { Box, useTheme } from '@material-ui/core';
import { getEmbedUrlFromYoutubeUrl } from './TipTapYouTubeUtils';

const YouTubView = React.forwardRef(
  (
    props: {
      node: {
        attrs: YoutubeOptions;
      };
      selected: boolean;
    },
    ref,
  ) => {
    const theme = useTheme();

    const youtubeUrl = getEmbedUrlFromYoutubeUrl({ url: props.node.attrs.src }) || '';

    return (
      <NodeViewWrapper ref={ref}>
        <Box
          style={{
            position: 'relative',
            height: '20rem',
            aspectRatio: '16/9',
          }}
        >
          {/* <img
            src={props.node.attrs.thumbnail}
            alt="Video Thumbnail"
            style={{
              height: '20rem',
              position: 'absolute',
              zIndex: 0,
            }}
          /> */}
          <iframe
            src={youtubeUrl}
            style={{
              width: '100%',
              height: '100%',
              zIndex: 1,
              position: 'relative',
              borderWidth: '4px',
              borderStyle: 'solid',
              borderColor: props.selected ? theme.palette.primary.main : 'transparent',
              boxShadow: props.selected ? theme.shadows[10] : undefined,
            }}
            title={`YouTube Player ${props.node.attrs.src}`}
          />
          {/* {props.selected && (
            <Box
              style={{
                position: 'absolute',
                inset: 0,
                background: theme.palette.primary.main,
                opacity: 0.2,
                pointerEvents: 'none',
                zIndex: 1,
              }}
            />
          )} */}
        </Box>
      </NodeViewWrapper>
    );
  },
);

export default YouTubView;
