import React from 'react';

import EditorCardManager from '../../EditorCardManager';
import GroupCardManager from '../../GroupCardManager';
import { deuniquify } from '../../../functions';

import { Button, Switch, TextField } from '@material-ui/core';

import { v4 as uuidv4 } from 'uuid';

import { GenericInputTextField } from '../../ContentManagementPage';

import './genericEditor.css';
import LinkEditor from '../../LinkEditor';

export interface GenericEditorProps {
  headline: string;
  data: any;
  callback: (data: any) => void;
}

export default function GenericEditor(props: GenericEditorProps) {
  return (
    <GroupCardManager title={props.headline} className="generic">
      <AnyInput title="" data={props.data} onChange={props.callback} />
    </GroupCardManager>
  );
}

export function AnyInput(props: {
  title: string;
  data: any;
  onChange: (value: any) => void;
  onTextStateChange?: (html: string) => void;
}) {
  const parseInputType = () => {
    // Ignore _something values
    if (props.title === '_img')
      return (
        <LinkEditor
          image={props.data.path}
          description={props.data.alt}
          setImage={(img) =>
            props.onChange({
              ...props.data,
              path: img,
            })
          }
          setDescription={(description) =>
            props.onChange({
              ...props.data,
              alt: description,
            })
          }
        />
      );
    if (props.title?.[0] === '_') return;

    switch (props.data.constructor || ''.constructor) {
      case Object:
        return <ObjectInput data={props.data} onChange={props.onChange} />;
      case Array:
        return <ArrayInput title={props.title} data={props.data} onChange={props.onChange} />;
      case Boolean:
        return <BooleanInput title={props.title} value={props.data} onChange={props.onChange} />;
      default:
        return (
          <SimpleInput
            title={props.title}
            value={props.data}
            onChange={props.onChange}
            onTextStateChange={props.onTextStateChange}
          />
        );
    }
  };

  return <>{parseInputType()}</>;
}

export function SimpleInput(props: {
  title: string;
  value: string;
  onChange: (value: string) => void;
  // Only objects are valid to contain text states
  onTextStateChange?: (html: string) => void;
}) {
  const { setGenericInputOnClose, setTextEditorText } = React.useContext(GenericInputTextField);

  return (
    <>
      <div className="simpleInput">
        {props.title && <span className="title">{props.title}</span>}
        {
          // Detect TextField entry by searching for tags
          props.onTextStateChange && /<.*>.*<\/.*>/.test(props.value) ? (
            <Button
              color="primary"
              onClick={() => {
                setGenericInputOnClose(() => props.onTextStateChange);
                setTextEditorText(props.value);
              }}
            >
              Bearbeiten
            </Button>
          ) : (
            <TextField
              fullWidth
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onChange(event.target.value)}
              className="input"
              value={props.value}
              error={!props.value}
            />
          )
        }
      </div>
    </>
  );
}

function BooleanInput(props: { title: string; value: boolean; onChange: (value: boolean) => void }) {
  return (
    <>
      <div className="booleanInput">
        {props.title && <span className="title">{props.title}</span>}
        <Switch checked={props.value} onChange={(event) => props.onChange(event.target.checked)}></Switch>
      </div>
    </>
  );
}

function ObjectInput(props: { data: { [key: string]: any }; onChange: (value: { [key: string]: any }) => void }) {
  return (
    <>
      {Object.entries(props.data as any).map(([key, entry]: [string, any]) => (
        <AnyInput
          title={key}
          data={entry}
          onChange={(data) => props.onChange({ ...props.data, ...{ [key]: data } })}
          onTextStateChange={(html) => props.onChange({ ...props.data, [key]: html })}
          key={key}
        />
      ))}
    </>
  );
}

function ArrayInput(props: { title: string; data: any[]; onChange: (value: any[]) => void }) {
  return (
    // Deep match new items
    <EditorCardManager onAdd={() => props.onChange(props.data.concat(deepMatch(props.data)))}>
      {props.title && <h2>{props.title}</h2>}
      {props.data.map((entry, index) => (
        <EditorCardManager
          onRemove={
            props.data.length > 1
              ? () => props.onChange(props.data.slice(0, index).concat(props.data.slice(index + 1)))
              : undefined
          }
          key={entry._key || index}
        >
          <AnyInput
            title=""
            data={entry}
            onChange={(data) =>
              props.onChange(props.data.slice(0, index).concat([data].concat(props.data.slice(index + 1))))
            }
          />
        </EditorCardManager>
      ))}
    </EditorCardManager>
  );
}

// Possible error when [[{...someKeys}], [{...otherKeys}]] objects should match but have additional optional (not included) keys
const deepMatch = (data: any, key?: string): any => {
  switch (data.constructor || ''.constructor) {
    case Object:
      return Object.fromEntries(
        Object.entries(data).map(([key, entry]: [string, any]) => [key, deepMatch(entry, key)]),
      );
    case Array:
      let basicStructure = data.map((entry: any) => deepMatch(entry));

      switch (basicStructure?.[0].constructor || ''.constructor) {
        case Object:
          return basicStructure.filter((item: any) => item.constructor === Object).length !== basicStructure.length
            ? basicStructure
            : [basicStructure.reduce((matched: any, current: any) => ({ ...matched, ...current }), {})];
        case Array:
          let shouldEqual = JSON.stringify(deuniquify(basicStructure[0]));

          for (const item of basicStructure.slice(1)) {
            if (shouldEqual !== JSON.stringify(deuniquify(item))) return basicStructure;
          }

          return [basicStructure[0]];
        default:
          return basicStructure.find((item: any) => item.constructor === Array || item.constructor === Object)
            ? basicStructure
            : [''];
      }

    default:
      return key === '_key' ? uuidv4() : '';
  }
};
