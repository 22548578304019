import React from 'react';

import { addGroup, updateGroup } from '../../../functions';
import { Navigation } from '../../../Interfaces';
import GroupCardManager from '../../GroupCardManager';
import NavigationGroupEditor from './NavigationGroupEditor';

import { v4 as uuidv4 } from 'uuid';

export interface NavigationEditorProps {
  headline: string;
  data: Navigation[];
  callback: (data: Navigation[] | null) => void;
}

export default function NavigationEditor(props: NavigationEditorProps) {
  const [navigationData, setnNavigationData] = React.useState(props.data);

  React.useEffect(() => {
    props.callback(navigationData);
  }, [navigationData]);

  const addNavigationGroup = addGroup.bind({
    setFunc: setnNavigationData,
    origData: navigationData,
    newElmt: {
      title: '',
      links: [
        {
          name: '',
          href: '',
          _key: uuidv4(),
        },
      ],
      _key: uuidv4(),
    },
  });

  return (
    <GroupCardManager onAdd={addNavigationGroup} title={props.headline}>
      {navigationData.map((navigationGroup, index) => {
        return (
          <NavigationGroupEditor
            removable={navigationData.length > 1}
            groupState={navigationGroup}
            callback={(data) => {
              updateGroup(data, index, setnNavigationData, navigationData);
            }}
            key={navigationGroup._key}
          />
        );
      })}
    </GroupCardManager>
  );
}
