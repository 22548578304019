import { Switch, Route, useHistory } from 'react-router-dom';

import ArticlesPage from './Components/ArticlesPage';
import UserPage from './Components/UserPage';
import ContentManagementPage from './Components/ContentManagementPage';
import SettingsPage from './SettingsPage';
import EditorPage from './Components/EditorPage';
import {
  DataContext,
  DataHistoryContext,
  DataIDContext,
  ModulesContext,
  PostsContext,
  PostSearchContext,
  PostsLoadedContext,
  PostsSearchedContext,
  PpmAddressContext,
  SelectedPostContext,
  UserContext,
} from './ContextManager';
import React from 'react';
import { DataVersion, Module, Post, Posts, SelectedPost, User } from './Interfaces';
import { uniquify } from './functions';

import { useAuth } from './Components/Authentication/AuthContext';
import Login from './Components/Authentication/Login';
import ForgotPassword from './Components/Authentication/ForgotPassword';
import PrivateRoute from './Components/Authentication/PrivateRoute';
import NavigationBar from './Components/NavigationBar';

import { useSnackbar } from 'notistack';

import './app.css';
import config from './config';

const defaultData = {
  quickAccess: [],
  navigation: [],
  slider: [],
  cards: [],
  people: [],
  subjects: {
    title: 'Ich bin ein Titel',
    headline: 'Ich bin eine Headline',
    kollegium: [],
    faecher: [],
  },
};

export default function App() {
  const [posts, setPosts] = React.useState({ posts: [], totalPages: 0, currentPage: 0 } as Posts);
  const [loadedPosts, setLoadedPosts] = React.useState(100);
  const [selectedPost, setSelectedPost] = React.useState(undefined as SelectedPost | undefined);
  const [postSearch, setPostSearch] = React.useState('');
  const [searchedPosts, setSearchedPosts] = React.useState([] as Post[]);

  const [users, setUsers] = React.useState([] as User[]);

  const [ppmAddress, setPPMAddress] = React.useState('Es wurde noch keine Adresse generiert' as string);
  const [dataHistory, setDataHistory] = React.useState([] as unknown as DataVersion[]);

  const [data, setData] = React.useState({} as any);
  const [currentDataID, setCurrentDataID] = React.useState('' as string);
  const [modules, setModules] = React.useState([] as Module[]);

  const { login, currentUser, postActions, settingActions, dataActions, userManagement, modulesJson, jwt } = useAuth();
  const history = useHistory();

  //@ts-ignore
  window.auth = { login, currentUser, postActions, settingActions, dataActions, userManagement, modulesJson, jwt };

  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (currentUser) {
      if (currentUser?.userGroup.includes('writer')) postUpdate();
      if (currentUser?.userGroup.includes('admin')) {
        addressUpdate();
        userUpdate();
      }
      if (currentUser?.userGroup.includes('contentManager')) {
        contentHistoryUpdate().then((data) => {
          if (data) setCurrentDataID(data.find((entry) => entry.preview)?.id || data[data.length - 1].id);
        });
        getModules();
      }

      const interval = setInterval(() => {
        if (currentUser) {
          if (currentUser?.userGroup.includes('writer')) postUpdate();
          if (currentUser?.userGroup.includes('admin')) {
            addressUpdate();
            userUpdate();
          }
          if (currentUser?.userGroup.includes('contentManager')) {
            contentHistoryUpdate();
            getModules();
          }
        }
      }, 60 * 1000);

      return () => clearInterval(interval);
    }
  }, [currentUser]);

  React.useEffect(() => {
    if (currentUser?.userGroup.includes('writer')) postUpdate();
  }, [loadedPosts]);

  React.useEffect(() => {
    if (postSearch) serverPostSearch(postSearch);
    else setSearchedPosts([]);
  }, [postSearch, posts]);

  React.useEffect(() => {
    if (currentUser && currentDataID) getDataById(currentDataID);
  }, [currentDataID]);

  const postUpdate = async () => {
    const posts = await postActions.list(0, loadedPosts).catch(() => {
      if (currentUser)
        enqueueSnackbar(
          'Die Verbindung zum Server konnte nicht hergestellt werden. Bitte versuchen Sie es in kurzer Zeit erneut.',
          { variant: 'error' },
        );
    });
    if (posts && currentUser)
      setPosts({
        ...posts,
        posts: uniquify(
          posts.posts.map((post: any) => ({
            ...post,
            images: JSON.parse(post.images as unknown as string),
            categories: JSON.parse(post.categories as unknown as string),
            date: new Date(post.date),
          })),
        ),
      });
  };

  const serverPostSearch = async (search: string) => {
    const searched = await postActions.search(search).catch(() => {
      enqueueSnackbar('Die Suche nach Beiträgen ist fehlgeschlagen. Bitte versuchen Sie es in kurzer Zeit erneut.', {
        variant: 'warning',
      });
    });
    if (searched && currentUser)
      setSearchedPosts(
        uniquify(
          searched.map((post: any) => ({
            ...post,
            images: JSON.parse(post.images as unknown as string),
            categories: JSON.parse(post.categories as unknown as string),
            date: new Date(post.date),
          })),
        ),
      );
  };

  const addressUpdate = async () => {
    const address = await settingActions.ppmAddress.get().catch(() => {
      if (currentUser)
        enqueueSnackbar(
          'Die Verbindung zum Server konnte nicht hergestellt werden. Bitte versuchen Sie es in kurzer Zeit erneut.',
          { variant: 'error' },
        );
    });
    if (address && currentUser) setPPMAddress(address);
  };

  const contentHistoryUpdate = async (): Promise<DataVersion[] | undefined> => {
    const data = await dataActions.list().catch(() => {
      if (currentUser)
        enqueueSnackbar(
          'Die Verbindung zum Server konnte nicht hergestellt werden. Bitte versuchen Sie es in kurzer Zeit erneut.',
          { variant: 'error' },
        );
    });
    if (data && currentUser) {
      if (data.length) {
        setDataHistory(data);
        return data;
      } else setData(uniquify(defaultData));
    }
    return;
  };

  const getDataById = async (id: string) => {
    // Clear old data
    setData({});

    const data = await dataActions.get(id).catch(() => {
      if (currentUser)
        enqueueSnackbar(
          'Die Verbindung zum Server konnte nicht hergestellt werden. Bitte versuchen Sie es in kurzer Zeit erneut.',
          { variant: 'error' },
        );
    });
    if (config.useTestData) setData(config.testData);
    else if (data && currentUser) setData(uniquify(data));
  };

  const getModules = async () => {
    setModules(Object.entries(JSON.parse(modulesJson)));
  };

  const userUpdate = async () => {
    const userData = await userManagement.listUsers().catch(() => {
      if (currentUser)
        enqueueSnackbar(
          'Die Verbindung zum Server konnte nicht hergestellt werden. Bitte versuchen Sie es in kurzer Zeit erneut.',
          { variant: 'error' },
        );
    });
    if (userData && currentUser) setUsers(uniquify(userData));
  };

  return (
    <div>
      <NavigationBar />
      <div className="page">
        <SelectedPostContext.Provider value={[selectedPost, setSelectedPost]}>
          <PostsContext.Provider value={[posts, setPosts]}>
            <PostsLoadedContext.Provider value={[loadedPosts, setLoadedPosts]}>
              <PostSearchContext.Provider value={[postSearch, setPostSearch]}>
                <PostsSearchedContext.Provider value={[searchedPosts, setSearchedPosts]}>
                  <UserContext.Provider value={[users, setUsers]}>
                    <PpmAddressContext.Provider value={[ppmAddress, setPPMAddress]}>
                      <DataHistoryContext.Provider value={[dataHistory, setDataHistory]}>
                        <ModulesContext.Provider value={[modules, setModules]}>
                          <DataContext.Provider value={[data, setData]}>
                            <DataIDContext.Provider value={[currentDataID, setCurrentDataID]}>
                              <Switch>
                                <Route
                                  path="/login"
                                  component={(props: any) =>
                                    Login({
                                      ...props,
                                      onLogin: async (email, password) => {
                                        await login(email, password);
                                        history.push('/');
                                      },
                                    })
                                  }
                                />
                                <Route path="/forgot-password" component={ForgotPassword} />

                                <PrivateRoute path="/settings" component={SettingsPage} />

                                <PrivateRoute path="/contentmanagement" component={ContentManagementPage} />
                                <PrivateRoute path="/users" component={UserPage} />

                                <PrivateRoute path="/editor" component={EditorPage} />
                                <PrivateRoute
                                  path="/"
                                  component={
                                    currentUser?.userGroup.includes('writer')
                                      ? ArticlesPage
                                      : currentUser?.userGroup.includes('contentManager')
                                      ? ContentManagementPage
                                      : currentUser?.userGroup.includes('admin')
                                      ? UserPage
                                      : SettingsPage
                                  }
                                />
                              </Switch>
                            </DataIDContext.Provider>
                          </DataContext.Provider>
                        </ModulesContext.Provider>
                      </DataHistoryContext.Provider>
                    </PpmAddressContext.Provider>
                  </UserContext.Provider>
                </PostsSearchedContext.Provider>
              </PostSearchContext.Provider>
            </PostsLoadedContext.Provider>
          </PostsContext.Provider>
        </SelectedPostContext.Provider>
      </div>
    </div>
  );
}
