const config = {
  developmentMode: false,
  passwordSaltRounds: 10,
  corsURL: 'http://localhost:3000',
  graphQlLink: 'https://hasura.idot-digital.de/v1/graphql',
  authserverLink: 'https://auth.idot.digital/',
  publicKey:
    '-----BEGIN PUBLIC KEY-----MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA1PUTPo6YIWzCMF73lcGlN/u0JdqWQqAEc16xfOOuHheHmnhF8EWIJj30LQSfuVAhDYQJZDv5xzixeupnTsDDBVjDvlqwfUwFkNARo/q24nZ64Py5qK/0/4Hy89MrER3DJYx7gd5dKSBaJKCIZKnRZtAB1YhWIjekLGiEBEtbJUAXE/weJMb99CR4mQ+d8sDs3MqTyEyydPjVgv923qtuFqFRKge//8QiE6FwY5nw1vlDdA+KoTLAWouEKZrC6QUNX0j/0295DnMaMGyFM3uhTHfj2G0Esa5peB7TlaLS3qbksq2MZagK//nuW0Y5w+0XDWPAJHri2Y4vdtxITN0i5A3VQ0LJqoyjMTtpObtDfmQoimi7GROBf8/PbCR6mqwptwiks3gcA1Il+MbE8B7dhXcpx+A09DfQE0WT3161zkRsRwI5wMGoq9DwnrCx5lrelLg0bzVXetHKBZCM5XXML9H9w64o6QKUW3CmLKRb6kF9+CoCy2l+SZld1+/Uu/l7h21dv6rOkGRCB77yFnH6vpGEI6U7XkmApHWfMoGWbnQS+/DYjJA9vryhfHyXdxyEjd+sY8Mu0vQC13ogVq4+gebK7uFf0ObCefdRGcpm0+6n1mjbROPWEu+pzr8+LFnPbwKX6BIwgMy4H9sseWFqFHAVbFFPpu6/jsx3QQxf85MCAwEAAQ==-----END PUBLIC KEY-----',
  useTestData: false,
  testData: {},
  testModules: {},
};

export default config;
