import React from 'react';

import EditorCardManager from './EditorCardManager';
import ImageSelector from './Editors/Generic/ImageSelector';
import { useAuth } from './Authentication/AuthContext';

import Dropdown from '@idot-digital/dropdown-selector';

import { ListItemText, MenuItem, TextField } from '@material-ui/core';

import './linkEditor.css';

export interface LinkEditorProps {
  onRemove?: () => void;

  setName?: React.Dispatch<React.SetStateAction<string>>;
  setHref?: React.Dispatch<React.SetStateAction<string>>;
  setImage?: React.Dispatch<React.SetStateAction<string>>;
  setFile?: React.Dispatch<React.SetStateAction<string>>;
  setDescription?: React.Dispatch<React.SetStateAction<string>>;

  name?: string;
  href?: string;
  image?: string;
  file?: string;
  description?: string;

  namePlaceholder?: string;
  picturePlaceholder?: string;
  descriptionPlaceholder?: string;
  dropdownPlaceholder?: string;

  color?: string;
  coverImage?: boolean;

  className?: string;
  inputClassName?: string;
  previewClassName?: string;

  children?: React.ReactNode;

  dontRedirect: boolean;
}

LinkEditor.defaultProps = {
  dontRedirect: false,
  coverImage: false,
};

export default function LinkEditor(props: LinkEditorProps) {
  const { parseBaseUrl } = useAuth();

  const parseImage = (image: string | undefined = props.image) => {
    // check if image undefined
    if (!image) return '';

    // Check if media file and .jpg is included
    if (image.includes('media/') || image.includes('icon/')) return parseBaseUrl('publish') + image;
    else return image;
  };

  return (
    <EditorCardManager onRemove={props.onRemove} style={{ padding: 0 }}>
      <div
        className={['linkEditorWrapper', props.className].join(' ')}
        style={props.image !== undefined ? {} : { backgroundColor: props.color }}
      >
        <div className={['input', props.inputClassName].join(' ')}>
          {props.name !== undefined && props.setName && (
            <TextField
              className="inputField"
              value={props.name}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setName?.(event.target.value)}
              label={props.namePlaceholder || 'Name'}
              color={props.color ? 'primary' : 'secondary'}
              error={!props.name}
            />
          )}
          {props.href !== undefined && props.setHref && (
            <Dropdown
              className="inputField"
              defaultValue={props.href}
              onChangeDone={props.setHref}
              errorOnEmpty
              errorAtStartup
              allowCustomInputs
              placeholder={props.dropdownPlaceholder || 'Link'}
              label={props.dropdownPlaceholder || 'Link'}
              style={{ display: '', alignSelf: 'flex-end' }}
              color={props.color ? 'primary' : 'secondary'}
            >
              {['mailto:info@mpg-trier.de', 'tel:004965019184343', '404.html'].map((item) => (
                <MenuItem value={item} key={item}>
                  <ListItemText primary={item}></ListItemText>
                </MenuItem>
              ))}
            </Dropdown>
          )}
          {props.description !== undefined && props.setDescription && (
            <TextField
              className="inputField"
              value={props.description}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setDescription?.(event.target.value)}
              label={props.descriptionPlaceholder || 'Beschreibung'}
              color={props.color ? 'primary' : 'secondary'}
              error={!props.description}
            />
          )}
          {props.image !== undefined && props.setImage && (
            <ImageSelector callback={props.setImage} imagePath={props.image} />
          )}
          {props.file !== undefined && props.setFile && (
            <ImageSelector callback={props.setFile} imagePath={props.file} />
          )}
        </div>
        {props.image !== undefined && (
          <div
            className={['preview', props.previewClassName, props.coverImage ? 'cover' : ''].join(' ')}
            style={{ backgroundColor: props.color }}
          >
            {!props.dontRedirect ? (
              <a href={props.href || parseImage()} rel="noreferrer" target="_blank">
                <img src={parseImage()} alt={props.href} loading="lazy" />
                {props.children}
              </a>
            ) : (
              <React.Fragment>
                <img src={parseImage()} alt={props.href} loading="lazy" />
                {props.children}
              </React.Fragment>
            )}
          </div>
        )}
        {props.file !== undefined && (
          <div
            className={['preview', props.previewClassName, props.coverImage ? 'cover' : ''].join(' ')}
            style={{ backgroundColor: props.color }}
          >
            <iframe src={parseImage(props.file)} title={props.description} loading="lazy" />
            {props.children}
          </div>
        )}
      </div>
    </EditorCardManager>
  );
}
